import { AuthService } from "../../../Services/auth/Auth.Service";
import Layout from "../../../components/Atom/Layout/Layout.vue";
import Relatorio from "../../Children.vue";
import NaoMenu from "../../Children.vue";
import { RouteConfig } from "vue-router";
import Absenteismo from "../../Jornada/Relatorios/Absenteismo.vue";
import RegistroJornada from "../../Jornada/Relatorios/RegistroJornada.vue";
import HorasExtras from "../../Jornada/Relatorios/HorasExtras.vue";
import ControleRfid from "../../Jornada/Relatorios/ControleRfid.vue";
import Inconformidades from "@/views/Jornada/Relatorios/Inconformidades.vue";
import BancoHoras from "../Relatorios/BancoHoras.vue";
import Cadastros from "../../Children.vue";
import Feriados from "@/views/Jornada/Cadastros/Feriados.vue";
import EditarFeriado from "@/views/Jornada/Cadastros/EditarFeriado.vue";
import Eventos from "../Relatorios/Eventos.vue";
import Processamento from "@/views/Jornada/NaoMenus/Processamento.vue";
import JornadaTrabalho from "@/views/Jornada/Relatorios/JornadaTrabalho.vue";
import Integracao from "@/views/Jornada/NaoMenus/Integracao.vue";
import Integridade from "@/views/Jornada/NaoMenus/Integridade.vue";
import FolhaPonto from "@/views/Jornada/Relatorios/FolhaPonto.vue";
import Parametros from "@/views/Jornada/Cadastros/Parametros/ListarParametros.vue";
import CadastroJustificativaJornada from "@/views/Jornada/Cadastros/Justificativas/CadastroJustificativa.vue";
import ListagemJustificativaJornada from "@/views/Jornada/Cadastros/Justificativas/ListagemJustificativa.vue";
import EditarParametros from "@/views/Jornada/Cadastros/Parametros/EditarParametros.vue";
import DashboardJornada from "@/views/Jornada/Dashboard/DashboardJornada.vue";
import SolicitacaoAjustes from "@/views/Jornada/NaoMenus/SolicitacaoAjustes.vue";
import AFDJornada from "@/views/Jornada/Relatorios/AFDJornada.vue";
import AEJJornada from "@/views/Jornada/Relatorios/AEJJornada.vue";
import Fechamento from "@/views/Jornada/NaoMenus/Fechamento.vue";

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
    if (new AuthService().isAuthenticated()) {
        next();
        return;
    }
    next("/login");
};
export const jornadaRoutes: RouteConfig = {
    path: "/controle/jornada",
    name: "controle-jornada",
    beforeEnter: ifAuthenticated,
    meta: {
        requiresAuth: true,
        Module: "controle/jornada",
        breadcrumbName: "Controle de Jornada",
    },
    components: {
        default: Layout,
    },
    children: [
        {
            path: "",
            meta: {
                Module: "controle/jornada",
                breadcrumbName: "",
            },
            components: { content: NaoMenu },
            children: [
                // Página da deshboard Jornada (Não achei uma maneira melhor)
                {
                    path: "",
                    name: "DashboardJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Dashboard Jornada",
                    },
                    component: DashboardJornada,
                },
                {
                    path: "integridade",
                    name: "Integridade",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Integridade",
                    },
                    component: Integridade,
                },
                {
                    path: "processamento",
                    name: "processamento",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Processamento",
                    },
                    component: Processamento,
                },
                {
                    path: "integracao",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Integração",
                    },
                    component: Integracao,
                },
                {
                    path: "solicitacao/ajustes",
                    name: "solicitacaoAjustes",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Solicitação Ajustes",
                    },
                    component: SolicitacaoAjustes,
                },
                {
                    path: "fechamento",
                    name: "fechamento",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Fechamento",
                    },
                    component: Fechamento,
                },
            ],
        },
        {
            path: "relatorios",
            meta: {
                Module: "controle/jornada",
                breadcrumbName: "Relatórios",
            },
            components: {
                content: Relatorio,
            },
            children: [
                {
                    path: "absenteismo",
                    name: "Absenteismo",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Absenteísmo",
                    },
                    component: Absenteismo,
                },
                {
                    path: "registro/jornada",
                    name: "RegistroJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Registro jornada",
                    },
                    component: RegistroJornada,
                },
                {
                    path: "horas/extras",
                    name: "HorasExtras",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Horas extras",
                    },
                    component: HorasExtras,
                },
                {
                    path: "controle/rfid",
                    name: "ControleRfid",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Controle RFID",
                    },
                    component: ControleRfid,
                },
                {
                    path: "inconformidade/jornada",
                    name: "InconformidadeJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Inconformidades",
                    },
                    component: Inconformidades,
                },
                {
                    path: "banco/horas",
                    name: "BancoHoras",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Banco de horas",
                    },
                    component: BancoHoras,
                },
                {
                    path: "eventos",
                    name: "Eventos",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Eventos",
                    },
                    component: Eventos,
                },

                // Jornada Trabalho
                {
                    path: "jornada/trabalho",
                    name: "JornadaTrabalho",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Jornada de Trabalho",
                    },
                    component: JornadaTrabalho,
                },

                // Folha Ponto
                {
                    path: "folha/ponto",
                    name: "FolhaPonto",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Folha Ponto",
                    },
                    component: FolhaPonto,
                },
                {
                    path: "afd",
                    name: "AFDJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "AFD",
                    },
                    component: AFDJornada,
                },
                {
                    path: "aej",
                    name: "AEJJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "AEJ",
                    },
                    component: AEJJornada,
                },
            ],
        },
        {
            path: "cadastros",
            meta: {
                Module: "controle/jornada",
                breadcrumbName: "Cadastros",
            },
            components: {
                content: Cadastros,
            },
            children: [
                {
                    path: "feriados",
                    name: "listagemFeriadosJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Feriados",
                    },
                    component: Feriados,
                },
                {
                    path: "feriados/editar/:id?",
                    name: "CadastroDeFeriadosJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Feriados",
                    },
                    component: EditarFeriado,
                },

                // Parametros
                {
                    path: "jornada/trabalho",
                    name: "listarParametros",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Parâmetros",
                    },
                    component: Parametros,
                },

                // Editar Parametros
                {
                    path: "jornada/trabalho/cadastrar/:id?",
                    name: "editarParametros",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Parâmetros",
                    },
                    component: EditarParametros,
                },

                // Justificativas
                {
                    path: "justificativas/jornada",
                    name: "ListagemJustificativaJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Justificativas",
                    },
                    component: ListagemJustificativaJornada,
                },

                // Editar Justificativas
                {
                    path: "justificativas/jornada/cadastrar/:id?",
                    name: "CadastroJustificativaJornada",
                    meta: {
                        Module: "controle/jornada",
                        breadcrumbName: "Justificativas",
                    },
                    component: CadastroJustificativaJornada,
                },
            ],
        },
        //Não Menu
        //Integração
        // {
        //     path: '',
        //     meta:
        //     {
        //         Module: "controle/jornada",
        //         breadcrumbName: "Não Menu"
        //     }, components:{ content: NaoMenus }, children: [

        //     ]
        // },
    ],
};
