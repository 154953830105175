<template>
    <eaglePanel id="processamentoDriver" :loading="loadingPanel">
        <div class="col-sm-12 row divDontPrint">
            <div class="col-sm-6 nopadding">
                <titulo titulo="Processamento" :icon="mdiDatabaseSync" />
            </div>
            <div class="col-sm-6 nopadding" align="right">
                <simpleButton
                    :disabled="$v.data.$invalid"
                    text="Gerar"
                    type="green"
                    :icon="mdiThumbUpOutline"
                    event="click"
                    @click="gerarRelatorio"
                />
            </div>
        </div>
        <div class="col-sm-12"><hr /></div>
        <div class="col-sm-12 row nopadding">
            <div class="col-sm-3 nopadding">
                <dayPicker
                    class="pr-0"
                    name="pickerPeriodoProcessamento"
                    opens="right"
                    @changeInput="mudaPeriodo"
                />
            </div>
            <div class="col-sm-3 nopadding">
                <selectAll
                    nameForRadio="seletorEmpresaProcessamento"
                    :isMultiple="true"
                    :hasSelectAll="true"
                    :labels="[{ description: 'Empresas*' }]"
                    :optionsArray="optSelectEmpresas"
                    :selected="selectedEmpresa"
                    @changeSelect="mudaEmpresa"
                />
            </div>
            <div class="col-md-5 col-sm-10 row nopadding">
                <div class="col-md-10 col-sm-11 nopadding">
                    <selectAll
                        class="nopadding"
                        nameForRadio="seletorGrupoAhSerProcessado"
                        :isMultiple="true"
                        :hasSelectAll="true"
                        ref="seletorMultiplo"
                        :disabled="!optSelectColaboradores.length"
                        :labels="labelsSeletorDeGrupoAhSerProcessado"
                        :optionsArray="optSelectColaboradores"
                        firstSelected="MR"
                        :loading="carregandoGrupos"
                        @changeSelect="selectColaborador"
                    />
                </div>
                <div class="col-md-2">
                    <simpleButton
                        v-if="getEditar() || getCadastrar()"
                        :disabled="$v.dataGerar.$invalid || !processar"
                        class="botaoFiltros"
                        text="Processar"
                        type="blue"
                        event="click"
                        @click="processWorkers()"
                    />
                </div>
            </div>
        </div>
        <div class="col-sm-12"><hr /></div>
        <div class="col-sm-12">
            <tableSimples class="tableProcessamentoDriver">
                <thead>
                    <br />
                    <tr>
                        <th class="col-sm-8 pl-3">Colaborador</th>
                        <th style="text-align: center">Status processamento</th>
                    </tr>
                </thead>
                <tbody v-if="dataGerar.length">
                    <tr v-for="(col, index) in dataGerar" :key="'table_processamento--' + index">
                        <td class="pl-2 col-sm-8">{{ col.mtnome }}</td>
                        <td class="pl-5">
                            <span
                                :class="'circle ' + col.status"
                                :title="col.exibirTitulo? 'Datas com problema:\n' + col.titulo.join(', '): ''"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <statusbar
                        :statusBar="statusBar"
                        colspanForTd="2"
                        msgCentro="selecione os campos obrigatorios para gerar o relatório"
                    />
                </tbody>
            </tableSimples>
        </div>
        <eagleModal
            title="Informativo do processamento"
            id="modalInformativoProg"
            :hardToClose="true">
            <template #modalBody>
                <div class="col-sm-12 nopadding row">
                    <div class="col-sm-12 nopadding">
                        <div class="displayModal">
                            <div class="col-sm-12 nopadding">
                                <div class="col-sm-1">Dia(s):</div>
                                <div class="col-sm-11">
                                    <b-badge
                                        class="badge-day"
                                        v-for="day in arrayDias"
                                        :variant="decideVarianteBadge(day.dia)"
                                        :key="day.dia">
                                        {{ formataData(day.dia, false) }}
                                    </b-badge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12"><hr /></div>
                    <div class="col-sm-12 mb-4">
                        Motorista(s):
                        {{
                            sendoProcessadoAg(progressValue) +
                            "/" +
                            trabalhadoresAhSeremProcessados()
                        }}
                        <b-progress :max="progressTotal" height="2rem" class="progressBar">
                            <b-progress-bar
                                :label="labelProgress()"
                                :value="progressValue"
                                animated
                                variant="success"
                            />
                        </b-progress>
                    </div>
                </div>
            </template>
            <template #modalFooter v-if="progressValue != progressTotal">
                Processando, aguarde! <b-spinner small title="" />
            </template>
            <template #modalFooter v-else>
                <div class="col-sm-12 row">
                    <div class="col-sm-8 errosProcessamento">
                        <span v-if="errosProcessamento.length">
                            <span>
                                <span class="texto"> Problemas no processamento!</span>
                                <span class="link" event="click" @click="abreModalErros"
                                    >Confira aqui</span
                                >
                            </span>
                        </span>
                        <span v-if="!errosProcessamento.length" class="textoSucesso">
                            Todos processados com sucesso
                        </span>
                    </div>
                    <div></div>
                    <div class="col-sm-4 nopadding" align="right">
                        <simpleButton
                            type="red"
                            text="Fechar"
                            :icon="mdiCloseThick"
                            event="click"
                            @click="fechaModal"
                        />
                    </div>
                </div>
            </template>
        </eagleModal>
        <eagleModal id="errosProcessamento">
            <template #modalBody>
                <div class="col-sm-12 nopadding divEmVolta">
                    <table class="tabelaDeErros">
                        <thead class="tableTitulos">
                            <tr>
                                <th>Data</th>
                                <th>Motorista</th>
                                <th>Motivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(erro, index) in errosProcessamento"
                                :key="'table-erro__' + index"
                            >
                                <td class="dataErro">{{ displayDia(erro.data, true) }}</td>
                                <td class="dataErro">{{ erro.mtnome }}</td>
                                <td class="dataErro">{{ erro.erro }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
            <template #modalFooter>
                <simpleButton
                    type="red"
                    text="Fechar"
                    :icon="mdiCloseThick"
                    event="click"
                    @click="fechaModalErros"
                />
            </template>
        </eagleModal>
    </eaglePanel>
</template>

<script>
import { mdiThumbUpOutline, mdiDatabaseSync, mdiCloseThick } from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { conectionError } from "@/Services/Helpers/swellHeper";

export default {
    name: "processamentoDriver",
    components: {
        eagleModal: require("@/components/Atom/Modal/ModalEagle").default,
        statusbar: require("@/components/Atom/StatusInformation/StatusInformation").default,
        tableSimples: require("@/components/Atom/Table/TableSimples").default,
        selectAll: require("@/components/Atom/Select/SelectAll").default,
        dayPicker: require("@/components/Atom/Datas/InputRangeWithLimit").default,
        eaglePanel: require("@/components/Atom/Panel/PanelEagle").default,
        titulo: require("@/components/Atom/Header/Titulo").default,
        simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
    },
    data() {
        return {
            mdiCloseThick: mdiCloseThick,
            mdiDatabaseSync: mdiDatabaseSync,
            mdiThumbUpOutline: mdiThumbUpOutline,
            loadingPanel: false,
            labelsSeletorDeGrupoAhSerProcessado: [
                { indexDFH: "MR", description: "Colaboradores*" },
            ],
            optSelectColaboradores: [],
            urlBase: "/gestao/desempenho/processamento/",
            optSelectEmpresas: new EmpresasService().Get(),
            data: {
                data: "",
                cliente: [],
                mot: [],
            },
            carregandoGrupos: false,

            dataParaSeletor: [],
            dataGerar: [],
            dataProcessamento: [],
            statusBar: "info",
            todosSelecionados: false,
            todosRFID: false,
            progressValue: 0,
            progressTotal: 0,
            objsAProcessar: [],
            arrayDias: [],
            errosProcessamento: [],
            selectedEmpresa: [],
            progressCurrentDay: "",
            progressDaysDone: new Set(),
            processar: false,
        };
    },
    validations: {
        data: {
            data: { required },
            cliente: { required },
            mot: { required },
        },
        dataGerar: { required },
    },
    methods: {
        ...mapGetters(["getMaster", "getEditar", "getCadastrar"]),

        /**
         * Alteração no periodo de tempo em que o relatório deve abrangir
         */
        mudaPeriodo(value) {
            this.dataGerar = [];
            let periodo = value.split(" - ");
            this.data.data = periodo;
            this.processar = true;
        },

        /**
         * @description calcula a % atual da lista de requests,
         * @returns {string} #%
         */
        labelProgress() {
            var perce = this.progressValue / this.progressTotal;
            perce = perce * 100;
            perce = Math.floor(perce);
            return `${perce}%`;
        },

        /**
         * @param {string} data no formato yyyy/mm/dd
         * @return {string} no formato dd/mm/yyyy
         */
        formataData(data, year = true) {
            var reg = /(\d{4})-(\d{2})-(\d{2})/;
            var subs;
            if (year) subs = "$3/$2/$1";
            else subs = "$3/$2";
            return data.replace(reg, subs);
        },

        /**
         * @param {string} day - a data que o badge representa
         * @return {string} com a variante do bad
         * @description verifica se o badge em questão já foi processado
         * ou já esta sendo processado no momento, e decide a variante de
         * acordo com isso.
         */
        decideVarianteBadge(day) {
            if (day === this.progressCurentDay) return "primary";
            if (this.progressDaysDone.has(day)) return "success";
            return "secondary";
        },

        /**
         * Limpa os dados exibidos e já faz o request
         * que traz as informações do segundo seletor de acordo com
         * a empresa selecionada
         */
        mudaEmpresa(arr) {
            this.processar = true;
            this.dataGerar = [];
            this.$refs.seletorMultiplo.clearAll();
            this.data.cliente = arr;
            this.optSelectColaboradores = [];
            if (this.data.cliente.length) {
                this.carregandoGrupos = true;
                new FiltrosService().dados_filtros(this.data.cliente, ["C"]).then((data) => {
                    // this.dataParaSeletor = data
                    // this.optSelectColaboradores = this.dataParaSeletor[
                    //     this.$refs.seletorMultiplo.labelSelected
                    // ]
                    this.optSelectColaboradores = data.C;
                    this.carregandoGrupos = false;
                });
            }
        },

        selectColaborador(colaborador) {
            this.dataGerar = [];
            this.data.mot = colaborador;
            this.processar = true;
        },

        /**
         * Gera a tabela de funcionarios e já seta
         * alguns dos parametros para exibição
         * de informação para o usuário
         */
        async gerarRelatorio() {
            this.loadingPanel = true;
            // this.statusBar = 'loading'
            this.dataGerar = [];
            let uri = this.urlBase + "gerar";
            await new HttpRequest().Post(uri, { data: this.data, gerar: true }).then((dados) => {
                if (dados.code == 200) {
                    this.dataGerar = dados.data.colaboradores;
                    this.dataGerar.forEach((el) => {
                        el.titulo = [];
                        el.exibirTitulo = false;
                    });
                }
            });
            this.loadingPanel = false;
        },

        processWorkers() {
            this.processar = false;
            const obj = {
                colabs: this.dataGerar,
                data: this.data.data,
            };
            this.loadingPanel = true;
            new HttpRequest()
                .Post(this.urlBase + "salvaFilaProcessamento", obj)
                .then((resposta) => {
                    if (resposta.code == 200) {
                        const msg = "Processamento em andamento, você será notificado após o termino!";
                        this.toastShow("Sucesso!", msg, "success");
                    } else {
                        const msg = "Ocorreu um erro ao criar fila de processamento!";
                        this.toastShow("Ops!", msg, "danger");
                    }
                })
                .catch(() => {
                    conectionError();
                })
                .finally(() => {
                    this.loadingPanel = false;
                });
        },

        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            });
        },

        /**
         * @description ordena a array de erros,
         * primariamente pela data e, em seguida, pelo nome do usuário
         */
        ordenarErros() {
            this.errosProcessamento = _.orderBy(
                this.errosProcessamento,
                ["data", "mtnome"],
                ["asc", "asc"]
            );
        },

        criaObjetos() {
            var objs = [];
            let diasAhProcessar = this.arrayDiasAhProcessar();
            this.arrayDias = diasAhProcessar.map((dia) => {
                return { dia: dia, cor: "white" };
            });
            diasAhProcessar.forEach((day) => {
                this.dataGerar.forEach((t) => {
                    var obj = {
                        codMotorista: t.mtcodigo,
                        data: day,
                        processado: false,
                    };
                    objs.push(obj);
                });
            });
            return objs;
        },

        /**
         * @param {object} info - resultado do processamento.
         * @param {boolean} info.processamento - se deu boa.
         * @param {string[]} info.erros - array de msg de erros
         * @param {object} obj
         * @param {number} obj.codMotorista - código do funcionário.
         * @param {string} obj.data- data do processamento
         * @description atualiza o trabalhador na tabela e já
         * adiciona os erros (caso tiver) na array de erros.
         * @author Gui 🍺
         */
        attWorker(info, { codMotorista, data }) {
            var w = _.find(this.dataGerar, { mtcodigo: codMotorista });
            if (info.info.length === 0) {
                if (w.status === "painel-gray") w.status = "painel-green";
            } else {
                w.status = "painel-pink";
                this.errosProcessamento.push({
                    mtnome: w.mtnome,
                    mtcodigo: w.codMotorista,
                    data: data,
                    erro: info.info,
                });
            }
        },

        /**
         * Verifica qual trabalador esta sendo processado
         * pelo código do mesmo, dá até pra devolver o nome
         * do cara agora
         * @author Gui 🍺
         */
        sendoProcessadoAg(valor) {
            let index = 0;
            var AhProcessar = this.dataGerar;
            if (this.objsAProcessar[valor] !== undefined) {
                let cod = this.objsAProcessar[valor].codMotorista;
                index = AhProcessar.findIndex((a) => {
                    return a.mtcodigo == cod;
                });
                // comentado pois estava fazendo a barra de processamento mostrar a porcentagem errada
                // deixei comentado pq vai que né
                // index += 1
                if (index == 0) {
                    index = AhProcessar.length;
                }
            } else {
                index = AhProcessar.length;
            }
            return index;
        },

        /**
         * Para transformar os dados do dia em um
         * formato entendivel para o usuário
         * @param dia data, podendo ser qualquer fomato
         * que o luxon entenda
         * @param completo true se deve incluir o ano
         * @returns string formatada com a data
         * @author Gui 🍺🍺
         */
        displayDia(dia, completo = false) {
            if (completo) return DateTime.fromFormat(dia, "yyyy-MM-dd").toFormat("dd/MM/yyyy");
            return DateTime.fromFormat(dia, "yyyy-MM-dd").toFormat("dd/MM");
        },

        /**
         * Retorna a array com todos os dias que devem ser
         * inclusos nas requests para o back-end
         */
        arrayDiasAhProcessar() {
            let y = this.arrayDatas(this.data.data[0], this.data.data[1]);
            return y;
        },

        abreModalErros() {
            this.$bvModal.show("errosProcessamento");
        },

        fechaModalErros() {
            this.fechaModal();
            this.$bvModal.hide("errosProcessamento");
        },

        abreModal() {
            this.$bvModal.show("modalInformativoProg");
        },

        fechaModal() {
            this.$bvModal.hide("modalInformativoProg");
        },

        trabalhadoresAhSeremProcessados() {
            let qtd = this.dataGerar.filter((a) => {
                return a;
            });
            return qtd.length;
        },

        /**
         * Copiei essas próximas 3 funções do serviço antigo
         */
        arrayDatas(ini, fim) {
            let d1 = this.toDate(ini),
                d2 = this.toDate(fim),
                intervalos = [];

            while (d1 <= d2) {
                intervalos.push(this.toString(d1));
                d1.setDate(d1.getDate() + 1);
            }
            return intervalos;
        },

        toDate(texto) {
            let partes = texto.split("/");
            return new Date(partes[2], partes[1] - 1, partes[0]);
        },

        toString(date) {
            return (
                date.getFullYear() +
                "-" +
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "-" +
                ("0" + date.getDate()).slice(-2)
            );
        },
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optSelectEmpresas;
        }
    },

    computed: {},
};
</script>

<style lang="scss" scoped>
.errosProcessamento {
    padding-top: 0.5% !important;
    text-align: center;
    .texto {
        color: red;
    }
    .link {
        margin: 5px;
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }
    .textoSucesso {
        color: #2b942b;
    }
}

.displayModal {
    display: inline;
    padding: 40px;
    .diaP {
        width: 50px;
        border: solid 1px black;
        margin: 3px;
        padding: 4px;
        overflow-wrap: anywhere;
    }
    .green {
        color: white;
        background-color: #6db000;
    }
    .white {
        color: black;
        background-color: white;
    }
    .blue {
        color: white;
        background-color: darkblue;
    }
}
.window {
    padding: 10px;
    border: #428bca 1px solid;
}
.divEmVolta {
    max-height: 400px;
    overflow: auto;
    .tabelaDeErros {
        width: 100%;
        .tableTitulos {
            padding: 1px;
            position: sticky;
            top: -1px;
            background: #f5f5f5;
        }
        .dataErro {
            padding: 10px;
        }
    }
}
.badge-day {
    width: 65px;
    margin: 3px;
    padding: 4px;
    overflow-wrap: normal;
    font-size: 12px !important;
}
td {
    text-align: left;
}
.botaoFiltros {
    margin-top: 33%;
}
.circle {
    padding-right: 24px;
    margin-right: 7px;
    border: 2px solid #fff;
    border-radius: 50%;
}
.painel-green {
    background-color: #6db000;
}
.painel-gray {
    background-color: #b5b5b5;
}
.painel-pink {
    background-color: #f8a1a2;
}
.painel-gray {
    background-color: #b5b5b5;
}

.tableProcessamentoDriver {
    overflow-x: hidden !important;
}
</style>
