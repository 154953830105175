/**
    Este componente é de legendas para as telas

    -> Propriedades:
        1- title - String - Titulo das legendas quando necessário - EX: 'Legenda'
        2- items - Array - Array de objetos a serem colocados na legenda - EX: [{description: 'legenda', spanColor:'Cor da bolinha se necessario(olhar css)', colWidth: 1-12}]

    -> Slots:
        Sem slots

    -> Eventos:
        Sem eventos
 */
<template>
    <div v-if="cssPersonalizado" class="col-sm-12 divPaiLegenda">
        <div v-if="title != ''" class="col-sm-12 divTitleLegenda">{{ title }}</div>
        <slot></slot>
        <div class="col-sm-12 nopadding divCorpoLegenda row">
            <div class="col-sm-6" v-for="(line, a) in items" v-bind:key="a" :class="retornaClasse(line)">
                <span v-if="line.spanColor != '' || line.icone != ''" :class="'spanBolinha span-' + line.spanColor"></span>
                <baseIcon v-if="line.icone != null" size='24' :icon=line.icone />
                {{ line.description }}
            </div>
        </div>
    </div>

    <div v-else class="col-sm-12 divPaiLegenda">
        <div v-if="title != ''" class="col-sm-12 divTitleLegenda">{{ title }}</div>

        <div class="col-sm-12 nopadding divCorpoLegenda row">

            <div class="no-wrap" v-for="(line, a) in items" v-bind:key="a" :class="retornaClasse(line)">
                <p class='text-truncate' :title="line.description">
                    <span v-if="line.spanColor != ''" :class="'spanBolinha span-' + line.spanColor"></span>
                    <baseIcon v-if="line.icone != null" size='24' :class=line.iconColor :icon=line.icone />
                    {{ line.description }}
                </p>
            </div>

        </div>

    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    },

    props: {
        cssPersonalizado: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        items: {
            type: Array,
            required: true,
            default: function () { return [] }
            // {description, spanColor, colWidth}
        },
    },
    methods: {
        retornaClasse(line): string {
            return 'col-sm-' + (
                (line.colWidth != '' && line.colWidth != undefined) ? line.colWidth : '12'
            ) + ' divLineLegenda'
        }

    },

})

</script>

<style lang="scss">
.row {
    margin: 0 -15px;
}


.iconGreen {
    color: #95b65c;
}

.iconBlue {
    color: #428BCA;
}

.iconOrange {
    color: #F0AD4E;
}

.iconRed {
    color: #E04B4A;
}

.divPaiLegenda {
    background: #dae1e9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 0px;
    margin-top: 3px;
    padding: 5px 5px;
    text-align: left;
    padding-bottom: 5px;
    font-family: nexabook, sans-serif !important;
    ;

    .divTitleLegenda {
        font-weight: bold;
        text-align: center;
        font-size: 14px;
    }

    .divCorpoLegenda {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
        text-align: left;
    }

    .divLineLegenda {
        padding-top: 0px;
        font-size: 13px;
        padding-left: 5px;
        padding-right: 5px;

        .spanBolinha {
            padding-right: 15px;
            margin-right: 7px;
            border: 1px solid #ccc;
            border-radius: 9px;
            border: 1px solid #ccc;

            &.span-orange {
                background-color: #ffd08e;
            }

            &.span-yellow {
                background-color: #faf6c5;
            }

            &.span-green {
                background-color: #95b65c;
            }

            &.span-red {
                background-color: #f5c2c2;
            }

            &.span-blue {
                background-color: #a6cfff;
            }

            &.span-white {
                background-color: #FFF;
            }

            &.span-light-green {
                background-color: #abe050;
            }

            &.span-light-blue {
                background-color: #5BC0DE;
            }

            &.span-gray {
                background-color: #a0a0a0;
            }

            &.span-superGray {
                background-color: #b8b8b8;
            }

            &.span-ligth-yellow {
                background-color: #eedf3e;
            }
        }
    }
}
</style>