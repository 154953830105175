<!-- 
Tipo do registro                    -> Tipo do registro. Preencher com "01". 
Tipo de identificador do empregador -> Tipo de identificador do empregador:  "1": CNPJ  - "2": CPF 
CNPJ                                -> CNPJ (14 dígitos) ou CPF (11 dígitos) do empregador. 
CAEPF                               -> (Cadastro de Atividade Econômica da Pessoa Física), caso exista. Se não existir, preencher com espaços. 
CNO                                 -> (Cadastro Nacional de Obras), caso exista. Se não existir, preencher com espaços. 
Razão social ou nome do empregador  -> Razão social ou nome do empregador. Deve ser preenchido com espaços à direita para completar o tamanho máximo se necessário.
Data inicial dos registros          -> Data inicial dos registros no AEJ no formato AAAA-MM-DD 
Data Final dos registros            -> Data final dos registros no AEJ no formato AAAA-MM-DD 
Data e hora da geração do AEJ       -> Data e hora da geração do AEJ no formato AAAA-MM-DDThh:mm:00ZZZZZ
Versão do leiaute do AEJ            -> Versão do leiaute do AEJ. Preencher com "001".
-->

<template>
    <div class="mt-4 col-12 nopadding">
        <tableRelatorio>
            <template slot="thead">
            </template>
            <template slot="tbody">
                <tr class="backColorTdPDF">
                    <td colspan="13" class="nopadding">
                        <span class="newbadge">
                            {{dados.descricao}}
                        </span>
                    </td>
                </tr>
                <tr class="trTdHeader">
                    <td>Tipo do registro</td>
                    <td>Tipo de identificador do empregador</td>
                    <td>CNPJ</td>
                    <td>CAEPF</td>
                    <td>CNO</td>
                    <td>Razão social ou nome do empregador</td>
                    <td>Data inicial dos registros</td>
                    <td>Data Final dos registros</td>
                    <td>Data e hora da geração do AEJ</td>
                    <td>Versão do leiaute do AEJ</td>
                </tr>
                <tr>
                    <td>{{dados.tipoReg}}</td>
                    <td>{{dados.tpIdtEmpregador}}</td>
                    <td>{{dados.idtEmpregador}}</td>
                    <td>{{dados.caepf}}</td>
                    <td>{{dados.cno}}</td>
                    <td>{{dados.razaoOuNome}}</td>
                    <td>{{dados.dataInicialAej}}</td>
                    <td>{{dados.dataFinalAej}}</td>
                    <td>{{dados.dataHoraGerAej}}</td>
                    <td>{{dados.versaoAej}}</td>
                </tr>
            </template>
        </tableRelatorio>
    </div>
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
    name:'registroAEJTipo1',
    components:{
		tableRelatorio: require('@/components/Atom/Table/TableRelatorio').default,
    },
    props:{
        dados:{
            type: Object,
            required: true,
            default: function(){return {}}
        },
    },
})
</script>
    
<style lang="scss">
    
</style>