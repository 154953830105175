<template>
	<panelEagle id="eventosVeiculo" :loading="relatorio.loading">
		<div class="col-sm-12 row">
			<div class="col-sm-5 nopadding">
				<titulo titulo="Eventos do Veículo" :icon="mdiCarInfo" />
			</div>
			<div class="col-sm-7 nopadding divDontPrint">
				<basicEIG
					:disabled="$v.$invalid || !horasValidas || this.relatorio.veiculo != null && this.relatorio.veiculo[0] == undefined"
					:loading="relatorio.loadingExportar" @gerarRelatorio="gerarRelatorio"
					@exportarRelatorio="exportarRelatorioResumido"
					:disabledDropdown="$v.$invalid || !relatorio.dados.length || !horasValidas" :btnLegenda=true>

					<simpleButton :type='relatorioResumido ? "grey" : "green"' text='Detalhado' event='click'
						@click="alterarRelatorio(false)" />
					<simpleButton :type='relatorioResumido ? "green" : "grey"' event='click' text='Resumido'
						@click="alterarRelatorio(true)" />
				</basicEIG>
			</div>
		</div>

		<slideUpDown class="divDontPrint">
			<div class="col-sm-12">
				<hr />
			</div>
			<div class="col-12 row p-0 pb-3">
				<div class="col-9 row nopadding">
					<div class="col-4 nopadding">
						<inputRangeWithLimit name="eventosVeiculoData" :isObrigatorio="true" opens='rigth' :intervalo="7"
							@changeInput="changeData" />
					</div>
					<div class="col-4 nopadding">
						<select-all ref="refSeletorEmpresa" nameForRadio="seletorEmpresa" extraClassParent="nopadding"
							:labels="labelSeletorEmpresa" @changeSelect="changeEmpresa" :isMultiple=multiplasEmpresas
							:hasSelectAll=multiplasEmpresas :optionsArray="optSelectEmpresa" :selected="selectedEmpresa" />
					</div>
					<div class="col-4 nopadding">
						<selectAll ref="refSeletorVeiculo" nameForRadio="seletorVeiculo" :disabled="!optSelectVeiculo.length > 0"
							:labels="labelSeletorVeiculo" :isMultiple=multiplasEmpresas :hasSelectAll=multiplasEmpresas
							:optionsArray="optSelectVeiculo" @changeSelect="changeVeiculo" firstSelected="V"
							:loading="relatorio.loadingVeiculos" />
					</div>
					<div class="col-12 row p-0 pt-2">
						<div class="col-4 nopadding">
							<inputSimple :inputClass="{ 'border border-danger': !horaIniOk }" @changeInput="changeHoraInicio"
								:hasMask="true" :mask="['hN:MN']" :value="relatorio.horaini" label="Hora Inicial" />
						</div>
						<div class="col-4 nopadding">
							<inputSimple :inputClass="{ 'border border-danger': !horaFimOk }" extraClass="p-0"
								@changeInput="changeHoraFim" :hasMask="true" :mask="['hN:MN']" :value="relatorio.horafim"
								label="Hora Final" />
						</div>
						<div class="col-4 nopadding">
							<!-- relatório resumido -->
							<span v-show="relatorioResumido">
								<selectAll ref="refSeletorEventos" nameForRadio="seletorEventos" id="eventosResumido"
									:disabled="!optSelectEventosResumido.length > 0" :labels="labelSeletorEventos" :isMultiple="true"
									:hasSelectAll="true" :optionsArray="optSelectEventosResumido" @changeSelect="changeEventos"
									firstSelected="EV" :loading="relatorio.loadingVeiculos" :selected="selectedEventos" />
							</span>

							<!-- relatório detalhado -->
							<span v-show="!relatorioResumido">
								<selectAll ref="refSeletorEventos" nameForRadio="seletorEventos" id="eve"
									:disabled="!optSelectVeiculo.length > 0" :labels="labelSeletorEventos" :isMultiple="true"
									:hasSelectAll="true" :optionsArray="optSelectEventos" @changeSelect="changeEventos" firstSelected="E"
									:loading="relatorio.loadingVeiculos" :selected="selectedEventos" />
							</span>
						</div>
					</div>
				</div>
				<div class="col-3 pt-4 nopadding row confirmaServicos mt-4 ">
					<div>
						<b-form-checkbox v-model="multiplasEmpresas" :id="checkboxMultiplasEmpresas" name="multiEmpresacheck"
							@change="changeCheckboxMultiEmpresa()" />
					</div>
					<div style="text-align: left">
						Selecionar múltiplas empresas e veículos
					</div>
				</div>
			</div>
		</slideUpDown>

		<div class="col-sm-12 divDontPrint">
			<hr />
		</div>

		<div class="col-sm-12 divDontPrint">
			<div v-if="!relatorioResumido">
				<TabelaRelatorioDetalhado :relatorio="relatorio" :mostrarDados="mostrarDados" :alertas="alertas" />
			</div>

			<div v-else>
				<TabelaRelatorioResumido :relatorio="relatorio.dados" :mostrarDados="mostrarDados" :alertas="alertas" />
			</div>
		</div>

		<div>
			<b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
				<div class="col-sm-12 nopadding row">
					<div class="col-sm-12 nopadding">
						<subtitle :items="itemsSubtitlePontos">
						</subtitle>
					</div>
				</div>
			</b-popover>
		</div>
	</panelEagle>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import {
	mdiCarInfo,
	mdiTarget,
	mdiCellphoneMessage,
	mdiDrawPen,
	mdiCarConnected,
	mdiCheckCircle,
	mdiCloseCircle,
	mdiBattery30,
	mdiBattery60,
	mdiBattery90,
	mdiBattery,
} from "@mdi/js";
import { EmpresasService } from "@/Services/auth/Empresas.service";
import { FiltrosService } from "@/Services/filtros/filtros.Service";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { mapGetters } from "vuex";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { validarHoras } from "@/Services/Helpers/DataHelper.ts";
import simpleButton from '@/components/Atom/Buttons/SimpleButton'

export default {
	name: "excessoVelocidade",
	components: {
		inputRangeWithLimit: require('@/components/Atom/Datas/InputRangeWithLimit').default,
		SelectAll: require("@/components/Atom/Select/SelectAll").default,
		panelEagle: require("@/components/Atom/Panel/PanelEagle").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		slideUpDown: require("@/components/Atom/SlideUpAndDown/SlideUpAndDown").default,
		basicEIG: require("@/components/Atom/Buttons/BasicButtonsRelatoriosEIG").default,
		inputSimple: require("@/components/Atom/Inputs/InputSimple").default,
		subtitle: require('@/components/Atom/Subtitle/Subtitle').default,
		simpleButton,
		'TabelaRelatorioDetalhado': require('@/components/Atom/SpecificComponents/EventosVeiculos/TablesSimplesDetalhado').default,
		'TabelaRelatorioResumido': require('@/components/Atom/SpecificComponents/EventosVeiculos/TablesSimplesResumido').default,
	},

	data() {
		return {
			url: "/telemetria/relatorios/eventos/veiculo/",
			mdiTarget: mdiTarget,
			mdiCarInfo: mdiCarInfo,
			mdiBattery: mdiBattery,
			mdiBattery90: mdiBattery90,
			mdiBattery60: mdiBattery60,
			mdiBattery30: mdiBattery30,
			mdiCellphoneMessage: mdiCellphoneMessage,
			mdiDrawPen: mdiDrawPen,
			mdiCarConnected: mdiCarConnected,
			mdiCheckCircle: mdiCheckCircle,
			mdiCloseCircle: mdiCloseCircle,
			labelSeletorVeiculo: [{ indexDFH: "V", description: "Veículo*" }],
			labelSeletorEmpresa: [{ indexDFH: "C", description: "Empresa*" }],
			labelSeletorEventos: [{ indexDFH: "E", description: "Eventos" }],
			optSelectEmpresa: [],
			optSelectVeiculo: [],
			optSelectEventos: [
				{ value: 80, description: 'Aceleração brusca' },
				{ value: 89, description: 'Aceleração brusca em curva' },
				{ value: 2000, description: 'Banguela' },
				{ value: 90, description: 'Colisão' },
				{ value: 4000, description: 'Conduzir sem se identificar' },
				{ value: 87, description: 'Curva em alta velocidade' },
				{ value: 78, description: 'Excesso Velocidade Chuva' },
				{ value: 37, description: 'Excesso Velocidade Seco' },
				{ value: 82, description: 'Freada brusca' },
				{ value: 88, description: 'Freada brusca em curva' },
				{ value: 3000, description: 'Fora de faixa verde' },
				{ value: 69, description: 'Marcha Lenta Excessiva (Excesso de tempo parado com ignição ligada)' },
				{ value: 1000, description: 'Rotação Excessiva' },
			],
			optSelectEventosResumido: [],
			itemsSubtitlePontos: [
				{ 'description': 'Eventos do veículo', 'spanColor': '', 'icone': mdiCarConnected, 'iconColor': 'iconGreen' },
				{ 'description': 'Comandos enviados', 'spanColor': '', 'icone': mdiCellphoneMessage, 'iconColor': 'iconBlue' },
				{ 'description': 'Tratativas realizadas', 'spanColor': '', 'icone': mdiDrawPen, 'iconColor': 'iconOrange' },
			],
			next_page: "",
			loading: false,
			relatorio: {
				data: null,
				cliente: null,
				veiculo: null,
				horaini: "00:00",
				horafim: "23:59",
				eventos: [],
				dados: [],
				info: "info",
				loading: false,
				loadingExportar: [false, false, false],
				loadingVeiculos: false,
			},
			selectedEmpresa: [],
			horaIniOk: true,
			horaFimOk: true,
			alertas: [
				11,
				13,
				15,
				17,
				19,
				23,
				25,
				27,
				31,
				33,
				35,
				37,
				45,
				46,
				47,
				48,
				53,
				67,
				68,
				69,
				70,
				80,
				82,
				87,
				88,
				89
			],
			paramsCopia: {},
			multiplasEmpresas: false,
			checkboxMultiplasEmpresas: '',
			selectedEventos: [],
			relatorioResumido: false,
		};
	},

	validations: {
		relatorio: {
			data: { required },
			cliente: { required },
			veiculo: { required },
			eventos: {
				required: requiredIf(function () {
					if (!this.multiplasEmpresas) {
						return false
					}
					return true
				}),
			},
		},
	},

	methods: {
		...mapGetters(["getMaster"]),

		/**
		 * Altera o relatório resumido com um novo relatório.
		 * @param {Object} relatorio - O relatório que será atribuído à variável `relatorioResumido`.
		 * @author Yuri 🇧🇷
		 */
		alterarRelatorio(relatorio) {
			this.limparRelatorio();
			this.relatorioResumido = relatorio
		},

		changeData(value) {
			this.limparRelatorio();
			this.relatorio.data = value;
		},

		changeEmpresa(value) {
			this.limparVeiculo();
			// Verifica se multiplasEmpresas está ativado
			if (this.multiplasEmpresas) {
				this.relatorio.cliente = value;
			} else {
				this.relatorio.cliente = [value[0]];
			}
			this.buscarFiltros();
		},

		changeVeiculo(value) {
			this.limparRelatorio();

			if (this.multiplasEmpresas) {
				this.relatorio.veiculo = value;
			} else {
				this.relatorio.veiculo = [value[0]];
			}
		},

		changeEventos(value) {
			this.limparRelatorio();
			this.relatorio.eventos = value;
		},

		changeHoraInicio(value) {
			this.limparRelatorio();
			this.relatorio.horaini = value;
			let valido = validarHoras(this.relatorio.horaini, this.relatorio.horafim);
			this.horaIniOk = valido.inicioOk;
			this.horaFimOk = valido.fimOk;
		},

		/**
		* @description Função para alterar o comportamento 
		*  dos Selects Empresa/Veiculos/Eventos. 
		*  Veiculos e Eventos tornan-se multiplos e enventos nao.
		* @param {Boolean} multiplasEmpresas - Variavel de controle do checkbox
		* @author VeCo® 🔱
		*/
		changeCheckboxMultiEmpresa() {
			if (!this.multiplasEmpresas) {
				this.multiplasEmpresas = false;
				this.labelSeletorEventos = [{ indexDFH: "E", description: "Eventos" }];
			} else {
				const msg = "Ao selecionar múltiplas empresas o relatório irá gerar somente os eventos selecionados."
				this.toastShow("Eventos de Veículo", msg, "warning");
				this.multiplasEmpresas = true;
				this.labelSeletorEventos = [{ indexDFH: "E", description: "Eventos*" }];
			}
			this.selectedEmpresa = [];
			this.selectedEventos = [];
		},

		/**
		* @description toastShow 
		*  Exibe a msg no canto direito superior como um toastShow. 
		*/
		toastShow(title, msg, type) {
			this.$bvToast.toast(msg, {
				autoHideDelay: 2500,
				variant: type,
				title: title
			});
		},

		changeHoraFim(value) {
			this.limparRelatorio();
			this.relatorio.horafim = value;
			let valido = validarHoras(this.relatorio.horaini, this.relatorio.horafim);
			this.horaIniOk = valido.inicioOk;
			this.horaFimOk = valido.fimOk;
		},

		limparVeiculo() {
			this.relatorio.veiculo = null;
			this.$refs.refSeletorVeiculo.clearAll();
			this.optSelectVeiculo = [];
			this.optSelectEventosResumido = [];
			this.limparRelatorio();
		},

		limparRelatorio() {
			this.relatorio.dados = [];
			this.relatorio.info = "info";
		},

		async buscarFiltros() {
			if (this.relatorio.cliente) {
				this.relatorio.loadingVeiculos = true;
				const empresasData = this.multiplasEmpresas
					? this.relatorio.cliente // Se multiplasEmpresas for true, envia como está
					: [this.relatorio.cliente]; // Se for false, coloca dentro de um array

				await new FiltrosService()
					.dados_filtros(empresasData, ["V", "EV"])
					.then((data) => {
						if (data) {
							this.optSelectVeiculo = data.V ?? [];
							this.optSelectEventosResumido = data.EV ?? [];
						}
					})
					.catch(() => conectionError())
					.finally(() => (this.relatorio.loadingVeiculos = false));
			}
		},

		montaPeriodo() {
			let horaIni = this.relatorio.horaini == 5 ? +"00" : this.relatorio.horaini;
			let horaFim = this.relatorio.horafim == 5 ? +"59" : this.relatorio.horafim;
			let data = this.relatorio.data.split(" - ");
			horaIni = data[0] + " " + horaIni;
			horaFim = data[1] + " " + horaFim;
			return [horaIni, horaFim];
		},

		async exportarRelatorioResumido(tipo) {
			this.setLoadingExportar(tipo);
			const periodo = this.montaPeriodo();
			let url = `${this.url}exportar`;
			let obj = {
				tipo: tipo,
				data: this.relatorio.data,
				cliente: this.relatorio.cliente,
				veiculo: this.relatorio.veiculo,
				periodo: periodo,
				arrayDados: this.relatorio.dados,
				eventos: this.relatorio.eventos,
				resumido: this.relatorioResumido
			};

			await new HttpRequest()
				.Post(url, obj)
				.then(({ data, status }) => {
					if (status && data) {
						var root = process.env.VUE_APP_ROOT;
						window.open(root + "/" + data.dados);
					}
				})
				.catch(() => conectionError())
				.finally(() => this.setLoadingExportar());
		},

		setLoadingExportar(tipo = false) {
			let load = [tipo == "pdf", tipo == "xls", tipo == "csv"];
			this.relatorio.loadingExportar = load;
		},

		async gerarRelatorio() {
			this.relatorio.loading = true;
			this.relatorio.dados = [];
			let url = `${this.url}`;

			if (this.relatorioResumido) {
				url += 'gerarResumido'
			} else {
				url += 'gerar'
			}

			let params = {
				data: this.relatorio.data,
				cliente: this.relatorio.cliente,
				veiculo: this.relatorio.veiculo,
				horaini: this.relatorio.horaini,
				horafim: this.relatorio.horafim,
				eventos: this.relatorio.eventos,
				multiplas: this.multiplasEmpresas,
			};
			this.paramsCopia = params
			await new HttpRequest()
				.Post(url, params)
				.then(({ data, status }) => {
					if (data.length <= 0) {
						this.relatorio.info = "error";
					}

					else if (status && data) {
						if (this.relatorioResumido) {
							this.relatorio.dados = data;
						} else {
							this.relatorio.dados = data ? Object.values(data.data["dadosTratados"]) : [];
						}

						this.next_page = data.next_page_url;

						if (!this.relatorio.dados.length) {
							this.relatorio.info = "error";
						}
					} else {
						conectionError();
						this.relatorio.info = "error2";
					}
				})
				.catch(() => conectionError())
				.finally(() => (this.relatorio.loading = false));
		},
	},

	computed: {
		dadosRelatorio() {
			return this.relatorio.dados
		},

		mostrarDados() {
			return this.relatorio.dados && this.relatorio.dados.length;
		},

		horasValidas() {
			return this.horaIniOk && this.horaFimOk;
		},
	},

	mounted() {
		this.optSelectEmpresa = new EmpresasService().Get();
		if (!this.getMaster()) {
			this.selectedEmpresa = [this.optSelectEmpresa[0]] ?? [];
		}
	},
};
</script>

<style lang="scss">
#eventosVeiculo {
	.table {
		tbody {
			tr.linhaPlaca {
				background: #fff !important;
			}
		}

		border-spacing: 40px;
	}

	td {
		text-align: left;
	}

	th {
		text-align: left;
	}

	.confirmaServicos {
		font-family: nexabook, sans-serif !important;
		padding: 2px !important;
		justify-content: center;
	}
}
</style>
