<template>
  <panelEagle id="CadastroPontuacao" :loading="loadingPanel">
    <div class="col-sm-12 nopading row nopadding">
      <div class="col-sm-6">
        <tituloPage :icon="mdiTrophy" titulo="Pontuação" />
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6">
        <basicButtonsCadastrosSC :disabled="$v.$invalid" tipoCancela="button" linkCancel="pontuacao"
          @salvarCadastro="salvarPontuacao">
        </basicButtonsCadastrosSC>
      </div>
    </div>

    <div class="col-sm-12 col-md-12">
      <hr />
    </div>

    <div class="col-sm-12 row nopadding">
      <div class="col-sm-3 nopadding">
        <selectAll :extraClass="{
          'border border-danger': $v.empresasSelecionadas.$anyError
        }" @close="$v.empresasSelecionadas.$touch()" nameForRadio="Selecione*" :selected="empresaSelected"
          :isMultiple="false" :hasSelectAll="false" :optionsArray="optEmpresas"
          :labels="[{ indexDFH: 'SE', description: 'Empresa*' }]" @changeSelect="changeEmpresa" />
      </div>
      <div class="col-sm-3 nopadding">
        <inputSimple name="descrição" label="Descrição*" :value="descricaoSelected"
          :inputClass="{ 'border border-danger': $v.descricao.$anyError }" @blur="$v.descricao.$touch()"
          @changeInput="changeDescricao" />
      </div>
      <div class="col-sm-6 nopadding">
        <buttonsFilters label="Valido para*" :arrayButtons="botoes" :onlyOneSelected="false"
          @buttonsFiltersChange="changeTipo" />
      </div>
      <requiredFields />
    </div>

    <div class="col-sm-12 col-md-12">
      <hr />
    </div>

    <div class="col-sm-12 row tamanho-minimo">
      <div class="col-sm-12 col-md-12 col-lg-3 categorias">
        <!-- 
                    parte onde seleciona as categorias para 
                    aparecer aquela tabela do lado 
                -->
        <b-card no-body header="Categorias" class="tableCategorias">
          <b-list-group v-for="(dados, p) in categorias" :key="dados + p" flush class="border-none">
            <b-list-group-item :disabled="dados[0].pscategoria != 1 && dados[0].pscategoria != 3
              ? disabled
              : false
              " @click="clicaBotao(dados, p)" class="text-left border-none grupoCategoria">
              {{ p }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>

      <div class="col-md-12 col-lg-8 estilo-tabela tamanho-corpo-tabela">
        <table class="tamanho-tabela">
          <thead class="cabecalho">
            <tr>
              <th class="th" colspan="1">Opções</th>
              <th class="th" colspan="1">
                {{
                  this.categoriaAtual == 'Telemetria avançada'
                    ? 'Valor'
                    : 'Tempo'
                }}
              </th>
              <th class="th" colspan="1">Pontuação</th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(subCategoria, index) in categorias">
              <template v-for="(dados, indexx) in categorias[index]">
                <tr :key="indexx + index" v-show="index == categoriaAtual">
                  <td colspan="1" class="checkbox-opc">
                    <b-checkbox @change="changeCheck(dados.psdescricao, dados.pscodigo)"
                      v-model="dados.checkSelecionado" class="checkBox">
                      {{ dados.psdescricao }}
                    </b-checkbox>
                  </td>

                  <td colspan="1">
                    <inputSimple :placeholder="dados.psdescricao == 'Média consumo de combustível'
                      ? '00,00'
                      : '--:--:--'" :hasMask="true" :mask="dados.psdescricao == 'Média consumo de combustível'
                        ? ['NN,NN']
                        : ['NN:NN:NN']
                        " :ref="'inputTempo' + dados.psdescricao" class="inputSimpleTable" :disabled="dados.psdescricao == 'Média consumo de combustível'
                          ? !dados.checkSelecionado
                          : !dados.checkSelecionado || dados.inputDisabled.time
                          " :value="dados.psdescricao == 'Média consumo de combustível'
                            ? dados.ppovalor
                            : dados.ppotempo
                            " :title="validaHora(dados.ppotempo)
                              ? 'Valor máximo  23:59:59'
                              : ''
                              " :inputClass="{
                                'border border-danger':
                                  dados.psdescricao == 'Média consumo de combustível'
                                    ? ''
                                    : validaHora(dados.ppotempo)
                              }" @changeInput="v => {
                                if (
                                  dados.psdescricao == 'Média consumo de combustível'
                                ) {
                                  dados.ppovalor = v
                                } else {
                                  dados.ppotempo = v
                                }
                              }
                                " />
                  </td>

                  <td colspan="1">
                    <inputSimple :hasMask="true" :mask="['NNNNNNNNN']" :ref="'inputPontuacao' + dados.psdescricao"
                      class="inputSimpleTable" :disabled="!dados.checkSelecionado || dados.inputDisabled.pontuacao
                        " :value="dados.ppopontuacao" @changeInput="v => (dados.ppopontuacao = v)" />
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-sm-12 col-md-12">
      <table class="text-left">
        <thead>
          <th>Classificação*</th>
        </thead>
        <tbody>
          <tr>
            <td class="my-auto badge badge-success">Classe A</td>
            <td class="my-auto">De</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoASelectedIni)" :inputClass="{
                'border border-danger': $v.pontosA.$anyError
              }" @blur="$v.pontosA.$touch()" @changeInput="pontoA"
                @input="(e) => pontoASelectedIni = filterInteger(e.target.value)" />
            </td>
            <td>até</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :inputClass="{
                'border border-danger': $v.pontosFinA.$anyError
              }" @blur="$v.pontosFinA.$touch()" :value="filterInteger(pontoASelectedFim)"
                @input="(e) => pontoASelectedFim = filterInteger(e.target.value)" @changeInput="pontoFinA" />
            </td>
            <td>pontos.</td>
          </tr>
          <tr>
            <td class="my-auto badge badge-primary">Classe B</td>
            <td class="my-auto">De</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoBSelectedIni)" :inputClass="{
                'border border-danger': $v.pontosB.$anyError
              }" @blur="$v.pontosB.$touch()" @input="(e) => pontoBSelectedIni = filterInteger(e.target.value)"
                @changeInput="pontoB" />

            </td>
            <td>até</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoBSelectedFim)" :inputClass="{
                'border border-danger': $v.pontosFinB.$anyError
              }" @blur="$v.pontosFinB.$touch()" @input="(e) => pontoBSelectedFim = filterInteger(e.target.value)"
                @changeInput="pontoFinB" />

            </td>
            <td>pontos.</td>
          </tr>
          <tr>
            <td class="my-auto badge badge-warning">Classe C</td>
            <td class="my-auto">De</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoCSelectedIni)" :inputClass="{
                'border border-danger': $v.pontosC.$anyError
              }" @blur="$v.pontosC.$touch()" @input="(e) => pontoCSelectedIni = filterInteger(e.target.value)"
                @changeInput="pontoC" />

            </td>
            <td>até</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoCSelectedFim)" :inputClass="{
                'border border-danger': $v.pontosFinC.$anyError
              }" @blur="$v.pontosFinC.$touch()" @input="(e) => pontoCSelectedFim = filterInteger(e.target.value)"
                @changeInput="pontoFinC" />

            </td>
            <td>pontos.</td>
          </tr>
          <tr>
            <td class="my-auto badge badge-danger">Classe D</td>
            <td class="my-auto">Acima de</td>
            <td class="tamanho-input my-auto" colspan="2">
              <inputSimple :value="filterInteger(pontoDSelectedIni)" :inputClass="{
                'border border-danger': $v.pontosD.$anyError
              }" @blur="$v.pontosD.$touch()" @input="(e) => pontoDSelectedIni = filterInteger(e.target.value)"
                @changeInput="pontoD" />

            </td>
            <td>pontos.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </panelEagle>
</template>
<script>
import Vue from 'vue'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { mdiTrophy } from '@mdi/js'
import { required } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { verifica24Horas } from '@/Services/Helpers/DataHelper.ts'

export default Vue.extend({
  name: 'CadastroPontuacao',

  components: {
    tituloPage: require('@/components/Atom/Header/Titulo').default,
    selectAll: require('@/components/Atom/Select/SelectAll').default,
    panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
    inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
    requiredFields: require('@/components/Atom/Footer/RequiredFields').default,
    buttonsFilters: require('@/components/Atom/Buttons/ButtonsFilters').default,
    basicButtonsCadastrosSC:
      require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default
  },

  validations: {
    pontosA: { required },
    pontosB: { required },
    pontosC: { required },
    pontosD: { required },
    descricao: { required },
    pontosFinA: { required },
    pontosFinB: { required },
    pontosFinC: { required },
    tipoSelecionados: { required },
    empresasSelecionadas: { required },
    categorias: v => {
      return v.retornaTabelaValida
    }
  },

  computed: {
    retornaTabelaValida() {
      let checkSelecionada = Object.values(this.categorias).find(v =>
        v.find(va => va.checkSelecionado)
      )
      if (checkSelecionada === undefined) {
        return false
      }
      let teste = Object.values(this.categorias).filter(_v => {
        let selecionados = _v.filter(c => c.checkSelecionado)
        return selecionados.find(v => {
          if (!verifica24Horas(v.ppotempo)) {
            return true
          }
          let ppotempo = v.ppotempo ?? ''
          let tempo = ppotempo.toString().length === 8
          let tempoObr = !v.inputDisabled.time
          let condTempo = tempoObr ? tempo : true

          let ppovalor = v.ppovalor ?? ''
          let valor = ppovalor.toString().length > 0
          let valorObr = v.pscodigo == 36 ? true : false
          let condValor = valorObr ? valor : true

          let ppopontuacao = v.ppopontuacao ?? ''
          let pontuacao = !!ppopontuacao.toString()
          let pontoObr = !v.inputDisabled.pontuacao
          let condPonto = pontoObr ? pontuacao : false

          return !(condTempo && condPonto && condValor)
        })
      })
      return !teste.length
    }
  },

  data() {
    return {
      teste: true,
      mdiTrophy: mdiTrophy,
      optEmpresas: new EmpresasService().Get(),
      checkSelecionado: false,
      tempoSelecionados: [],
      pontuacaoSelecionada: [],
      subCategorias: [],
      categorias: {},
      descricao: '',
      pontosA: '',
      pontosB: '',
      pontosC: '',
      pontosD: '',
      pontosFinA: '',
      pontosFinB: '',
      pontosFinC: '',
      tipoSelecionados: [],
      descricaoSelected: '',
      tipoMotora: [],
      empresaSelected: [],
      ppacodigo: '',
      empresasSelecionadas: [],
      pontoASelectedIni: '',
      pontoASelectedFim: '',
      pontoBSelectedIni: '',
      pontoBSelectedFim: '',
      pontoCSelectedIni: '',
      pontoCSelectedFim: '',
      pontoDSelectedIni: '',
      pclclasseA: '',
      pclclasseB: '',
      pclclasseC: '',
      pclclasseD: '',
      pclcodigoPontuacao: '',
      pclcodigoA: '',
      pclcodigoB: '',
      pclcodigoC: '',
      pclcodigoD: '',
      disabled: '',
      categoriaAtual: '',
      loadingPanel: false,
      botoes: [
        {
          value: 'M',
          width: '32.5%',
          isSelected: true,
          title: 'Motorista',
          text: 'Motorista',
          extraClass: 'lado',
          icon: ''
        },
        {
          value: 'A',
          width: '32.5%',
          isSelected: false,
          title: 'Ajudante',
          text: 'Ajudante',
          icon: ''
        },
        {
          value: 'D',
          width: '32.5%',
          isSelected: false,
          title: 'Administrativo',
          text: 'Administrativo',
          icon: ''
        }
      ]
    }
  },

  methods: {
    validaHora(hora) {
      if (!hora) return false
      return !verifica24Horas(hora)
    },

    filterInteger(value) {
      if (typeof value === 'string') {
        return value.replace(/\D/g, '');
      }

      return value;
    },

    changeCheck(ref, codigo) {
      var refTempo = 'inputTempo' + ref
      var refPontuacao = 'inputPontuacao' + ref
      this.$refs[refTempo][0].clearAll()
      this.$refs[refPontuacao][0].clearAll()
      this.changeTempo('', codigo)
      this.changePontuacao('', codigo)
    },

    changeEmpresa(selecionada) {
      this.empresasSelecionadas = selecionada
    },

    changeTipo(tipo) {
      this.tipoSelecionados = tipo
    },

    changeDescricao(descricao) {
      this.descricao = descricao
    },

    /**
     * @deprecated
     */
    changeTempo(tempoSelecionados, pscodigo) {
      for (let i = 0; i < this.categorias[this.categoriaAtual].length; i++) {
        if (pscodigo == this.categorias[this.categoriaAtual][i].pscodigo) {
          this.categorias[this.categoriaAtual][i].ppotempo = tempoSelecionados
        }
      }
    },

    /**
     * @deprecated
     */
    changePontuacao(pontuacaoSelecionada, pscodigo) {
      for (let i = 0; i < this.categorias[this.categoriaAtual].length; i++) {
        if (pscodigo == this.categorias[this.categoriaAtual][i].pscodigo) {
          this.categorias[this.categoriaAtual][i].ppopontuacao =
            pontuacaoSelecionada
        }
      }
    },

    clicaBotao(value, index) {
      this.categoriaAtual = index
      this.subCategorias = value
    },

    pontoA(a) {
      this.pontosA = a
      this.pclclasseA = 'A'
    },

    pontoB(b) {
      this.pontosB = b
      this.pclclasseB = 'B'
    },

    pontoC(c) {
      this.pontosC = c
      this.pclclasseC = 'C'
    },

    pontoD(d) {
      this.pontosD = d
      this.pclclasseD = 'D'
    },

    pontoFinA(a) {
      this.pontosFinA = a
    },

    pontoFinB(b) {
      this.pontosFinB = b
    },

    pontoFinC(c) {
      this.pontosFinC = c
    },

    tabelaCadastro(codigo) {
      this.loadingPanel = true
      new HttpRequest()
        .Post('/gestao/desempenho/cadastros/pontuacao/cadastrar', {
          valor: codigo
        })
        .then(({ data }) => {
          this.categoriaAtual = Object.keys(data.categorias)[0]
          this.categorias = data.categorias
          for (const i in data.categorias) {
            let b = [...data.categorias[i]]
            b.map(v => {
              if (v.checkSelecionada) {
                if (typeof v.ppotempo !== 'string') {
                  v.ppotempo = '12'
                }
                if (typeof v.ppopontuacao !== 'string') {
                  v.ppopontuacao = '00:00:01'
                }
              }
            })
            this.categorias[i] = b
            // obj[i] = b
          }
        })
        .finally(() => {
          this.$nextTick(() => {
            for (const k in this.$refs) {
              this.$refs[k][0].$forceUpdate()
            }
            this.loadingPanel = false
          })
        })
    },

    async salvarPontuacao() {
      // this.loadingPanel = true

      var empresa = [
        { ppacodigo: this.ppacodigo },
        { descricao: this.descricao },
        { tipo: this.tipoSelecionados },
        { empresa: this.empresasSelecionadas[0] }
      ]

      var clasA = [
        { pclcodigoA: this.pclcodigoA },
        { pclclasseA: this.pclclasseA },
        { pontosA: this.pontosA },
        { pontosFinA: this.pontosFinA }
      ]

      var clasB = [
        { pclcodigoB: this.pclcodigoB },
        { pclclasseB: this.pclclasseB },
        { pontosB: this.pontosB },
        { pontosFinB: this.pontosFinB }
      ]

      var clasC = [
        { pclcodigoC: this.pclcodigoC },
        { pclclasseC: this.pclclasseC },
        { pontosC: this.pontosC },
        { pontosFinC: this.pontosFinC }
      ]

      var clasD = [
        { pclcodigoD: this.pclcodigoD },
        { pclclasseD: this.pclclasseD },
        { pontosD: this.pontosD },
        { pclcodigoPontuacao: this.pclcodigoPontuacao }
      ]

      var tabela = this.categorias

      try {
        var dados = await new HttpRequest().Post(
          '/gestao/desempenho/cadastros/pontuacao/salvar',
          {
            empresa,
            clasA,
            clasB,
            clasC,
            clasD,
            tabela
          }
        )

        if (dados.data.success) {
          this.$router.push('/gestao/desempenho/cadastros/pontuacao')
        } else {
          this.modalOpsErro()
        }
      } catch (error) {
        this.modalOpsErro()
      } finally {
        this.loadingPanel = false
      }
    },

    async editarPontuacao(codigo) {
      var dados = await new HttpRequest().Post(
        '/gestao/desempenho/cadastros/pontuacao/editar',
        {
          valor: codigo
        }
      )
      var empresa = dados.data.empresa[0]
      this.ppacodigo = empresa.ppacodigo
      this.empresaSelected = [
        {
          value: empresa.value,
          description: empresa.description
        }
      ]
      this.descricaoSelected = empresa.ppadescricao
      this.tipoMotora = empresa.ppatipomotorista
      this.tipoSelecionados[0] = ''
      this.botoes[0].isSelected = false
      for (let index = 0; index < this.tipoMotora.length; index++) {
        if (this.tipoMotora[index] == 'M') {
          this.tipoSelecionados[0] = this.tipoSelecionados[0] + 'M'
          this.botoes[0].isSelected = true
        }
        if (this.tipoMotora[index] == 'A') {
          this.tipoSelecionados[0] = this.tipoSelecionados[0] + 'A'
          this.botoes[1].isSelected = true
        }
        if (this.tipoMotora[index] == 'D') {
          this.tipoSelecionados[0] = this.tipoSelecionados[0] + 'D'
          this.botoes[2].isSelected = true
        }
      }
      let classificacao = dados.data.classificacao
      if (classificacao.length > 0) {
        this.pclcodigoPontuacao = classificacao[0].pclcodigopontuacao
        for (const i in classificacao) {
          if (classificacao[i].pclclasse == 'A') {
            this.pclcodigoA = classificacao[i].pclcodigo
            this.pclclasseA = classificacao[i].pclclasse
            this.pontoASelectedIni = classificacao[i].pclpontoinicio
            this.pontoASelectedFim = classificacao[i].pclpontofim
          }
          if (classificacao[i].pclclasse == 'B') {
            this.pclcodigoB = classificacao[i].pclcodigo
            this.pclclasseB = classificacao[i].pclclasse
            this.pontoBSelectedIni = classificacao[i].pclpontoinicio
            this.pontoBSelectedFim = classificacao[i].pclpontofim
          }
          if (classificacao[i].pclclasse == 'C') {
            this.pclcodigoC = classificacao[i].pclcodigo
            this.pclclasseC = classificacao[i].pclclasse
            this.pontoCSelectedIni = classificacao[i].pclpontoinicio
            this.pontoCSelectedFim = classificacao[i].pclpontofim
          }
          if (classificacao[i].pclclasse == 'D') {
            this.pclcodigoD = classificacao[i].pclcodigo
            this.pclclasseD = classificacao[i].pclclasse
            this.pontoDSelectedIni = classificacao[i].pclpontoinicio
          }
        }
      }
    },

    modalOpsErro() {
      conectionError()
    }
  },

  async mounted() {
    this.loadingPanel = true
    if (this.$route.params.id) {
      await this.editarPontuacao(this.$route.params.id)
      await this.tabelaCadastro(this.$route.params.id)
    } else {
      await this.tabelaCadastro()
    }
    // this.categoriaAtual = 'Jornada de trabalho'
    this.loadingPanel = false
  }
})
</script>

<style lang="scss" scoped>
.checkbox-opc {
  text-align: left;
}

.cabecalho {
  top: -1px;
  z-index: 3;
  position: sticky;
}

.grupoCategoria:hover {
  color: black;
  cursor: pointer;
  background: #eeeded;
}

.grupoCategoria {
  border: none;
  background: #f6f5f5;
}

.tableCategorias {
  margin-bottom: 80px;
  border: none;
  background: #f6f5f5;
}

.tamanho-corpo-tabela {
  max-height: 340px !important;
  overflow-y: auto !important;

  table {
    background: #f6f5f5;

    thead {
      max-height: 40px !important;

      tr {
        th {
          font-weight: 400;
          font-family: nexabook, sans-serif !important;
          border: 1px solid #e3e3e3 !important;
          width: 20%;
          padding: 2.5px;
          top: -1px;
          z-index: 10;
          background: #eeeded;
        }
      }
    }

    tbody {
      background-color: #f6f5f5;

      tr {
        td {
          border-bottom: 1px solid #e3e3e3;
          background-color: #ffffff;
          padding-left: 10px;
          width: 20%;
          height: 62px;
        }
      }
    }
  }
}

.tamanho-input {
  min-width: 150px !important;
}

.inputSimpleTable {
  padding: 0px;
  vertical-align: top;
}

.card-header {
  padding-top: 3px;
  padding-bottom: 3px;
}

.classificacao {
  margin: 5px;

  td {
    tr {
      height: 50px;
      text-align: center;
      padding-top: 10px;
    }
  }
}

.imputSimple {
  padding: 0px;
  vertical-align: top;

  input {
    min-width: 100px !important;
  }
}

.badge-success {
  margin-right: 15px;
  margin-top: 4px;
  width: 200px;
  height: 35px;
  padding-top: 13px;
  border-radius: 0px;
  background-color: #95b65c;
}

.badge-primary {
  margin-right: 15px;
  margin-top: 4px;
  width: 200px;
  height: 35px;
  padding-top: 13px;
  border-radius: 0px;
  background-color: #428bca;
}

.badge-warning {
  margin-right: 15px;
  margin-top: 4px;
  width: 200px;
  height: 35px;
  padding-top: 13px;
  border-radius: 0px;
  background-color: #f0ad4e;
  color: white;
}

.badge-danger {
  margin-right: 15px;
  margin-top: 4px;
  width: 200px;
  height: 35px;
  padding-top: 13px;
  border-radius: 0px;
  background-color: #e04b4a;
}
</style>
