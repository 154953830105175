<!-- eslint-disable vue/require-v-for-key -->
<template>
	<EaglePanel id="historicoDePosicoes" :loading="gerandoRelatorio">
		<div class="col-sm-12 nopadding">
			<div class="col-sm-12 row">
				<div class="col-sm-6 nopadding">
					<titulo titulo="Histórico de posições" :icon="mdiMapMarkerPath" />
				</div>

				<div class="col-sm-6 nopadding divDontPrint">
					<botoes :loading="loading" :disabled="formInvalido" @gerarRelatorio="gerarRelatorio"
						@exportarRelatorio="exportarRelatorio" :disabledDropdown="relatorioResumido
							? $v.dataRelResumido.$invalid
							: $v.dataRelDetalhado.$invalid
							">
						<simpleButton :type="relatorioResumido ? 'grey' : 'green'" text="Detalhado" event="click"
							@click="relatorioResumido = !relatorioResumido" />
						<simpleButton :type="relatorioResumido ? 'green' : 'grey'" event="click" text="Resumido"
							@click="relatorioResumido = !relatorioResumido" />
					</botoes>
				</div>
			</div>

			<div class="col-sm-12 nopadding row">
				<slideUpDown>
					<div class="col-sm-12 somepadding row">
						<div class="col-sm-12">
							<hr />
						</div>
						<div class="col-sm-3 nopadding">
							<div class="col-sm-12 nopadding" id="div_dayPicker">
								<dayPicker name="periodoRelatorio" :intervalo="7" id="periodoRelatorio" opens="right"
									:isObrigatorio="true" @changeInput="mudaIntervalo" :extraClass="{
										'border border-danger': tamanhoIntervalo > 7
									}" :ComTitulo="false" />
							</div>
							<b-tooltip target="div_dayPicker" triggers="hover" v-if="tamanhoIntervalo > 7">
								período selecionado inválido! (Máximo 7 dias)
							</b-tooltip>
							<div class="col-sm-12 nopadding row">
								<div class="col-sm-6 linha2">
									<inputSimple label="Hora Inicial" type="time" value="00:00"
										@changeInput="v => (relatorio.horaInicial = v)" />
								</div>
								<div class="col-sm-6 linha2">
									<inputSimple label="Hora Final" type="time" value="23:59"
										@changeInput="v => (relatorio.horaFinal = v)" />
								</div>
							</div>
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-bind="{
								nameForRadio: 'seletorEmpresaPosicoes',
								labels: labelSeletorEmpresas,
								isMultiple: true,
								optionsArray: optSelectEmpresas,
								hasSelectAll: true,
								selected: selectedEmpresa
							}" v-on="{ changeSelect: mudaSeletorEmpresa }" />

							<div class="col-sm-12 mt-4 nopadding row" v-show="relatorioResumido" style="margin-left: 35px !important">
								<b-check class="nopadding" v-model="check.prefixo" @change="() => limpaInfoRelatorios()">
									Ordenar por prefixo
								</b-check>
							</div>
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-bind="{
								loading: loadingBtn,
								nameForRadio: 'seletorVeiculo',
								labels: labelSeletorVeiculo,
								isMultiple: true,
								optionsArray: optSelectVeiculos,
								hasSelectAll: true,
								firstSelected: 'V',
								disabled: !optSelectVeiculos.length,
								ref: 'seletorVeiculo'
							}" v-on="{
								changeSelect: mudaSeletorVeiculo,
								changeCheck: mudaCheckVeiculo
							}" />

							<div class="col-sm-12 mt-4 nopadding row" v-show="relatorioResumido" style="margin-left: 35px !important">
								<b-check class="nopadding" v-model="check.apenasJornada" @change="() => limpaInfoRelatorios()">
									Visualizar somente informações de jornada
								</b-check>
							</div>
						</div>
						<div class="col-sm-3 nopadding row" v-show="relatorioResumido">
							<filters label="Tempo parado acima de" :arrayButtons="btsFiltroTempo" zeroValue="0"
								@buttonsFiltersChange="mudaFiltroTempo" />
						</div>
						<div class="col-sm-3 nopadding">
							<selectAll v-show="!relatorioResumido" v-bind="{
								loading: loadingBtn,
								nameForRadio: 'seletorTrabalhador',
								labels: labelSeletorTrabalhador,
								optionsArray: optSelectTrabalhador,
								isMultiple: true,
								hasSelectAll: true,
								firstSelected: 'C',
								disabled: !optSelectTrabalhador.length,
								ref: 'seletorTrabalhador'
							}" v-on="{
								changeSelect: mudaSeletorTrabalhador,
								changeCheck: mudaCheckTrabalhador
							}" />
						</div>
					</div>
				</slideUpDown>
			</div>

			<div class="col-sm-12">
				<hr />
			</div>
		</div>

		<!-- Tabela Resumido -->
		<div class="col-sm-12" v-if="relatorioResumido">
			<tableSimples>
				<thead>
					<tr>
						<th class="fonte-tr-resumido">Data</th>
						<th class="fonte-tr-resumido">Ocorrência</th>
						<th class="fonte-tr-resumido">Inicio</th>
						<th class="fonte-tr-resumido">Fim</th>
						<th class="fonte-tr-resumido">Tempo</th>
						<th class="fonte-tr-resumido">Ponto</th>
						<th class="fonte-tr-resumido">Localização</th>
						<th class="fonte-tr-resumido">
							{{ colunaMedicaoHodometro }}<br />{{ colunaMedicaoHorimetro }}
						</th>
						<th class="fonte-tr-resumido">Motorista</th>
						<th class="fonte-tr-resumido">Ajudante</th>
					</tr>
				</thead>

				<tbody v-if="dataRelResumido.length">
					<tr class="sticky-header">
						<td colspan="2" class="nopadding">
							<div class="d-flex justify-content-center align-items-center nopadding"
								style="color: #4ead7f; cursor: pointer" @click="adicionarEventos">
								<div>
									<baseIcon size="20" :icon="mdiTableLargePlus" />
								</div>
								<div>
									<p style="
                        color: #000;
                        margin-left: 5px;
                        margin-top: 5px;
                        margin-bottom: 0px;
                      ">
										Adicionar eventos
									</p>
								</div>
							</div>
						</td>

						<td colspan="2" class="nopadding">
							<div class="d-flex justify-content-center align-items-center nopadding"
								style="color: #cc635f; cursor: pointer" @click="removerEventos">
								<div>
									<baseIcon size="20" :icon="mdiTableLargeRemove" />
								</div>
								<div>
									<p style="
                        color: #000;
                        margin-left: 5px;
                        margin-top: 5px;
                        margin-bottom: 0px;
                      ">
										Remover eventos
									</p>
								</div>
							</div>
						</td>

						<td colspan="9" class="cedulaProcurar">
							<input placeholder="Buscar" v-model="searchQ" />
						</td>
					</tr>

					<template v-for="(data, index) in dataRelResumido">
						<tr :key="index + '__placa'">
							<td colspan="3" v-text="data.placa" class="badgePlaca"></td>
						</tr>

						<template v-for="item in data">
							<template v-for="(oc, j) in item.dados">
								<tr v-show="oc.localizacao.match(RegExp(searchQ, 'i'))" :key="j + '_dados' + oc.unique">
									<td class="fonte-td-resumido d-flex justify-content-center align-items-center nopadding">

										<div style="margin-right: 10px">
											<div v-if="oc.primeiroRegistro" v-show="ativarAdicionarEventos" @click="adicionarEvento(oc, data)"
												class="nopadding">
												<baseIcon style="
                              color: #4ead7f;
                              cursor: pointer;
                            " size="25" :icon="mdiPlus" />
											</div>

											<div v-show="ativarAdicionarEventos" @click="adicionarEvento(oc, data)" class="nopadding">
												<baseIcon style="color: #4ead7f; cursor: pointer; margin-top: 15px" size="25" :icon="mdiPlus" />
											</div>
										</div>

										<div v-if="
											oc.ocorrencia == 'Abertura de jornada' ||
											oc.ocorrencia == 'Fechamento de jornada' ||
											oc.ocorrencia == 'Início do intervalo' ||
											oc.ocorrencia == 'Fechamento do intervalo'
										">
											<div v-show="ativarRemoverEventos" @click="removerEvento(oc)" class="nopadding"
												style="margin-top: -15px">
												<baseIcon style="color: #cc635f; cursor: pointer" size="25" :icon="mdiClose" />
											</div>
										</div>

										<p class="mr-2">{{ oc.data }}</p>
									</td>

									<td class="fonte-td-resumido" :style="{
										cursor:
											oc.ocorrencia == 'Abertura de jornada' ||
												oc.ocorrencia == 'Fechamento de jornada' ||
												oc.ocorrencia == 'Início do intervalo' ||
												oc.ocorrencia == 'Fechamento do intervalo'
												? 'pointer'
												: 'default'
									}" v-text="oc.ocorrencia" @dblclick="abrirCard(oc)"></td>

									<td class="fonte-td-resumido" v-text="oc.inicio"></td>

									<td class="fonte-td-resumido" v-text="oc.fim"></td>

									<td class="fonte-td-resumido" v-text="oc.tempo"></td>

									<td class="fonte-td-resumido" v-text="oc.ponto"></td>

									<td class="fonte-td-resumido" v-text="oc.localizacao"></td>

									<td class="fonte-td-resumido" v-text="oc.distancia"></td>

									<td class="fonte-td-resumido" v-text="oc.motorista"></td>

									<td class="fonte-td-resumido" v-text="oc.ajudante"></td>
								</tr>

								<template>
									<tr>
										<td colspan="13">
											<b-collapse class="w100 p-2" v-model="oc.clicado">
												<div v-if="deletarJornadaCollapse" class="d-flex justify-content-start align-items-center">
													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Justificativa *</p>

														<select v-model="justificativaSelecionada"
															@change="(justificativa) => changeJustificativa(justificativa)">
															<option v-for='(justificativa, index) in justificativas' :key='index'
																:value="justificativa.value" v-text="justificativa.description" />
														</select>
													</div>

													<div class="d-flex">
														<div @click="cancelarEventoJornadaConfirmar()"
															style="padding: 3; background-color: #3265cb; color: #fff; margin: 3px; cursor: pointer; margin-left: 25px">
															<baseIcon size="18" :icon="mdiCheckBold" />
														</div>

														<div @click="cancelarEvento(oc)"
															style="padding: 3; background-color: #c8352c; color: #fff; margin: 3px; cursor: pointer">
															<baseIcon size="18" :icon="mdiClose" />
														</div>
													</div>
												</div>

												<div v-else class="d-flex justify-content-start align-items-center">
													<div class="d-flex justify-content-center align-items-center nopadding">
														<p class="mr-1 mt-2 text-nowrap" style="font-weight: bold">Evento *</p>
														<select v-model="eventoSelecionado" @change="changeEvento">
															<option v-for='(evento, index) in eventos' :key='index' :value="evento.value"
																v-text="evento.description" />
														</select>
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Tipo *</p>

														<select v-model="tipoSelecionado" @change="(tipo) => changeTipo(tipo)">
															<option v-for='(tipo, index) in tipos' :key='index' :value="tipo.value"
																v-text="tipo.description" />
														</select>
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Veículo *</p>

														<select v-model="veiculoSelecionado" @change="changeVeiculo">
															<option v-for='(veiculo, index) in optSelectVeiculos' :key='index' :disabled="true"
																:value="veiculo.value" v-text="veiculo.description" />
														</select>
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Data *</p>

														<input type="date" :value="dataSelecionada" @change="changeData" />
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Horário *</p>
														<input type="time" step="1" :value="horarioSelecionado"
															@change="(event) => changeHorario(event.target.value)" />
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-1 ml-3 mt-2 text-nowrap" style="font-weight: bold;">Justificativa *</p>

														<select v-model="justificativaSelecionada"
															@change="(justificativa) => changeJustificativa(justificativa)">
															<option v-for='(justificativa, index) in justificativas' :key='index'
																:value="justificativa.value" v-text="justificativa.description" />
														</select>
													</div>

													<div class="d-flex justify-content-start align-items-center nopadding">
														<p class="mr-2 ml-5 mt-2 text-nowrap" style="font-weight: bold;">Motorista *</p>

														<select v-model="motoristaSelecionado" @change="changeMotorista">
															<option v-for='(motorista, index) in motoristas' :key='index' :value="motorista.value"
																v-text="motorista.description" />
														</select>
													</div>

													<div class="d-flex">
														<div v-show="registrandoNovaJornada" @click="registrarNovaJornada(oc)"
															style="padding: 3; background-color: #3265cb; color: #fff; margin: 3px; cursor: pointer; margin-left: 25px">
															<baseIcon size="18" :icon="mdiCheckBold" />
														</div>

														<div v-show="!registrandoNovaJornada" @click="salvarEvento(oc)"
															style="padding: 3; background-color: #3265cb; color: #fff; margin: 3px; cursor: pointer; margin-left: 25px">
															<baseIcon size="18" :icon="mdiCheckBold" />
														</div>

														<div @click="cancelarEvento(oc)"
															style="padding: 3; background-color: #c8352c; color: #fff; margin: 3px; cursor: pointer">
															<baseIcon size="18" :icon="mdiClose" />
														</div>
													</div>
												</div>
											</b-collapse>
										</td>
									</tr>
								</template>
							</template>

							<tr :key="item.totais.unique" v-if="item.totais != undefined">
								<td class="fonte-td-resumido negrito totais" colspan="2">
									Total em jornada: {{ item.totais.jornada }}
								</td>

								<td class="fonte-td-resumido negrito totais" colspan="3">
									Total em movimento: {{ item.totais.movimento }}
								</td>

								<td class="fonte-td-resumido negrito totais" colspan="1">
									Total parado: {{ item.totais.parado }}
								</td>

								<td class="fonte-td-resumido negrito totais" colspan="2">
									Total em intervalo: {{ item.totais.intervalo }}
								</td>

								<td class="fonte-td-resumido negrito totais" colspan="2">
									Total em descanso: {{ item.totais.descanso }}
								</td>
							</tr>
						</template>
					</template>
				</tbody>

				<tbody v-else>
					<status :statusBar="infoResumido" />
				</tbody>
			</tableSimples>
		</div>

		<!-- Tabela Detalhado -->
		<div class="col-sm-12" v-else>
			<tableSimples>
				<thead>
					<tr>
						<th>Data</th>
						<th>Horário</th>
						<th>Tempo Parado</th>
						<th>Evento</th>
						<th>Local</th>
						<th>Colaborador</th>
						<th>Percorrido</th>
						<th>Hodômetro</th>
						<th>Velocidade</th>
						<th>Ponto</th>
						<th>Região</th>
						<th>Temp(°C)</th>
						<th>Localizar</th>
					</tr>
				</thead>
				<tbody v-if="dataRelDetalhado.length">
					<tr>
						<td colspan="13" class="cedulaProcurar">
							<input placeholder="Buscar" v-model="searchQ" class="inputBusca" />
						</td>
					</tr>
					<template v-for="(rec, index) in dataRelDetalhado">
						<tr :key="index + '_placa_' + rec.placa">
							<td class="badgePlaca mt-2" v-text="rec.placa" colspan="4" />
						</tr>
						<template v-for="(info, i) in rec.dados">
							<tr v-show="info.endereco
								.toLowerCase()
								.includes(searchQ.trim().toLowerCase())
								" :key="rec.placa + '__dados' + i" :class="defineClasse(info.evento) + ' dadosTabela'">
								<td v-text="info.data" />
								<td v-text="info.hora" />
								<td v-text="info.tempo" />
								<td class="celIconeTexto">
									<baseIcon :class="info.evento" size="18" :icon="defineIcone(info.evento)" />
									<span v-text="'\n' + defineTexto(info.evento)" />
								</td>
								<td class="enderecoDetalhado" v-text="info.endereco + ' / ' + info.cidade" />
								<td class="enderecoDetalhado" v-text="info.motorista" />
								<td v-text="info.kms + 'kms'" />
								<td v-text="info.hodometro + 'km'" />
								<td v-text="info.velocidade" />
								<td v-text="info.ponto" />
								<td v-text="decideRegiao(info.regiao)" />
								<td v-text="info.temperatura" />
								<td event="click" @click="localizar(info.latlon)">
									<baseIcon size="18" class="cursor-pointer colorIconLocalizar" :icon="mdiTarget" />
								</td>
							</tr>
						</template>
					</template>
				</tbody>
				<tbody v-else>
					<status :statusBar="infoDetalhado" :colspanForTd="13" />
				</tbody>
			</tableSimples>
		</div>

		<!-- modal certeza remover um evento -->
		<modalEagle @confirmButton="confirmarRemocaoEvento()" id="modalConfirmacaoRemoverEvento"
			title="Você tem certeza que deseja realizar essa operação?">
			<template #modalBody>
				<b-overlay :opacity="0.65" class="bemAlto">
					<div class="col-sm-12 nopadding">
						Ao confirmar essa operação não será mais possível revertê-la!
					</div>
					<div class="col-sm-12 nopadding">
						Tem certeza que deseja fazer isso?
					</div>
				</b-overlay>
			</template>
		</modalEagle>
	</EaglePanel>
</template>
<script>
import moment from 'moment'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import simpleButton from '@/components/Atom/Buttons/SimpleButton'
import botoes from '@/components/Atom/Buttons/BasicButtonsRelatoriosEIG'
import dayPicker from '@/components/Atom/Datas/InputRangeWithLimit'
import status from '@/components/Atom/StatusInformation/StatusInformation'
import slideUpDown from '@/components/Atom/SlideUpAndDown/SlideUpAndDown'
import {
	mdiMapMarkerPath,
	mdiRoadVariant,
	mdiCarKey,
	mdiSquare,
	mdiCarTractionControl,
	mdiBattery,
	mdiTarget,
	mdiCarConnected,
	mdiTableLargePlus,
	mdiTableLargeRemove,
	mdiPlus,
	mdiClose,
	mdiCheckBold
} from '@mdi/js'
import { conectionError } from '@/Services/Helpers/swellHeper'

export default {
	name: 'HistoricoDePosicoes',

	components: {
		baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
		EaglePanel: require('@/components/Atom/Panel/PanelEagle').default,
		titulo: require('@/components/Atom/Header/Titulo').default,
		simpleButton,
		botoes,
		dayPicker,
		inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		filters: require('@/components/Atom/Buttons/ButtonsFilters').default,
		tableSimples: require('@/components/Atom/Table/TableSimples').default,
		status,
		slideUpDown,
		modalEagle: require('@/components/Atom/Modal/ModalEagle').default
	},

	validations: {
		dataRelDetalhado: { required },
		dataRelResumido: { required }
	},

	data() {
		return {
			loadingBtn: false,
			mdiTarget: mdiTarget,
			mdiCarKey: mdiCarKey,
			mdiSquare: mdiSquare,
			mdiBattery: mdiBattery,
			mdiRoadVariant: mdiRoadVariant,
			mdiMapMarkerPath: mdiMapMarkerPath,
			mdiCarTractionControl: mdiCarTractionControl,
			mdiCarConnected: mdiCarConnected,
			mdiTableLargePlus: mdiTableLargePlus,
			mdiTableLargeRemove: mdiTableLargeRemove,
			mdiPlus: mdiPlus,
			mdiClose: mdiClose,
			mdiCheckBold: mdiCheckBold,
			optSelectEmpresas: new EmpresasService().Get(),
			baseUri: '/telemetria/relatorios/historico/posicoes/',
			optSelectVeiculos: [],
			optSelectTrabalhador: [],
			gerandoRelatorio: false,
			relatorioResumido: false,
			allOpt: [],
			colunaMedicaoHodometro: 'Hodômetro',
			colunaMedicaoHorimetro: '',
			labelSeletorEmpresas: [{ description: 'Empresas*' }],
			labelSeletorVeiculo: [
				{ indexDFH: 'V', description: 'Veiculos*' },
				{ indexDFH: 'GV', description: 'Grupos*' }
			],
			labelSeletorTrabalhador: [
				{ indexDFH: 'C', description: 'Colaborador' },
				{ indexDFH: 'GC', description: 'Grupos' }
			],
			loading: [false, false, false],
			relatorio: {
				cliente: [],
				veiculo: [],
				trabalhador: [],
				intervalo: '',
				horaInicial: '',
				horaFinal: ''
			},
			check: {
				veiculo: '',
				trabalhador: '',
				prefixo: false,
				apenasJornada: false
			},
			dataRelResumido: [],
			dataRelDetalhado: [],
			searchQ: '',
			infoDetalhado: 'info',
			infoResumido: 'info',
			selectedEmpresa: [],
			tempoParado: '',
			btsFiltroTempo: [
				{ value: '1', isSelected: false, width: '30%', text: '1 min' },
				{ value: '5', isSelected: false, width: '30%', text: '5 min' },
				{ value: '10', isSelected: false, width: '30%', text: '10 min' },
				{ value: '15', isSelected: false, width: '30%', text: '15 min' },
				{ value: '30', isSelected: false, width: '30%', text: '30 min' },
				{ value: '60', isSelected: false, width: '30%', text: '60 min' }
			],
			ativarAdicionarEventos: false,
			ativarRemoverEventos: false,
			dataAtual: null,
			rotasPendentesCollapseVisible: true,
			eventoSelecionado: '',
			eventos: [
				{ value: 'Abertura de jornada', description: 'Abertura' },
				{ value: 'Fechamento de jornada', description: 'Fechamento' },
			],
			tipos: [
				{ value: 'Jornada', description: 'Jornada' },
				{ value: 'Intervalo', description: 'Intervalo' },
				{ value: 'Espera', description: 'Espera' },
			],

			justificativas: [],
			motoristas: [],
			tipoSelecionado: '',
			veiculoSelecionado: '',
			dataSelecionada: '',
			justificativaSelecionada: '',
			motoristaSelecionado: '',
			horarioSelecionado: '',
			placaSelecionada: '',
			prefixoSelecionado: '',
			novaData: false,
			jornadaSelecionadaDeletar: {},
			trocaPlaca: false,
			registrandoNovaJornada: false,
			jornadaJustificada: false,
			origemJornada: null,
			deletarJornadaCollapse: false,
			jornadaDeletadaFechar: [],
		}
	},

	methods: {
		...mapGetters(['getMaster']),

		/**
		 * @param {string|null} regiao
		 * @return {string} nome da região OU Sem região
		 */
		decideRegiao(regiao) {
			if (regiao) return regiao
			return 'Sem região'
		},

		/**
		 * Função que faz o request para gerar o relatório
		 */
		gerarRelatorio(atualizar = true) {
			if (atualizar) {
				this.dataRelResumido = [];
				this.dataRelDetalhado = [];
				this.gerandoRelatorio = true;
				this.jornadaJustificada = false;
				this.justificativaSelecionada = null;
			}

			this.deletarJornadaCollapse = false;

			let uri = this.baseUri + (this.relatorioResumido ? 'resumido' : 'completo')

			if (this.relatorioResumido) {
				this.consultarJustificativas();
			}

			new HttpRequest()
				.Post(uri, this.objRequest, 100000)
				.then(data => {
					this.gerandoRelatorio = false
					let dados
					if (data.data.rel.dadosresumido != undefined) {
						const dadosNaoOrdenados = data.data.rel.dadosresumido;

						const chavesOrdenadas = Object.keys(dadosNaoOrdenados).sort((a, b) => {
							const numA = parseInt(a.split("|")[1].trim());
							const numB = parseInt(b.split("|")[1].trim());

							return numA - numB;
						});

						const veiculosOrdenados = {};
						chavesOrdenadas.forEach(placa => {
							veiculosOrdenados[placa] = dadosNaoOrdenados[placa];
						});

						dados = veiculosOrdenados
						this.motoristas = data.data.rel.motoristas;

						const chavesDados = Object.keys(dados);
						let dataAtual = null;

						chavesDados.map((chave) => {
							const chavesDatas = Object.keys(dados[chave]);

							chavesDatas.map((data) => {
								const dadosItem = dados[chave][data].dados;


								dadosItem.map((dado, indexDados) => {
									if (dado.origem != undefined && this.origemJornada == null) {
										this.origemJornada = dado.origem;
									}

									if (dataAtual == null || dataAtual != dado.data) {
										dados[chave][data].dados[indexDados].primeiroRegistro = true;

										dataAtual = dado.data;
									}
								})
							})
						})
					} else {
						dados = data.data.rel
					}
					this.processaRelatorio(dados)
					if (data.data.rel.horimetrohodometro == 'hodometro/horimetro') {
						this.colunaMedicaoHodometro = 'Hodômetro'
						this.colunaMedicaoHorimetro = 'Horímetro'
					} else if (data.data.rel.horimetrohodometro == 'hodometro') {
						this.colunaMedicaoHodometro = 'Hodômetro'
						this.colunaMedicaoHorimetro = ''
					} else if (data.data.rel.horimetrohodometro == 'horimetro') {
						this.colunaMedicaoHodometro = ''
						this.colunaMedicaoHorimetro = 'Horímetro'
					} else {
						this.colunaMedicaoHodometro = 'Hodômetro'
					}
				})
				.catch(() => {
					conectionError()
				})
		},

		/**
		 * Consulta as justificativas disponíveis para os clientes no servidor.
		 * 
		 * @author Yuri 🇧🇷
		 */
		consultarJustificativas() {
			const url = this.baseUri + 'consultar/justificativas';

			new HttpRequest()
				.Post(url, this.objRequest)
				.then(data => {
					this.justificativas = data.data;
				})
				.catch(() => {
					conectionError()
				})
		},

		/**
		 * Quando a empresa principal é altera, já faz a request para
		 * carregar as opções do seletor de Veiculos e trabalhadores
		 */
		mudaSeletorEmpresa(arr) {
			this.limpaRelacaocliente()
			this.relatorio.cliente = arr
			if (this.relatorio.cliente.length) {
				this.loadingBtn = true
				new FiltrosService()
					.dados_filtros(this.relatorio.cliente, ['V', 'GV', 'C', 'GC'])
					.then(data => {
						this.loadingBtn = false
						this.allOpt = data
						this.optSelectVeiculos = this.allOpt[this.check.veiculo]
						this.optSelectTrabalhador = this.allOpt[this.check.trabalhador]
					})
			}
		},

		localizar(latLong) {
			window.open(`https://www.google.com/maps/place/${latLong}`)
		},

		/**
		 * Recebe o valor do filtro de tempo que o usuário seleciona
		 * @param value
		 * @author Otávio 🦆
		 */
		mudaFiltroTempo(value) {
			this.tempoParado = value
			this.limpaInfoRelatorios()
		},

		exportarRelatorio(tipo) {
			var root = process.env.VUE_APP_ROOT
			this.loadingExportacao(tipo)
			let uri = this.baseUri + 'exportar'
			let obj = {
				tipo: tipo,
				relResumido: this.relatorioResumido,
				intervalo: this.relatorio.intervalo,
				hodometro: this.colunaMedicaoHodometro,
				horimetro: this.colunaMedicaoHorimetro,
				dados: this.relatorioResumido
					? this.dataRelResumido
					: this.dataRelDetalhado
			}
			new HttpRequest().Post(uri, obj).then(data => {
				this.loadingExportacao()
				if (data.status) window.open(root + '/' + data.data.local)
			})
		},

		loadingExportacao(tipo = '') {
			if (tipo === 'pdf') this.loading = [true, false, false]
			if (tipo === 'xls') this.loading = [false, true, false]
			if (tipo === 'csv') this.loading = [false, false, true]
			// eslint-disable-next-line no-unused-vars
			if (tipo === '') this.loading = this.loading.map(a => (a = false))
		},

		limpaInfoRelatorios() {
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			this.colunaMedicaoHodometro = 'Hodômetro'
			this.colunaMedicaoHorimetro = ''
		},

		/**
		 * Função que existe só para centralizar as coisas que devem
		 * ser "limpas" quando a empresa selecionada muda
		 */
		limpaRelacaocliente() {
			this.optSelectVeiculos = []
			this.optSelectTrabalhador = []
			this.dataRelResumido = []
			this.dataRelDetalhado = []
			this.$refs.seletorTrabalhador.clearAll()
			this.$refs.seletorVeiculo.clearAll()
		},

		mudaSeletorVeiculo(arr) {
			this.relatorio.veiculo = arr
			this.limpaInfoRelatorios()
		},

		mudaSeletorTrabalhador(arr) {
			this.relatorio.trabalhador = arr
			this.limpaInfoRelatorios()
		},

		mudaCheckVeiculo(check) {
			this.check.veiculo = check
			this.limpaInfoRelatorios()
			if (this.allOpt[this.check.veiculo])
				this.optSelectVeiculos = this.allOpt[this.check.veiculo]
		},

		mudaCheckTrabalhador(check) {
			this.check.trabalhador = check
			this.limpaInfoRelatorios()
			if (this.allOpt[this.check.trabalhador])
				this.optSelectTrabalhador = this.allOpt[this.check.trabalhador]
		},

		mudaIntervalo(data) {
			this.relatorio.intervalo = data
			this.limpaInfoRelatorios()
		},

		/**
		 * Processa dados do relatório para
		 * facilitar a exibição
		 */
		processaRelatorio(data) {
			var mapa = []
			var obj = {}
			this.infoDetalhado = 'loading'
			this.infoResumido = 'loading'
			if (this.relatorioResumido) {
				this.infoDetalhado = 'info'
				this.infoResumido = 'info'
				Object.keys(data).forEach(key => {
					obj = {
						placa: key,
						...data[key]
					}
					mapa.push(obj)
				})
				if (!mapa.length) this.infoResumido = 'error'
				this.dataRelResumido = mapa
			} else {
				this.infoDetalhado = 'info'
				this.infoResumido = 'info'
				Object.keys(data).forEach(key => {
					obj = {
						placa: key,
						dados: data[key]
					}
					mapa.push(obj)
				})
				if (!mapa.length) this.infoDetalhado = 'error'
				this.dataRelDetalhado = mapa
			}
		},

		/**
		 * Define a classe que a linha da tabela vai ter de acordo
		 * com o evento em questão.
		 */
		defineClasse(evento) {
			switch (evento.toLowerCase()) {
				case 'm':
					return 'fundoVerde'
				default:
					return 'fundoVermelho'
			}
		},

		/**
		 * Define o texto que vai aparecer na cedula
		 * do evento
		 */
		defineTexto(evento) {
			switch (evento.toLowerCase()) {
				case 'm':
					return 'Movimento'
				case 'il':
					return 'Ignição Ligada'
				case 'fp':
					return 'Fim Parada'
				case 'ip':
					return 'Inicio Parada'
				case 'id':
					return 'Ignição Desligada'
				case 'mi':
					return 'Movimento Indevido'
				case 'ec':
					return 'Energia Cortada'
				case 'er':
					return 'Energia Restaurada'
				case 'p1':
					return 'Porta 1 Acionada'
				case 'p2':
					return 'Porta 2 Acionada'
				case 'p3':
					return 'Porta 3 Acionada'
				case 'p4':
					return 'Porta 4 Acionada'
			}
		},

		/**
		 * Define qual dos ícones vai aparecer ao lado
		 * do texto da celula do evento
		 */
		defineIcone(evento) {
			switch (evento.toLowerCase()) {
				case 'm':
					// mdiRoadVariant
					return this.mdiRoadVariant
				case 'il':
					// mdiCarKey
					return this.mdiCarKey
				case 'fp':
					// mdiSquare
					return this.mdiSquare
				case 'ip':
					// mdiSquare
					return this.mdiSquare
				case 'id':
					// mdiCarKey
					return this.mdiCarKey
				case 'mi':
					// mdiCarTractionControl
					return this.mdiCarTractionControl
				case 'ec':
					// mdiBattery
					return this.mdiBattery
				case 'er':
					// mdiBattery
					return this.mdiBattery
				default:
					return this.mdiCarConnected
			}
		},

		/**
		 * Formata uma data no formato 'DD/MM/YYYY' para 'YYYY-MM-DD'.
		 *
		 * @param {string} data Data no formato 'DD/MM/YYYY'.
		 * @return {string} Data formatada no formato 'YYYY-MM-DD'.
		 * @author Yuri 🇧🇷
		 */
		formatarData(data) {
			const [dia, mes, ano] = data.split("/");
			return `${ano}-${mes}-${dia}`;
		},

		/**
		 * Formata uma data no formato 'YYYY-MM-DD' para 'DD/MM/YYYY'.
		 *
		 * @param {string} data Data no formato 'YYYY-MM-DD'.
		 * @return {string} Data formatada no formato 'DD/MM/YYYY'.
		 * @author Yuri 🇧🇷
		 */
		formatarDataUTM(data) {
			const [ano, mes, dia] = data.split("-");
			return `${dia}/${mes}/${ano}`;
		},

		/**
		 * Abre um card de evento e atualiza os detalhes da jornada com base no evento selecionado.
		 * Atualiza as variáveis relacionadas ao evento, como tipo, veículo, placa, data, horário e motorista.
		 *
		 * @param {Object} oc Objeto contendo informações do evento a ser exibido no card.
		 * @return {void}
		 * @author Yuri 🇧🇷
		 */
		abrirCard(oc) {
			if (
				oc.ocorrencia == 'Abertura de jornada' ||
				oc.ocorrencia == 'Fechamento de jornada' ||
				oc.ocorrencia == 'Início do intervalo' ||
				oc.ocorrencia == 'Fechamento do intervalo'
			) {
				this.registrandoNovaJornada = false;
				this.limparDadosNovaJornada();

				this.dataRelResumido.map((data, index) => {
					data[oc.data].dados.map((registro, indexItem) => {
						if (registro.unique == oc.unique) {
							const registroEditando = this.dataRelResumido[index][oc.data].dados[indexItem]
							const valorAnterior = registroEditando.clicado;
							registroEditando.clicado = !valorAnterior;

							const tipoSelecionadoParaMarcar = registroEditando.tipoOcorrencia == 'Abertura de jornada' || registroEditando.tipoOcorrencia == 'Fechamento de jornada' ? ('Jornada') : ('Intervalo');

							const placaPrefixoSelecionada = registroEditando.placa.trimEnd() + ' | ' + registroEditando.prefixo;
							let veiculoSelecionadoMarcar = '';
							let placaSelecionadaMarcar = '';

							this.optSelectVeiculos.map((veiculo) => {
								if (veiculo.description == placaPrefixoSelecionada) {
									veiculoSelecionadoMarcar = veiculo.value;
									placaSelecionadaMarcar = veiculo.description;
								}
							});
							this.eventoSelecionado = registroEditando.tipoOcorrencia;
							this.tipoSelecionado = tipoSelecionadoParaMarcar;
							this.veiculoSelecionado = veiculoSelecionadoMarcar;
							this.placaSelecionada = placaSelecionadaMarcar;
							this.dataSelecionada = this.formatarData(registroEditando.data);
							if (registroEditando.fim == null) {
								this.horarioSelecionado = registroEditando.inicio;
							} else {
								this.horarioSelecionado = registroEditando.fim;
							}
							this.motoristaSelecionado = registroEditando.motorista;
						}
					})
				})
			}
		},

		/**
		 * Habilita a interface para adicionar novos eventos e desativa a remoção de eventos.
		 *
		 * @author Yuri 🇧🇷
		 */
		adicionarEventos() {
			this.registrandoNovaJornada = true;
			this.ativarRemoverEventos = false;
			this.ativarAdicionarEventos = !this.ativarAdicionarEventos;

			this.resetClicadoRegistros();
			this.limpaValoresInputsJornada();
		},

		/**
	 * Reseta o estado "clicado" de todos os registros dentro de `dataRelResumido`, exceto os que pertencem à chave 'placa'.
	 * A função percorre os dados e define `clicado` como `false` para todos os registros.
	 *
	 * @return {void}
	 * @throws {Exception} Caso algum erro ocorra ao processar os dados.
	 * @author Yuri 🇧🇷
	 */
		resetClicadoRegistros() {
			const chaves = Object.keys(this.dataRelResumido[0])

			chaves.map((chave) => {
				if (chave != 'placa' && chave != 0) {
					this.dataRelResumido[0][chave].dados.map((dado, index) => {
						this.dataRelResumido[0][chave].dados[index].clicado = false;
					})
				}
			})
		},

		/**
	 * Limpa os valores dos campos selecionados para registrar uma nova jornada, reinicializando as variáveis relacionadas aos inputs.
	 *
	 * @return {void}
	 * @author Yuri 🇧🇷
	 */
		limpaValoresInputsJornada() {
			this.eventoSelecionado = null;
			this.tipoSelecionado = null;
			this.veiculoSelecionado = null;
			this.placaSelecionada = null;
			this.dataSelecionada = null;
			this.horarioSelecionado = null;
			this.motoristaSelecionado = null;
		},

		/**
		 * Habilita a interface para remover eventos e desativa a adição de eventos.
		 *
		 * @author Yuri 🇧🇷
		 */
		removerEventos() {
			this.registrandoNovaJornada = false;
			this.ativarAdicionarEventos = false;
			this.ativarRemoverEventos = !this.ativarRemoverEventos;
			this.resetClicadoRegistros();
			this.limpaValoresInputsJornada();
		},

		/**
		 * Adiciona um evento à lista de eventos, alternando o estado 'clicado' do registro correspondente.
		 *
		 * @param {Object} oc Objeto contendo informações do evento a ser adicionado.
		 * @author Yuri 🇧🇷
		 */
		adicionarEvento(oc, item) {
			this.registrandoNovaJornada = true;
			this.deletarJornadaCollapse = false;

			this.limparDadosNovaJornada();

			this.dataRelResumido.map((data, index) => {
				data[oc.data].dados.map((registro, indexItem) => {
					if (registro.unique == oc.unique) {
						const valorAnterior = this.dataRelResumido[index][oc.data].dados[indexItem].clicado;
						this.dataRelResumido[index][oc.data].dados[indexItem].clicado = !valorAnterior;
						this.selecionarDadosAdicionarJornada(item, registro);
					}
				})
			})
		},

		/**
		 * Seleciona os dados de um veículo, motorista, data e horário para adicionar em uma nova jornada.
		 *
		 * @param {Object} item Objeto contendo os dados do veículo, incluindo a placa.
		 * @param {Object} registro Objeto contendo os dados da jornada, incluindo a data, motorista e horário de início.
		 * @author Yuri 🇧🇷
		 */
		selecionarDadosAdicionarJornada(item, registro) {
			let veiculoSelecionadoMarcar = '';

			this.optSelectVeiculos.map((veiculo) => {
				if (veiculo.description == item.placa) {
					veiculoSelecionadoMarcar = veiculo.value;

					const dadosVeiculoSelecionado = veiculo.description.split(" ");

					this.placaSelecionada = dadosVeiculoSelecionado[0];
					this.prefixoSelecionado = dadosVeiculoSelecionado[2];
				}
			});

			this.veiculoSelecionado = veiculoSelecionadoMarcar;
			this.dataSelecionada = this.formatarData(registro.data);
			this.motoristaSelecionado = registro.motorista;
			this.horarioSelecionado = registro.fim;
			if (registro.fim == null) {
				this.horarioSelecionado = registro.inicio;
			} else {
				this.horarioSelecionado = registro.fim;
			}
		},

		/**
		 * Exibe um modal de confirmação para remover um evento e define a jornada selecionada para exclusão.
		 *
		 * @param {Object} jornada Objeto contendo os dados da jornada a ser removida.
		 * @author Yuri 🇧🇷
		 */
		removerEvento(oc) {
			this.registrandoNovaJornada = false;
			this.deletarJornadaCollapse = true;
			this.jornadaDeletadaFechar = oc;

			this.dataRelResumido.map((data, index) => {
				data[oc.data].dados.map((registro, indexItem) => {
					if (registro.unique == oc.unique) {
						const valorAnterior = this.dataRelResumido[index][oc.data].dados[indexItem].clicado;
						this.dataRelResumido[index][oc.data].dados[indexItem].clicado = !valorAnterior;
					}
				})
			})

			this.jornadaSelecionadaDeletar = oc
		},

		cancelarEventoJornadaConfirmar() {
			this.$bvModal.show('modalConfirmacaoRemoverEvento')
		},

		/**
		 * Confirma a remoção de um evento, enviando uma solicitação para deletar a jornada selecionada.
		 * Após a exclusão, organiza os dados das jornadas e oculta o modal de confirmação.
		 *
		 * @throws Exception
		 * @author Yuri 🇧🇷
		 */
		confirmarRemocaoEvento() {
			const url = this.baseUri + 'deletar/jornada';
			const data = {
				jornada: this.jornadaSelecionadaDeletar,
				justificativa: this.justificativaSelecionada
			};

			new HttpRequest()
				.Post(url, data)
				.then((retorno) => {

					if (!retorno.data.retorno.status) {
						this.toastShow('Atenção!', `O colaborador '${retorno.data.retorno.motorista}' não possui jornada associada! Para realizar alterações, associe uma jornada!`, 'warning');

					} else {
						this.cancelarEvento(this.jornadaDeletadaFechar);
						this.$bvModal.hide('modalConfirmacaoRemoverEvento');
						this.toastShow('Sucesso!', 'Jornada deletada com sucesso!', 'success');
						this.toastShow('Atenção!', 'O relatório deve ser gerado novamente para atualizar os dados de jornada.', 'warning');
						this.gerarRelatorio(false);
					}
				})
				.catch(() => {
					conectionError()
				})
		},

		/**
		 * Organiza as jornadas removendo a jornada selecionada para exclusão dos dados resumidos.
		 * Limpa a jornada selecionada para exclusão e atualiza o estado para ativar/desativar a remoção de eventos.
		 *
		 * @author Yuri 🇧🇷
		 */
		organizarJornadasDeletar() {
			this.dataRelResumido.map((dados) => {
				const chavesDatas = Object.keys(dados);

				chavesDatas.map((data) => {
					if (data != 'placa') {
						this.dataRelResumido[0][data].dados = this.dataRelResumido[0][data].dados.filter(
							dado => dado.codigoJornada !== this.jornadaSelecionadaDeletar.codigoJornada
						);
						this.jornadaSelecionadaDeletar = '';
					}
				})

				this.toastShow('Sucesso!', 'Jornada deletada com sucesso!', 'success');
				this.toastShow('Atenção!', 'O relatório deve ser gerado novamente para atualizar os dados de jornada.', 'warning');
				this.ativarRemoverEventos = !this.ativarRemoverEventos
			})
		},

		/**
	 * Atualiza o valor do evento com base no valor fornecido no input.
	 *
	 * @param {Event} evento Evento contendo o valor atualizado do evento.
	 * @author Yuri 🇧🇷
	 */
		changeEvento(evento) {
			evento.target.value
		},

		/**
	 * Atualiza o tipo selecionado com base no valor fornecido no input.
	 *
	 * @param {Event} tipo Evento contendo o valor atualizado do tipo.
	 * @author Yuri 🇧🇷
	 */
		changeTipo(tipo) {
			this.tipoSelecionado = tipo.target.value
		},

		/**
		 * Atualiza o veículo selecionado e define a placa e o prefixo com base nas informações do veículo escolhido.
		 * Também marca a troca de placa como verdadeira.
		 *
		 * @param {Event} veiculo Evento contendo o valor atualizado do veículo.
		 * @author Yuri 🇧🇷
		 */
		changeVeiculo(veiculo) {
			this.veiculoSelecionado = veiculo.target.value;
			this.trocaPlaca = true

			this.optSelectVeiculos.map((veiculoSelecionado) => {
				if (veiculoSelecionado.value == veiculo.target.value) {

					const dadosVeiculoSelecionado = veiculoSelecionado.description.split(" ");

					this.placaSelecionada = dadosVeiculoSelecionado[0];
					this.prefixoSelecionado = dadosVeiculoSelecionado[2];
				}
			})
		},

		/**
		 * Atualiza a data selecionada com base no valor fornecido no input e marca a nova data como verdadeira.
		 *
		 * @param {Event} data Evento contendo o valor atualizado da data.
		 * @author Yuri 🇧🇷
		 */
		changeData(data) {
			this.dataSelecionada = data.target.value;
			this.novaData = true;
		},

		/**
		 * Atualiza o horário selecionado com base no valor fornecido no input.
		 *
		 * @param {Event} horario Evento contendo o valor atualizado do horário.
		 * @author Yuri 🇧🇷
		 */
		changeHorario(horario) {
			this.horarioSelecionado = horario;
		},

		/**
		 * Atualiza a justificativa selecionada com base no valor fornecido no input.
		 *
		 * @param {Event} justificativa Evento contendo o valor atualizado da justificativa.
		 * @author Yuri 🇧🇷
		 */
		changeJustificativa(justificativa) {
			this.justificativaSelecionada = justificativa.target.value;
			this.jornadaJustificada = true;
		},

		/**
		 * Altera o valor do motorista com base no evento de alteração de entrada.
		 *
		 * @param {Event} motorista Evento contendo o valor atualizado do motorista.
		 * @author Yuri 🇧🇷
		 */
		changeMotorista(motorista) {
			motorista.target.value
		},

		/**
		 * Salva um evento atualizado, enviando os dados modificados para a API e reorganizando os eventos localmente.
		 *
		 * @param {Object} registro Objeto contendo o registro original da jornada.
		 * @author Yuri 🇧🇷
		 */
		salvarEvento(registro) {
			const obj = {
				evento: this.eventoSelecionado,
				tipo: this.tipoSelecionado,
				veiculo: this.veiculoSelecionado,
				data: this.dataSelecionada,
				horario: this.horarioSelecionado,
				motorista: this.motoristaSelecionado,
				justificativa: this.justificativaSelecionada,
				placa: this.placaSelecionada,
				jornadaJustificada: this.jornadaJustificada,
			};

			const url = this.baseUri + 'editar/jornada';
			const data = {
				jornada: registro,
				jornadaEditada: obj,
				motorista: this.motoristaSelecionado
			};

			new HttpRequest()
				.Post(url, data)
				.then((retorno) => {
					if (!retorno.data.retorno.status) {
						this.toastShow('Atenção!', `O colaborador '${retorno.data.retorno.motorista}' não possui jornada associada! Para realizar alterações, associe uma jornada!`, 'warning');
					} else {
						this.organizarDadosEventosEditado(registro, obj);
						this.jornadaJustificada = false;
						this.justificativaSelecionada = [];
					}

				})
				.catch(() => {
					conectionError()
				})
		},

		/**
		 * Atualiza a listagem com a nova jornada inserida.
		 *
		 * @param {Object} registroNovo Objeto contendo os novos dados da jornada a serem aplicados.
		 * @author Yuri 🇧🇷
		 */
		organizarJornadaAdicionada(registroNovo) {
			this.gerarRelatorio(false);

			this.dataRelResumido.map((dados) => {
				const chavesDatas = Object.keys(dados);

				chavesDatas.map((data) => {
					if (data != 'placa') {
						if (this.trocaPlaca) {
							this.dataRelResumido[0][data].dados = this.dataRelResumido[0][data].dados.filter(
								dado => dado.codigoJornada !== registroNovo.codigoJornada
							);
						} else {
							if (this.novaData) {
								const novaDataString = this.formatarDataUTM(registroNovo.data);

								if (!this.dataRelResumido[0][novaDataString]) {
									this.dataRelResumido[0][novaDataString] = { dados: [] };
								}

								this.dataRelResumido[0][novaDataString].dados.push({
									data: novaDataString,
									inicio: registroNovo.horario,
									motorista: registroNovo.motorista,
									ocorrencia: registroNovo.evento,
									placa: registroNovo.placa,
									tipoOcorrencia: registroNovo.evento,
									clicado: false,
									prefixo: this.prefixoSelecionado
								});
							} else {
								const registrosExistentes = this.dataRelResumido[0][data].dados;

								registrosExistentes.push({
									data: this.formatarDataUTM(registroNovo.data),
									inicio: registroNovo.horario,
									motorista: registroNovo.motorista,
									ocorrencia: registroNovo.evento,
									placa: registroNovo.placa,
									tipoOcorrencia: registroNovo.evento,
									clicado: false,
									prefixo: this.prefixoSelecionado
								});
							}
						}
					}
				});

				this.toastShow('Sucesso!', 'Jornada adicionada com sucesso!', 'success');
				this.toastShow('Atenção!', 'O relatório deve ser gerado novamente para atualizar os dados de jornada.', 'warning');
			});
		},

		/**
		 * Organiza e atualiza os dados de eventos editados, ajustando informações de uma jornada com base no registro antigo e novo.
		 *
		 * @param {Object} registroAntigo Objeto contendo os dados da jornada original que será atualizada.
		 * @param {Object} registroNovo Objeto contendo os novos dados da jornada a serem aplicados.
		 * @author Yuri 🇧🇷
		 */
		organizarDadosEventosEditado(registroAntigo, registroNovo) {
			this.gerarRelatorio(false);

			this.dataRelResumido.map((dados) => {
				const chavesDatas = Object.keys(dados);

				chavesDatas.map((data) => {
					if (data != 'placa') {
						if (this.trocaPlaca) {
							this.dataRelResumido[0][data].dados = this.dataRelResumido[0][data].dados.filter(
								dado => dado.codigoJornada !== registroAntigo.codigoJornada
							);
						} else {
							this.dataRelResumido[0][data].dados.map((dado, index) => {
								if (this.novaData) {
									const novaDataString = this.formatarDataUTM(registroNovo.data);

									this.dataRelResumido[0][novaDataString].dados[index].data = novaDataString;
									this.dataRelResumido[0][novaDataString].dados[index].inicio = registroNovo.horario;
									this.dataRelResumido[0][novaDataString].dados[index].motorista = registroNovo.motorista;
									this.dataRelResumido[0][novaDataString].dados[index].ocorrencia = registroNovo.evento;
									this.dataRelResumido[0][novaDataString].dados[index].placa = registroNovo.placa;
									this.dataRelResumido[0][novaDataString].dados[index].tipoOcorrencia = registroNovo.evento;
									this.dataRelResumido[0][novaDataString].dados[index].clicado = false;
									this.dataRelResumido[0][novaDataString].dados[index].prefixo = this.prefixoSelecionado;
								} else if (dado.unique == registroAntigo.unique) {
									this.dataRelResumido[0][data].dados[index].data = this.formatarDataUTM(registroNovo.data);
									this.dataRelResumido[0][data].dados[index].inicio = registroNovo.horario;
									this.dataRelResumido[0][data].dados[index].motorista = registroNovo.motorista;
									this.dataRelResumido[0][data].dados[index].ocorrencia = registroNovo.evento;
									this.dataRelResumido[0][data].dados[index].placa = registroNovo.placa;
									this.dataRelResumido[0][data].dados[index].tipoOcorrencia = registroNovo.evento;
									this.dataRelResumido[0][data].dados[index].clicado = false;
									this.dataRelResumido[0][data].dados[index].prefixo = this.prefixoSelecionado;
								}
							})
						}
					}
				})

				this.toastShow('Sucesso!', 'Jornada atualizada com sucesso!', 'success');
				this.toastShow('Atenção!', 'O relatório deve ser gerado novamente para atualizar os dados de jornada.', 'warning');
			})
		},

		/**
		 * Registra uma nova jornada enviando os dados do evento para a API.
		 * Após o registro, exibe a resposta no console.
		 *
		 * @author Yuri 🇧🇷
		 */
		registrarNovaJornada(registro) {
			const url = this.baseUri + 'registrar/nova/jornada';
			const obj = {
				jornada: {
					evento: this.eventoSelecionado,
					tipo: this.tipoSelecionado,
					veiculo: this.veiculoSelecionado,
					data: this.dataSelecionada,
					horario: this.horarioSelecionado,
					motorista: this.motoristaSelecionado,
					justificativa: this.justificativaSelecionada,
					placa: this.placaSelecionada
				},
				jornadaRegistrada: registro,
				origem: this.origemJornada,
			};

			new HttpRequest()
				.Post(url, obj)
				.then((retorno) => {
					if (!retorno.data.retorno.status) {
						this.toastShow('Atenção!', `O colaborador '${retorno.data.retorno.motorista}' não possui jornada associada! Para realizar alterações, associe uma jornada!`, 'warning');
					} else {
						this.cancelarEvento(registro);
						this.jornadaJustificada = false;
						this.justificativaSelecionada = null;
						this.toastShow('Sucesso!', 'Jornada adicionada com sucesso!', 'success');
						this.toastShow('Atenção!', 'O relatório deve ser gerado novamente para atualizar os dados de jornada.', 'warning');
						this.gerarRelatorio(false);
					}

				})
				.catch(() => {
					conectionError()
				})
		},

		/**
		 * Limpa os dados selecionados para a criação de uma nova jornada de trabalho.
		 * 
		 * Esta função redefine todos os campos selecionados na criação de uma nova jornada, 
		 * como evento, tipo, veículo, data, horário, motorista, justificativa, placa e a jornada justificada.
		 * 
		 * @author Yuri 🇧🇷
		 */
		limparDadosNovaJornada() {
			this.eventoSelecionado = null;
			this.tipoSelecionado = null;
			this.veiculoSelecionado = null;
			this.dataSelecionada = null;
			this.horarioSelecionado = null;
			this.motoristaSelecionado = null;
			this.justificativaSelecionada = null;
			this.placaSelecionada = null;
			this.jornadaJustificada = null;
		},

		/**
		 * Cancela um evento específico, desmarcando-o como clicado nos dados resumidos.
		 *
		 * @param {Object} oc Objeto contendo informações do evento, incluindo 'data' e 'unique'.
		 * @author Yuri 🇧🇷
		 */
		cancelarEvento(oc) {
			this.limparDadosNovaJornada();

			this.dataRelResumido.map((data, index) => {
				data[oc.data].dados.map((registro, indexItem) => {
					if (registro.unique == oc.unique) {
						this.dataRelResumido[index][oc.data].dados[indexItem].clicado = false;
					}
				})
			})
		},

		/**
		* @description Método para mostrar o toast na tela
		* @param {string} msg   - mensagem que vai aparecer no corpo do toast
		* @param {string} type  - qual o tipo do toast
		* @param {string} title - título para inserir no cabeçalho
		* @author Vitor Hugo 🐨
		*/
		toastShow(title, msg, type) {
			this.$bvToast.toast(msg, {
				autoHideDelay: 3500,
				variant: type,
				title: title
			});
		},
	},

	computed: {
		/**
		 * Prepara o objeto que deve ser enviado
		 * para o back-end para gerar o relatório
		 */
		objRequest() {
			if (this.relatorioResumido) {
				return {
					dataFim: this.datas.fim,
					dataInicio: this.datas.ini,
					clientes: this.relatorio.cliente,
					veiculos: this.relatorio.veiculo,
					tipeV: this.check.veiculo,
					filtroTempo: this.tempoParado[0],
					apenasJornada: this.check.apenasJornada,
					prefixo: this.check.prefixo
				}
			} else {
				return {
					dini: this.datas.ini,
					dfim: this.datas.fim,
					tipeV: this.check.veiculo,
					tipeM: this.check.trabalhador,
					empresas: this.relatorio.cliente,
					veiculos: this.relatorio.veiculo,
					motoristas: this.relatorio.trabalhador
				}
			}
		},

		/**
		 * Separa o campo intervalo em 2 datas
		 * para enviar para o back-end 🍺
		 */
		datas() {
			if (this.relatorio.intervalo === '') {
				return {
					ini: moment(),
					fim: moment()
				}
			}
			var datas = this.relatorio.intervalo.split(' - ')
			let inicio = datas[0].split('/')
			let fim = datas[1].split('/')
			inicio =
				inicio.reverse().join('-') + ' ' + this.relatorio.horaInicial + ':00'
			fim = fim.reverse().join('-') + ' ' + this.relatorio.horaFinal + ':59'
			return {
				ini: inicio,
				fim: fim
			}
		},

		tamanhoIntervalo() {
			let ini = moment(new Date(this.datas.ini))
			let fim = moment(new Date(this.datas.fim))

			return fim.diff(ini, 'days')
		},

		formInvalido() {
			if (this.tamanhoIntervalo > 7) {
				return true
			}
			if (
				!this.relatorio.veiculo.length &&
				!this.relatorio.trabalhador.length
			) {
				return true
			}
			return false
		}
	},

	watch: {
		'relatorio.horaInicial'() {
			this.dataRelDetalhado = []
			this.dataRelResumido = []
		},
		'relatorio.horaFinal'() {
			this.dataRelDetalhado = []
			this.dataRelResumido = []
		},
		relatorioResumido() {
			this.dataRelDetalhado = []
			this.dataRelResumido = []
			this.searchQ = ''
			this.$refs.seletorTrabalhador.clearAll()
			this.infoDetalhado = 'info'
			this.infoResumido = 'info'
		}
	},

	mounted() {
		if (!this.getMaster()) {
			this.selectedEmpresa = this.optSelectEmpresas
		}
	}
}
</script>

<style lang="scss" scoped>
.cedulaProcurar {
	text-align: right;
}

.inputBusca {
	border: 1px solid black;
	padding-left: 5px;
	padding-top: 2px;
}

.fundoVerde {
	background-color: #dff0d8 !important;
}

.fundoVermelho {
	background-color: #f2dede !important;
}

.badgePlaca {
	background-color: #777;
	padding-left: auto;
	padding-right: auto;
	color: white;
	margin-top: 5px;
	padding-top: 4px !important;
	font-size: 15px !important;
	text-align: center !important;
}

.linha2 {
	padding: 0 !important;
	margin-top: 1%;
	margin-bottom: 1%;
}

.somepadding {
	padding-left: 0.5% !important;
	padding-right: 0.5% !important;
}

.botao {
	cursor: pointer;
}

.fonte-td-resumido {
	font-size: 11px !important;
}

.totais {
	background-color: #d5d5d5 !important;
}

.fonte-tr-resumido {
	font-size: 11px !important;
}

.icone::before {
	font-size: 12px !important;
	padding: 0 !important;
	border: 0 !important;
	margin: 0 !important;
}

.red {
	color: red;
}

.green {
	color: green;
}

.busca {
	padding-left: 40%;
	max-height: 20px !important;
}

.celIconeTexto {
	align-content: left !important;
	width: 10%;
	padding: 1px;
}

.enderecoDetalhado {
	width: 20%;
	padding: 1px;
}

.dadosTabela {
	td {
		padding: 3px;
	}
}

.square {
	display: inline-block !important;
	width: 10px !important;
	height: 10px !important;
	background-color: black;
	border-radius: 2px !important;
}

th {
	padding-left: 0.6% !important;
	min-width: 80px;
}

.colorIconLocalizar {
	color: #2196f3;
}

.IL {
	color: #018001;
}

.ID {
	color: #fe0c0c;
}

.EC {
	color: #fe0c0c;
}

.ER {
	color: #018001;
}

.totalizador {
	background: rgba(213, 213, 213, 0.7);
}

.negrito {
	font-weight: bold;
}

.sticky-header {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10;
	background-color: white;
	padding: 10px;
	border-radius: 0.375rem;
}
</style>
