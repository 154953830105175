<template>
    <panelEagle id='Cadastro de Novo Usuário App' :loading='loading'>
        <div class="col-sm-12 divDontPrint nopadding">
            <div class="col-sm-12 row nopadding">
                <div class="col-sm-6 row ">
                    <tituloPage titulo='Usuário aplicativo' :icon='mdiCellphoneText'>
                    </tituloPage>
                </div>
                <div class="col-sm-6 row ">
                    <BBCadastroSC 
                        linkCancel='usuariosApp'
                        tipoCancela='button' 
                        @salvarCadastro="salvaCadastro" 
                        :disabled='$v.$invalid || usuariosAppExedido' />
                </div>
            </div>
            <div class="col-12"><hr></div>
            <div class="col-12 nopadding ">
                <div class="row">
                    <div class="col-6 nopadding">
                        <buttonsFilters
                            label='Status'
                            :arrayButtons='botoesStatus'
                            @buttonsFiltersChange='mudaBotaoStatus'>
                        </buttonsFilters>
                    </div>  
                    <div class="col-6 nopadding">
                    <buttonsFilters
                        label="Modo rastreador" 
                        :arrayButtons='botoesRastreador'
                        @buttonsFiltersChange='mudaFiltroModoRastreador'>
                    </buttonsFilters>
                    </div>
                    <div class="col-4 nopadding mt-2">
                        <selectall
                            nameForRadio='selecionaEmpresa'
                            :labels='labelUsuarios'
                            :selected='valueSelectEmpresa'
                            :isMultiple='false'
                            :allowEmpty='false'
                            :optionsArray='optSelectEmpresas'
                            :extraClass='{"border border-danger": $v.usuario.usacliente.$anyError}'
                            @close='$v.usuario.usacliente.$touch()'
                            @changeSelect='mudaFiltroEmpresa'>
                        </selectall>
                    </div>
                     <div class="col-4 nopadding mt-2">
                        <selectall 
                            nameForRadio='selecionaPerfil'
                            :isMultiple='false'
                            :allowEmpty='false'
                            :selected='valuePerfil'
                            :optionsArray='optSelectTipoPerfil'
                            :labels='[{description:"Tipo de Perfil*"}]'
                            :extraClass='{"border border-danger": $v.usuario.usaperfil.$anyError}'
                            @close='$v.usuario.usaperfil.$touch()'
                            @changeSelect='mudaSelectPerfil'
                        />
                    </div>
                    <div class="col-4 nopadding mt-2">
                        <selectall
                            nameForRadio='SelecionaAssociado'
                            :disabled='!optAssociado.length'
                            :isMultiple='false'
                            :allowEmpty='false'
                            :labels='labelAssocidado'
                            :selected='valueAssociado'
                            :optionsArray='optAssociado'
                            :loading='carregandoAssociado'
                            ref='seletorAssociado'
                            :extraClass='{"border border-danger": $v.usuario.usamotorista.$anyError}'
                            @close='$v.usuario.usamotorista.$touch()'
                            @changeSelect='mudaFiltroAssocidado'
                        >
                        </selectall>
                    </div>
                </div>
            </div>
            <requiredFields></requiredFields>
        </div>
        <div class="col-sm-12"><hr></div>
    </panelEagle>
</template>
<script>
import Vue from 'vue'
import Swal from 'sweetalert2'
import { conectionError } from  '@/Services/Helpers/swellHeper.js'
import { required } from 'vuelidate/lib/validators'
import { UsuarioApp } from './UsuarioApp'
import { mdiCellphoneText } from '@mdi/js'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service';
import { EmpresasService } from '@/Services/auth/Empresas.service';
export default Vue.extend({
	name:'cadastrarUsuarioApp',
	components:{
		'panelEagle'     : require('@/components/Atom/Panel/PanelEagle').default,
		'tituloPage'     : require('@/components/Atom/Header/Titulo').default,
		'BBCadastroSC'   : require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		'buttonsFilters' : require('@/components/Atom/Buttons/ButtonsFilters').default,
		'requiredFields' : require('@/components/Atom/Footer/RequiredFields').default,
		'selectall'      : require('@/components/Atom/Select/SelectAll').default,
	},
	data(){
		return{
			mdiCellphoneText:mdiCellphoneText,
			// dados usuario
			usuario : UsuarioApp,
			// links
			link          : '/administrativo/cadastros/usuarios/app/',
			// Values
			valueStatus         : '',
			valuePerfil         : [],
			valueAssociado      : [],
			valueSelectEmpresa  : [],
			valueModoRastreador : '',
			// opt
			optAssociado       : [],
			optSelectEmpresas  : [],
			optSelectTipoPerfil: [
				{ value:'M', description: 'Motorista/Ajudante'},
				{ value:'A', description: 'Administrativo'    },
				{ value:'R', description: 'Roterizador', $isDisabled:true    }
			],
			// Botões, 
			botoesStatus:[
				{'value':'A', 'width': '49.2%', 'text': 'Ativo',   'isSelected':true},
				{'value':'I', 'width': '49.2%', 'text': 'Inativo', 'isSelected':false},
			],
			botoesRastreador:[
				{'value':'S', 'width': '49.2%', 'text': 'Sim', 'isSelected':false},
				{'value':'N', 'width': '49.2%', 'text': 'Não', 'isSelected':true}
			],
			// Errors
			erroSelectEmpresa :'',
			erroAssocidado    :'',
			errorClass        :'border border-danger',
			labelUsuarios:[
				{indexDFH: 'EM', 
					description: 'Empresa*'}
			],
			labelAssocidado:[{
				indexDFH: 'C', 
				description: 'Associado*'
			}],
			first:false,
			loading:false,
			carregandoAssociado:false,
			usuariosAppExedido: false
		}
	},
	validations:{
		usuario:{
			usacliente  : { required },
			usaperfil   : { required },
			usamotorista: { required },
		}
	},
    
	methods: {
		salvaCadastro(){
			this.fazPost();
		},
		limpaErros(){
			this.erroSelectEmpresa = '';
			this.erroAssocidado = '';
		},

		mudaSelectPerfil(value){
			this.usuario.usaperfil = value[0]
			this.carregaAssociados()
		},

		redireciona(){
			this.$router.push({name:'usuariosApp'});
		},

		mudaFiltroEmpresa(value){
			if(this.first){
				this.first = false
				return;
			}
			this.usuario.usacliente = value[0];
			this.valueAssociado = [];
			this.usuario.usamotorista = '';
			this.carregaAssociados();
			this.listaUsuariosApp();
		},
        
		/**
         * decide entre salvar ou atualizar, envia os dados
         * para o back-end e lida com qualquer erro que possa
         * a acontecer
         */
		fazPost(){
			this.loading = true
			let rota = this.link
			if(this.$route.params.id) rota +='atualizar'
			else rota += 'salvar'
			new HttpRequest().Post(rota, {usuario: this.usuario})
				.then((data)=>{
					if(data?.code == 201){
						this.redireciona()
					} else if(data?.code == 200){
						Swal.fire({
							icon: 'warning',
							title: 'Oops...',
							html: data.data.mensagem,
							footer: '',
						})
					}else{
						conectionError()
					}
				}).finally(()=> this.loading = false)
		},


		/**
		 * @description Função para buscar os usuarios cadastrados para uso do app)
		 *  Efetua um request ao banco a fim de listar a quantidade de usuarios permitidos
		 *  caso retorne true exibe a mensagem toastoShow no canto da tela e desabilita o botao salvar.
		 * @param {array}   this.usuario.usacliente        - Numero da empresa selecionada no select empresa
		 * @return toastShow - com a mensagem caso true.
		 * @author VeCo® 🔱
		 */
		listaUsuariosApp(){
			let rotaUsuarioApp = this.link+'listausuariosapp'
			new HttpRequest().Post(rotaUsuarioApp, {empresa: this.usuario.usacliente})
				.then((data)=>{
					if(data?.data.success){
						this.toastShow(
							"Atenção!", 
							"Quantidade de usuários do app excedeu o limite do contrato! Contate o suporte para mais informações.", 
							"warning"
						);
						this.usuariosAppExedido = true;
					}else{
						this.usuariosAppExedido = false;
					}
				})
		},

		//Exibe a mensagem no canto direito
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title
            });
        },


		/**
         * Faz a busca pelos associados a aparecer no seletor
         * Verifica se existe um cliente e um perfil para usar como
         * filtro, caso positivo envia as informações para o back-end
         */
		async carregaAssociados(){
			if(this.usuario.usacliente && this.usuario.usaperfil){
				this.carregandoAssociado = true
				let rota = this.link+'associados'
				let obj = {
					clientes: [this.usuario.usacliente],
					perfil  : [this.usuario.usaperfil]
				}
				new HttpRequest()
					.Post(rota ,obj)
					.then((data)=>this.optAssociado = data.data.motoristas)
					.finally(()=>this.carregandoAssociado = false)
			}
		},

		mudaBotaoStatus(value){
			this.valueStatus = value;
			this.usuario.usastatus = value[0];
		},

		mudaFiltroAssocidado(value){
			this.usuario.usamotorista = value[0];
		},

		mudaFiltroModoRastreador(value){
			this.valueModoRastreador = value;
			this.usuario.usarastreador = value[0];
		},

		setBotoes(){
			if (this.usuario.usastatus == 'N'){
				this.botoesStatus[0]['isSelected'] = false;
				this.botoesStatus[1]['isSelected'] = true;
			}
			if(this.usuario.usarastreador == 'S'){
				this.botoesRastreador[0]['isSelected'] = true;
				this.botoesRastreador[1]['isSelected'] = false;
			}
		},

		setTipoPerfil(){
			if(this.usuario.usaperfil != '' ){
				let t = this.optSelectTipoPerfil
					.find(p=>{
						return p.value == this.usuario.usaperfil
					})
				if(t !== undefined)
					this.valuePerfil.push(t)
			}
		},
        
		/**
         * Carregar usuário para edição
         */
		setUsuario(usuario){
			this.usuario.usacodigo      = usuario.usacodigo;
			this.usuario.usastatus      = usuario.usastatus;
			this.usuario.usacliente     = usuario.usacliente;
			this.usuario.usarastreador  = usuario.usarastreador;
			this.usuario.usamotorista   = usuario.usamotorista;
			this.usuario.usaperfil      = usuario.usaperfil
		},

		/**
         * Por algum motivo o usuário estava vindo com sujeira
         * e fazia com que a validação desse erro, isso é só 
         * pra garantir que não exista nada que não deveria na memoria
         */
		unsetUser(){
			this.usuario.usacliente   = ''
			this.usuario.usaperfil    = '' 
			this.usuario.usamotorista = ''
		},
	},
	mounted(){
		this.loading = true
		this.unsetUser()
		this.optSelectEmpresas = new EmpresasService().Get()
		if(this.$route.params.id){
			this.first = true
			var link = this.link+'getuser'
			var request = { codigo : this.$route.params.id }
			new HttpRequest().Post(link, request)
				.then((data)=>{
					this.setUsuario(data.data.usuario)
					this.valueAssociado     = [data.data.motorista]
					this.valueSelectEmpresa = [data.data.cliente]
					this.setBotoes()
					this.setTipoPerfil()
					this.loading = false
				})
		}else{
			this.loading = false
		}
	},
	watch:{
		/**
         * Verifica as alterações de usaperfil para limpar o campo
         * de associado
         */
		'usuario.usaperfil'(newValue, oldValue){
			this.$refs.seletorAssociado.clearAll()
		}
	}
})
</script>
<style lang="scss" scoped>
</style>
