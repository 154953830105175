<template>
	<eaglePanel id="cadastarClientes" :loading="carregandoCliente">
		<div class="col-sm-12">
			<div class="col-sm-12 nopadding row">
				<div class="col-sm-6 nopadding">
					<titulo titulo="Empresa" :icon="mdiOfficeBuildingMarker" />
				</div>
				<div class="col-sm-6 nopadding">
					<botoes :disabled="$v.$invalid || !docValido" tipoCancela="button" linkCancel="listarClientes"
						@salvarCadastro="salvarCliente" />
				</div>
			</div>

			<div class="col-sm-12 nopadding">
				<hr />
			</div>

			<div class="col-sm-12 nopadding">
				<b-tabs v-model="pagina">
					<!-- CADASTRO -->
					<b-tab title="Cadastro">
						<div class="col-sm-12 nopadding row mt-2">
							<div class="col-sm-4 nopadding">
								<filtros label="Tipo de cliente" :arrayButtons="buttonsFiltroTipoCliente"
									@buttonsFiltersChange="mudaTipoCliente" />
							</div>

							<div class="col-sm-8 prospeccao">
								<b-check v-model="cliente.clprospeccao">
									Em prospecção
								</b-check>
							</div>

							<div class="col-sm-4 nopadding mt-3">
								<simpleInput name="razaoSocial" label="Razão Social*" :value="cliente.clnome" :inputClass="{
									'border border-danger':
										$v.cliente.clnome.$anyError,
								}" @blur="$v.cliente.clnome.$touch()" @changeInput="(v) => (cliente.clnome = v)" />
							</div>

							<div class="col-sm-3 nopadding mt-3">
								<simpleInput name="inputDoc1" :label="clienteJuridico ? 'CNPJ*' : 'CPF*'" :value="cliente.cldocumento"
									:hasMask="true" :inputClass="{
										'border border-danger':
											$v.cliente.cldocumento.$anyError,
									}" :title="docValido
	? ''
	: clienteJuridico
		? 'CPNJ inválido!'
		: 'CPF inválido!'
	" :mask="clienteJuridico
		? ['NN.NNN.NNN/NNNN-NN']
		: ['NNN.NNN.NNN-NN']
		" @blur="$v.cliente.cldocumento.$touch()" @changeInput="changeCPFCNPJ" />
							</div>

							<div class="col-sm-2 nopadding mt-3">
								<simpleInput name="inputDoc2" :label="clienteJuridico ? 'Insc. estadual' : 'RG'"
									:value="cliente.cldocumento2" @changeInput="(v) => (cliente.cldocumento2 = v)" />
							</div>


							<!--
							<div class="col-sm-3 row noppading">
								<div class="titulo-parametros col-lg-12">
									Segmento Empresa*
								</div>
								<div class="col-sm-7 noppading">
									<selectAll class="p-0" nameForRadio="selectSegmentoEmpresa" :isMultiple="true"
										:optionsArray="optSelectTipoProduto" :selected="tipoSegmentoEmpresaSelecionada"
										:labels="labelSelectSegmentoEmpresa" 
										:loading="carregandoSegmentoEmpresa" ref="selectTipoSegmentoEmpresa" @changeSelect="changeTipoSegmentoEmpresa" />
								</div>
								<div class="col-sm-3">
									<buttonS text="Novo" type="blue" :icon="mdiPlus" extraClass="buttonS"
										:disabled="$v.formulario.veproprietario.$invalid" event="click"
										@click="$bvModal.show('modalCadastraTipoProduto')" />
								</div>
							</div>
							-->
							
							
							<div class="col-sm-3 nopadding mt-3">
								<selectAll v-bind="{
									isMultiple: false,
									allowEmpty: false,
									nameForRadio: 'SeletorSegmento',
									selected: preSegmento,
									labels: [{ description: 'Segmento Empresa*' }],
									optionsArray: optSelectSegmentoEmpresa,
									extraClass: {
										'border border-danger':
											$v.cliente.clsegmento.$anyError,
									},
								}" @close="$v.cliente.clsegmento.$touch()" @changeSelect="selectSegmento" />
							
							</div>
							




							

							<div class="col-sm-12 nopadding mt-3 row" v-show="clienteJuridico">
								<div class="col-sm-4 nopadding">
									<simpleInput name="nomeFantasia" label="Nome Fantasia" :value="cliente.clfantasia"
										@changeInput="(v) => (cliente.clfantasia = v)" />
								</div>
							</div>

							<div class="col-sm-12 nopadding row mt-3">
								<div class="col-sm-4">
									<muilt :hasMask="true" :qtBnt="qtBntTel" label="Telefone" :mask="[`(NN) PPPNN-PPPNNNN`]"
										@changeInput="alteraTelefones" />
								</div>

								<div class="col-sm-4">
									<muilt :qtBnt="qtBntEmail" label="Email" @changeInput="alteraEmails" />
								</div>
							</div>

							<div class="col-sm-12 nopadding row mt-3">
								<div class="col-sm-4 nopadding">
									<datePicker name="dataVigencia" label="Data Vigência" :value="cliente.cliniciovigencia" @changeInput="(v) => (cliente.cliniciovigencia = v)
										" />
								</div>

								<div class="col-sm-4 nopadding row">
									<simpleInput name="site" label="Site" :value="cliente.clsite"
										@changeInput="(v) => (cliente.clsite = v)" />
								</div>

								<div class="col-sm-4" style="text-align: left">
									<label class="subtitle">Logo</label>
									<div class="divUpload">
										<input class="form-control" readonly :value="imageValue" />
										<button @click="onPickFileLogo" class="botaoUpload">
											<baseIcon size="16" class="icone" :icon="mdiCloudUpload" />
										</button>
										<input type="file" accept="image/png, image/jpeg" style="display: none" ref="fileInputLogo"
											@change="onFileLogo" />

										<button @click="onDownloadLogoCliente(cliente.cllogo)" class="botaoUpload ml-3" v-if="cliente.cllogo">
											<baseIcon size="16" class="icone" :icon="mdiDownloadOutline" />
										</button>
									</div>

								</div>
							</div>

							<div class="col-sm-12 nopadding mt-3 row">
								<div class="col-sm-3 nopadding">
									<simpleInput name="logradouro" label="Logradouro" :value="cliente.cllogradouro"
										@changeInput="(v) => (cliente.cllogradouro = v)" />
								</div>

								<div class="col-sm-2 nopadding">
									<simpleInput name="numero" label="Número" :value="cliente.clnumero"
										@changeInput="(v) => (cliente.clnumero = v)" />
								</div>

								<div class="col-sm-2 nopadding">
									<simpleInput name="complemento" label="Complemento" :value="cliente.clcomplemento"
										@changeInput="(v) => (cliente.clcomplemento = v)" />
								</div>

								<div class="col-sm-2 nopadding">
									<simpleInput name="bairro" label="Bairro" :value="cliente.clbairro"
										@changeInput="(v) => (cliente.clbairro = v)" />
								</div>

								<div class="col-sm-3 nopadding">
									<selectAll :isMultiple="false" :selected="preCidade" :extraClass="{
										'border border-danger':
											$v.cliente.clcidade.$anyError,
									}" nameForRadio="seletorCidade" :allowEmpty="false" :labels="[{ description: 'Cidade*' }]"
										:optionsArray="optSelectCidade" @close="$v.cliente.clcidade.$touch()" @changeSelect="mudaCidade" />
								</div>

								<div class="col-4 nopadding mt-3">
									<simpleInput name="latitude" label="Latitude" type="number" :value="cliente.cllatitude"
										@change="procuraEndereco" @changeInput="(v) => (cliente.cllatitude = v)" />
								</div>

								<div class="col-4 nopadding mt-3">
									<simpleInput name="longitude" label="Longitude" :value="cliente.cllongitude" type="number"
										@change="procuraEndereco" @changeInput="(v) => (cliente.cllongitude = v)" />
								</div>
							</div>

							<div class="col-sm-12 mt-3">
								<div class="col-sm-12 labelMapa">
									<label id="labelLocalEmpresa">
										Selecione o local da empresa
									</label>
								</div>
								<b-overlay :show="procurandoInfo">
									<mapaS ref="mapaSimples" height="330" :latitude="Number(posMapaI.lat)"
										:longitude="Number(posMapaI.long)">
										<markers :latitude="cliente.cllatitude" :longitude="cliente.cllongitude" :isDraggable="true"
											@dragend="procuraEndereco" @drag="atualizaLatLongMarker">
											<popMarker>
												<div class="col-sm-12 nopadding centraliza">
													<div>
														Me arraste para o local desejado
													</div>
													<div style="font-weight: bold"></div>
													<div>
														<slider :value="valueSlide" ref="sliderRaio" :maxValue="500" @mudaValor="(v) =>
															(cliente.clraio = v)
															" />
													</div>
													<div v-html="'<b>Raio:</b>' +
														cliente.clraio +
														' Mts'
														" />
												</div>
											</popMarker>
										</markers>
										<circuloM :center="[
											cliente.cllatitude,
											cliente.cllongitude,
										]" :radius="cliente.clraio" />
									</mapaS>
								</b-overlay>
							</div>

						</div>
					</b-tab>

					<!-- CONFIGURAÇÕES -->
					<b-tab title="Configurações">
						<div class='col-sm-12'>
							<div class="col-sm-3 buttonConfig nopadding mt-2">
								<span class="tituloConfig">Configurações de Gerais</span>
							</div>
						</div>

						<div class="col-sm-12 nopadding row mt-3">
							<div class="col-sm-3 nopadding">
								<simpleInput name="limiteEmails" label="Limite de e-mails" :value="cliente.clfranquiamensalemail"
									@changeInput="(v) => (cliente.clfranquiamensalemail = v)" type='number' />
							</div>

							<div class="col-sm-2 nopadding">
								<simpleInput name="usuariosContratados" label="Usuários contratados*"
									:value="cliente.clusuarioscontratados" @changeInput="(v) => (cliente.clusuarioscontratados = v)"
									type='number' />
							</div>

							<div class="col-sm-2 nopadding">
								<simpleInput name="usuariosAppContratados" label="Usuários App contratados*"
									:value="cliente.clusuariosappcontratados" @changeInput="(v) => (cliente.clusuariosappcontratados = v)"
									type='number' />
							</div>

							<div class="col-sm-2 nopadding">
								<simpleInput name="veiculosContradoF" label="Veículos contratados*" :value="cliente.clveiculoscontratados"
									:hasMask="true" :mask="['NNNN']" @changeInput="alteraVeiculosContrado" />
							</div>

							<div class="col-sm-3" style="text-align: left">
								<label class="subtitle">Contrato do cliente</label>
								<div class="divUpload">
									<input class="form-control" readonly :value="contratoValue" />
									<button @click="onPickFileContratoCliente" class="botaoUpload">
										<baseIcon size="16" class="icone" :icon="mdiCloudUpload" />
									</button>
									<input type="file" accept="application/pdf" style="display: none" ref="fileInputContratoCliente"
										@change="onFileContratoCliente" />

									<button @click="onDownloadContratoCliente(cliente.clcontratocliente)" class="botaoUpload ml-3"
										v-if="cliente.clcontratocliente.length > 26">
										<baseIcon size="16" class="icone" :icon="mdiDownloadOutline" />
									</button>
								</div>

							</div>
						</div>

						<div class="col-sm-12">
							<div class="col-sm-3 buttonConfig nopadding mt-2">
								<span class="tituloConfig">Configurações de API</span>
							</div>

							<div class="col-sm-12 nopadding">
								<hr />
							</div>

							<div class="col-sm-2 nopadding buttonConfig">
								<simpleButton :text="apiAtiva ? 'Desabilitar API' : 'Habilitar API'" event="click" width="100%"
									@click="mudaApiKey" />
							</div>

							<div class="col-sm-9 nopadding" v-if="apiAtiva">
								<div class="labelKey">Chave API</div>
								<div class="divApi">
									<input readonly class="form-control alturaBotoes inputApi" :value="cliente.clapikey" />
									<button class="botao APICopiar alturaBotoes" v-text="'Copiar'" event="click" @click="copiarChave" />
									<button class="alturaBotoes botao APIAtt" v-text="'Atualizar Chave'" event="click"
										@click="atualizarChave" />
								</div>
							</div>

							<div :class="'col-sm-12 buttonConfig ' +
								(apiAtiva ? 'pt-1' : 'mt-5 pt-4')
								">
								<span v-text="'Regras Aplicativo'" class="tituloConfig" />
							</div>

							<div class="col-sm-12 nopadding">
								<hr />
							</div>

							<div class="col-sm-12 nopadding buttonConfig">
								<b-form-group label="Considerar Confirmações de entrega/coleta">
									<b-form-radio v-model="cliente.clmodotratamentorota" name="radioApp" value="A">
										Através do aplicativo
									</b-form-radio>
									<b-form-radio v-model="cliente.clmodotratamentorota" name="radioIg" value="I">
										Baseado na ignição e posicionamento do veículo
									</b-form-radio>
								</b-form-group>
							</div>

							<div :class="'col-sm-12 buttonConfig mt-5 pt-4'">
								<span v-text="'Regras Mapa'" class="tituloConfig" />
							</div>
							<div class="col-sm-12 nopadding buttonConfig">
									<b-form-radio v-model="cliente.clutilizacluster" name="radioMapa" value="A">
										Utiliza cluster
									</b-form-radio>
							</div>
						</div>
					</b-tab>

					<b-tab title="Parâmetros roterizador">
						<div class="col-sm-12 nopadding buttonConfig mt-2 ml-3">
							<b-check v-model="cliente.clrotaagruparpedidos">
								Agrupar pedidos para mesmo ponto
							</b-check>
						</div>
					</b-tab>

					<!-- MÓDULOS -->
					<b-tab title="Módulos">
						<b-form-group class="buttonConfig nopadding mt-2 ml-3">
							<b-form-checkbox-group v-model="modulosSelecionados" :options="allModulos" stacked />
						</b-form-group>
					</b-tab>

					<!-- APLICATIVO -->
					<b-tab title="Aplicativo">
						<div class="col-sm-12 nopadding buttonConfig mt-2 ml-3 row">
							<div class="col-sm-12">
								<b-form-checkbox name="habilitaEagleDriver" v-model="habilitaDriver" :value="true" :unchecked-value="false"
									@change="habilita">
									Habilitar eagle driver?
								</b-form-checkbox>
							</div>
							<div class="col-sm-2">
								<b-form-checkbox name="habilitaEagleDriver" ref="refAssinatura" v-model="driverOpcoes[0]" :value="true"
									:unchecked-value="false" :disabled="!habilitaDriver">
									Utiliza assinatura eletrônica?
								</b-form-checkbox>
								<b-form-checkbox name="habilitaEagleDriver" ref="refColeta" v-model="driverOpcoes[1]" :value="true"
									:unchecked-value="false" :disabled="!habilitaDriver">
									Realiza Coleta?
								</b-form-checkbox>
								<b-form-checkbox name="habilitaEagleDriver" ref="refAssinatura" v-model="driverOpcoes[4]" :value="true"
									:unchecked-value="false" :disabled="!habilitaDriver">
									Realiza Transbordo?
								</b-form-checkbox>
							</div>
							<div class="col-sm-3">
								<b-form-checkbox name="habilitaEagleDriver" ref="refAssinatura" v-model="driverOpcoes[2]" :value="true"
									:unchecked-value="false" :disabled="!habilitaDriver">
									Realiza impressão de ticket?
								</b-form-checkbox>
								<b-form-checkbox name="habilitaEagleDriver" ref="refAssinatura" v-model="driverOpcoes[3]" :value="true"
									:unchecked-value="false" :disabled="!habilitaDriver">
									Bloquear alteração da confirmação do ponto?
								</b-form-checkbox>

								
							</div>
						</div>
					</b-tab>

					<!-- INTEGRAÇÃO -->
					<b-tab title="Integração">
						<div class="col-sm-12 nopadding mt-2 row">
							<div class="col-sm-3 pt-4">
								<b-check v-model="ticket" @change="ticketLog">Ticket Log</b-check>
							</div>

							<div class="col-sm-3 nopadding">
								<simpleInput name="usuario" label="Usuário" :disabled="!ticket" :value="clienteIntegracao.ciusuario"
									@changeInput="(v) => (clienteIntegracao.ciusuario = v)
										" />
							</div>

							<div class="col-sm-3 nopadding">
								<simpleInput name="senha" label="Senha" :disabled="!ticket" :value="clienteIntegracao.cisenha"
									type="password" @changeInput="(v) => (clienteIntegracao.cisenha = v)" />
							</div>

							<div class="col-sm-3 nopadding">
								<simpleInput name="chave" label="Chave" :value="clienteIntegracao.cichave" :disabled="!ticket"
									@changeInput="(v) => (clienteIntegracao.cichave = v)" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<selectAll nameForRadio="seletorTipoServico" :labels="[{ description: 'Tipo de Serviço' }]"
									:isMultiple="false" :disabled="!ticket" :optionsArray="optSelectServicoIntegracao"
									:selected="preServicoIntegracao" @changeSelect="mudaTipoDeServico" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="enderecoServico" label="Endereço do serviço" :value="clienteIntegracao.cihost"
									:disabled="!ticket" @changeInput="(v) => (clienteIntegracao.cihost = v)" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="porta" label="Porta" :value="clienteIntegracao.ciporta" type="number"
									:disabled="!ticket" @changeInput="(v) => (clienteIntegracao.ciporta = v)" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="pasta" label="Pasta" :value="clienteIntegracao.cipasta" :disabled="!ticket"
									@changeInput="(v) => (clienteIntegracao.cipasta = v)" />
							</div>

							<div class="col-sm-12 nopadding">
								<hr />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="codigoInterfoc" label="Código interfoc filial" :value="cliente.clcodigointerfocfilial"
									@changeInput="(v) => (cliente.clcodigointerfocfilial = v)
										" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="codigoInterfoc" label="Código interfoc matriz" :value="cliente.clcodigointerfocmatriz"
									@changeInput="(v) => (cliente.clcodigointerfocmatriz = v)
										" />
							</div>

							<div class="col-sm-3 nopadding mt-1">
								<simpleInput name="uruBase" label="Url base" :value="cliente.clurlbaseinterfoc"
									@changeInput="(v) => (cliente.clurlbaseinterfoc = v)" />
							</div>
						</div>
					</b-tab>

					<!-- SMTP -->
					<b-tab title="SMTP">
						<div class="col-sm-4 pt-4 mb-5 row">
							<b-check class='' v-model="adicionarSMTP">Adicionar SMTP</b-check>

							<b-check class='ml-4' v-if="adicionarSMTP" v-model="clienteSMTP.csssl" @change="sslSelect">{{
								labelsClienteSMTP.csssl }}
							</b-check>
						</div>

						<div v-if="adicionarSMTP">
							<div class="col-sm-12 nopadding row">
								<div class="col-sm-4 nopadding">
									<simpleInput name="host" :label="labelsClienteSMTP.cshost" :value="clienteSMTP.cshost" @changeInput="(v) => (clienteSMTP.cshost = v)
										" />
								</div>

								<div class="col-sm-4 nopadding">
									<simpleInput name="porta" :label="labelsClienteSMTP.csporta" :value="clienteSMTP.csporta" type="number"
										@changeInput="(v) => (clienteSMTP.csporta = v)
											" />
								</div>

								<div class="col-sm-4 nopadding">
									<selectAll nameForRadio="seletorTipoConexao"
										:labels="[{ description: labelsClienteSMTP.cstipoconexao }]" :isMultiple="false"
										:optionsArray="optSelectTipoConexao" @changeSelect="mudaTipoDeConexao"
										:selected="tipoConexaoSelecionada" />
								</div>
							</div>

							<div class="col-sm-12 nopadding mt-4 row">
								<div class="col-sm-4 nopadding">
									<simpleInput name="usuario" :label="labelsClienteSMTP.csusuario" :value="clienteSMTP.csusuario"
										@changeInput="(v) => (clienteSMTP.csusuario = v)" />
								</div>

								<div class="col-sm-4 nopadding">
									<simpleInput :label="labelsClienteSMTP.cssenha" :type="tipo" placeholder="Senha"
										:value="clienteSMTP.cssenha" @changeInput="(v) => (clienteSMTP.cssenha = v)" />
									<span class="iconeOlhinho" @click="trocaIcone()">
										<base-icon :icon="icone" size="16" />
									</span>
								</div>

								<div class="col-sm-4 nopadding">
									<simpleInput name="remetente" :label="labelsClienteSMTP.csremetente" :value="clienteSMTP.csremetente"
										@changeInput="(v) => (clienteSMTP.csremetente = v)
											" />
								</div>
							</div>

							<div class="col-sm-12 nopadding row">
								<div class="col-sm-4 nopadding">
									<simpleInput name="remetenteTicket" :label="labelsClienteSMTP.csremetenteticket"
										:value="clienteSMTP.csremetenteticket" @changeInput="(v) => (clienteSMTP.csremetenteticket = v)
											" />
								</div>

								<div class="col-sm-4 nopadding">
									<simpleInput name="limiteEmailHora" :label="labelsClienteSMTP.cslimiteemailporhora"
										:value="clienteSMTP.cslimiteemailporhora" @changeInput="(v) => (clienteSMTP.cslimiteemailporhora = v)"
										type="number" />
								</div>

								<div class="col-sm-4 nopadding">
									<simpleInput name="limiteEmailMes" :label="labelsClienteSMTP.cslimiteemailpormes"
										:value="clienteSMTP.cslimiteemailpormes" @changeInput="(v) => (clienteSMTP.cslimiteemailpormes = v)"
										type="number" />
								</div>
							</div>
						</div>

					</b-tab>
				</b-tabs>

				<required />

				<div class="col-sm-12 nopadding">
					<hr />
				</div>
			</div>
		</div>

		<modalEagle id="gerarNovaChave" title="Alerta!!" @confirmButton="confirmaGerarChaveNova">
			<template #modalBody>
				<p>
					Se você confirmar essa ação, uma nova chave será gerada e os acessos
					do cliente serão bloqueados. Você deverá informar a nova chave para o
					cliente, para que ele possa implementar em suas integrações. Deseja
					mesmo continuar?
				</p>
			</template>
		</modalEagle>
	</eaglePanel>
</template>

<script>
import Vue from "vue";
import { mdiOfficeBuildingMarker, mdiCloudUpload } from "@mdi/js";
import { required } from "vuelidate/lib/validators";
import { cadastro } from "./EmpresasHelpers";
import { mapActions } from "vuex";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import axios from "axios";
import jsonpAdapter from "axios-jsonp";
import { validaCpfCnpj } from "@/Services/Helpers/ValidatorHelper";
import { mdiEye, mdiEyeOff, mdiDownloadOutline } from "@mdi/js";
import { conectionError } from '@/Services/Helpers/swellHeper';

export default Vue.extend({
	name: "CadastrarEmpresa",
	components: {
		baseIcon: require("@/components/Atom/Icon/BaseIcon.vue").default,
		eaglePanel: require("@/components/Atom/Panel/PanelEagle").default,
		titulo: require("@/components/Atom/Header/Titulo").default,
		botoes: require("@/components/Atom/Buttons/BasicButtonsCadastrosSC").default,
		filtros: require("@/components/Atom/Buttons/ButtonsFilters").default,
		simpleInput: require("@/components/Atom/Inputs/InputSimple").default,
		selectAll: require("@/components/Atom/Select/SelectAll").default,
		datePicker: require("@/components/Atom/Datas/InputSingleDay").default,
		mapaS: require("@/components/Atom/Mapa/MapaSimples").default,
		markers: require("@/components/Atom/Mapa/MarkerPoint").default,
		simpleButton: require("@/components/Atom/Buttons/SimpleButton").default,
		popMarker: require("@/components/Atom/Mapa/PopupMarker").default,
		slider: require("@/components/Atom/Buttons/BasicSlider").default,
		circuloM: require("@/components/Atom/Mapa/CircleSimple").default,
		modalEagle: require("@/components/Atom/Modal/ModalEagle").default,
		required: require("@/components/Atom/Footer/RequiredFields").default,
		muilt: require("@/components/Atom/Inputs/InputMultiple").default,
	},

	data() {
		return {
			dismissCountDown: 0,
			mdiCloudUpload: mdiCloudUpload,
			mdiOfficeBuildingMarker: mdiOfficeBuildingMarker,
			...cadastro(),
			preSegmento: [],
			preCidade: [],
			preServicoIntegracao: [],
			carregandoCliente: false,
			clienteJuridico: true,
			procurandoInfo: false,
			apiAtiva: false,
			confirmacoesEntrega: "A",
			baseUri: "/administrativo/cadastros/clientes/",
			optSelectCidade: [],
			allModulos: [],
			modulosSelecionados: [],
			habilitaDriver: false,
			driverOpcoes: [false, false, false, false, false],
			// realizaColeta: false,
			// assinatura: false,
			appsEagle: [
				"Habilitar eagle driver?",
				"Utiliza assinatura eletrônica?",
				"Realiza coleta?",
			],
			// appsEagle2:[{text: "Habilitar eagle driver?",value:"habilita",checked:true}],
			// appsPerguntas:[
			//     { text: 'Utiliza assinatura eletrônica?', value: 'assinatura', disabled: true },
			//     { text: 'Realiza coleta?', value: 'coleta', disabled: true }],
			max: 2,
			ticket: false,
			posMapaI: {
				lat: -27.1,
				long: -52.6269,
			},
			pagina: 0,
			qtBntEmail: [{ value: "" }],
			qtBntTel: [{ value: "" }],
			//
			imageUrl: "",
			image: null,
			imageValue: "Nenhum arquivo selecionado",
			contratoClienteUrl: "",
			contratoCliente: null,
			contratoValue: "Nenhum arquivo selecionado",
			telefones: [],
			emails: [],
			valueSlide: 50,
			tipo: "password",
			icone: mdiEye,
			mdiEye: mdiEye,
			mdiEyeOff: mdiEyeOff,
			cliente: {
				clnome: '',
				clraio: '',
				cldocumento: '',
				clcidade: '',
				clsegmento: '',
				clveiculoscontratados: null,
				clfranquiamensalemail: null,
				clusuarioscontratados: null,
				clusuariosappcontratados: null,
				clcontratocliente: 'Nenhum arquivo selecionado',
			},
			adicionarSMTP: false,
			clienteSMTP: {
				cshost: '',
				csporta: null,
				csusuario: '',
				cssenha: '',
				csssl: false,
				cstipoconexao: '',
				csremetente: '',
				csremetenteticket: '',
				cslimiteemailporhora: null,
				cslimiteemailpormes: null,
			},
			labelsClienteSMTP: {
				csssl: 'Utiliza autenticação SSL',
				cshost: 'Host *',
				csporta: 'Porta *',
				csusuario: 'Usuário *',
				cssenha: 'Senha *',
				cstipoconexao: 'Tipo de Conexão *',
				csremetente: 'Remetente *',
				csremetenteticket: 'Remetente Ticket *',
				cslimiteemailporhora: 'Limite E-mail por Hora *',
				cslimiteemailpormes: 'Limite E-mail por Mês *',
			},
			clienteSMTPAux: {
				cshost: '',
				csporta: null,
				csusuario: '',
				cssenha: '',
				csssl: false,
				cstipoconexao: '',
				csremetente: '',
				csremetenteticket: '',
				cslimiteemailporhora: null,
				cslimiteemailpormes: null,
			},
			basePathSaveFiles: 'documentos/contratoClientes/',
			optSelectTipoConexao: [
				{
					value: 'SSL', description: 'SSL',
				},
				{
					value: 'TCP', description: 'TCP',
				},
				{
					value: 'TLS', description: 'TLS',
				}
			],
			tipoConexaoSelecionada: [{
				value: '',
				description: ''
			}],
			mdiDownloadOutline: mdiDownloadOutline,
			linkBackEnd: process.env.VUE_APP_ROOT
		};
	},

	validations: {
		cliente: {
			clnome: { required },
			cldocumento: { required },
			clcidade: { required },
			clsegmento: { required },
			clveiculoscontratados: { required },
			clusuarioscontratados: { required },
			clusuariosappcontratados: { required },
			
		},
		clienteSMTPAux: {
			cshost: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.cshost);
				}
				return true;
			},
			csporta: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.csporta);
				}
				return true;
			},
			csusuario: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.csusuario);
				}
				return true;
			},
			cssenha: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.cssenha);
				}
				return true;
			},
			cstipoconexao: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.cstipoconexao);
				}
				return true;
			},
			csremetente: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.csremetente);
				}
				return true;
			},
			csremetenteticket: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.csremetenteticket);
				}
				return true;
			},
			cslimiteemailporhora: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.cslimiteemailporhora);
				}
				return true;
			},
			cslimiteemailpormes: function () {
				if (this.adicionarSMTP) {
					return required(this.clienteSMTP.cslimiteemailpormes);
				}
				return true;
			}
		}
	},
	methods: {
		/**
		 * recebe um cnpj e faz uma consulta na api da receita
		 * pra completar os dados da empresa
		 * e atribuir pras variaveis
		 * @param { String } value - cpnj do brabo
		 */
		buscaCNPJ(value) {
			var cnpj = value.replace(/\D/g, "");
			if (cnpj.length !== 14) return;
			axios({
				url: `https://www.receitaws.com.br/v1/cnpj/${cnpj}`,
				adapter: jsonpAdapter,
			}).then(({ data }) => {
				if (data.fantasia != undefined) {
					this.cliente.clnome = data.nome;
					this.cliente.clfantasia = data.fantasia;
					this.cliente.cllogradouro = data.logradouro;
					this.cliente.clnumero = data.numero;
					this.cliente.clcomplemento = data.complemento;
					this.cliente.clbairro = data.bairro;
					this.cliente.classinaturadriver = false;
					this.cliente.clcoletadriver = false;
					var telefone = data.telefone.split("/ ");
					var email = data.email.split("/ ");
					this.qtBntEmail = email.map((v) => {
						return { value: v };
					});
					this.qtBntTel = telefone.map((v) => {
						return { value: v };
					});
					this.preCidade = this.optSelectCidade.filter(
						(v) =>
							v.description
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/gi, "")
								.toLowerCase() ===
							data.municipio
								.normalize("NFD")
								.replace(/[\u0300-\u036f]/gi, "")
								.toLowerCase()
					);
				}
			});
		},
		/**
	* faz um toogle no input de senha para
	* torna-la visivel ou em password de novo
	* @author Marcola
	*/
		trocaIcone(icone) {
			if (this.icone != this.mdiEyeOff) {
				this.icone = this.mdiEyeOff;
				this.tipo = "text";
			} else {
				this.icone = this.mdiEye;
				this.tipo = "password";
			}
		},

		changeCPFCNPJ(value) {
			if (
				this.clienteJuridico &&
				this.cliente.cldocumento != value &&
				validaCpfCnpj(value)
			) {
				this.buscaCNPJ(value);
			}
			this.cliente.cldocumento = value;
		},

		procuraEndereco() {
			this.posMapaI.lat = this.cliente.cllatitude;
			this.posMapaI.long = this.cliente.cllongitude;
		},

		mudaApiKey() {
			if (this.apiAtiva) {
				let uri = this.baseUri + "apioff";
				this.cliente.clapikey = "";
				this.apiAtiva = false;
				if (this.$route.params.id)
					new HttpRequest().Post(uri, { codigo: this.cliente.clcodigo });
			} else {
				this.apiAtiva = true;
				this.confirmaGerarChaveNova();
			}
		},

		/**
		 * Quando um arquivo é carregado para o input
		 * do tipo file, ele vai disparar esse evento.
		 * Ele "trata" o arquivo e deixa pronto para
		 * ser enviado para o back-end
		 */
		onFileLogo(event) {
			const files = event.target.files;
			this.imageValue = files[0].name;
			const fileReader = new FileReader();
			fileReader.addEventListener("load", () => {
				this.imageUrl = fileReader.result;
			});
			fileReader.readAsDataURL(files[0]);
			this.image = files[0];
		},

		/**
			 * Quando um arquivo é carregado para o input
			 * do tipo file, ele vai disparar esse evento.
			 * Ele "trata" o arquivo e deixa pronto para
			 * ser enviado para o back-end
			 */
		onFileContratoCliente(event) {
			const files = event.target.files;
			this.contratoValue = files[0].name;

			const fileReader = new FileReader();
			fileReader.addEventListener("load", () => {
				this.contratoClienteUrl = fileReader.result;
			});
			fileReader.readAsDataURL(files[0]);
			this.contratoCliente = files[0];
		},

		/**
		 * limpa as campos do ticket log na aba de
		 * integracao :)
		 */
		ticketLog() {
			if (!this.ticket) {
				Object.keys(this.clienteIntegracao).forEach(
					(i) => (this.clienteIntegracao[i] = "")
				);
				this.preServicoIntegracao = [];
			}
			return;
		},

		sslSelect() {
			if (!this.ticket) {
				Object.keys(this.clienteIntegracao).forEach(
					(i) => (this.clienteIntegracao[i] = "")
				);
				this.preServicoIntegracao = [];
			}
			return;
		},

		/**
		 * Quando o usuário clica no botão de upload, ele simula
		 * um click no input do tipo file que esta escondido
		 */
		onPickFileLogo() {
			this.$refs.fileInputLogo.click();
		},

		/**
		 * Quando o usuário clica no botão de upload, ele simula
		 * um click no input do tipo file que esta escondido
		 */
		onPickFileContratoCliente() {
			this.$refs.fileInputContratoCliente.click();
		},

		onDownloadContratoCliente(filePath) {
			const downloadLink = document.createElement("a");
			downloadLink.href = process.env.VUE_APP_ROOT + '/' + filePath;
			downloadLink.download = this.cliente.clnome + 'contratoCliente.pdf';
			downloadLink.target = '_blanck'
			downloadLink.click();
		},

		onDownloadLogoCliente(filePath) {
			const downloadLink = document.createElement("a");
			downloadLink.href = process.env.VUE_APP_ROOT + '/' + filePath;
			downloadLink.download = this.cliente.clnome + 'Logomarca.png';
			downloadLink.target = '_blanck'
			downloadLink.click();
		},

		alteraTelefones(arr) {
			this.telefones = arr;
		},

		alteraEmails(arr) {
			this.emails = arr;
		},

		alteraVeiculosContrado(v) {
			this.cliente.clveiculoscontratados = v;
		},

		mudaTipoCliente(tipo) {
			this.cliente.cldocumento = "";
			if (tipo[0] == "F") this.clienteJuridico = false;
			else this.clienteJuridico = true;
		},

		atualizaLatLongMarker(drag) {
			this.cliente.cllatitude = drag.latlng.lat;
			this.cliente.cllongitude = drag.latlng.lng;
		},

		copiarChave() {
			navigator.clipboard.writeText(this.cliente.clapikey);
			this.$bvToast.toast("Copiado Com sucesso!", {
				title: "Chave API",
				autoHideDelay: 2500,
				variant: "success",
			});
		},

		selectSegmento(arr) {
			this.cliente.clsegmento = arr[0];
		},

		atualizarChave() {
			this.$bvModal.show("gerarNovaChave");
		},

		confirmaGerarChaveNova() {
			this.$bvModal.hide("gerarNovaChave");
			this.carregandoCliente = true;
			let url = this.baseUri + "apikey";
			new HttpRequest()
				.Post(url, { codigo: this.cliente.clcodigo })
				.then((data) => {
					this.cliente.clapikey = data.data.key;
					this.carregandoCliente = false;
				});
		},

		carregaSegmento() {
			this.preSegmento = [
				this.optSelectSegmentoEmpresa.find((a) => {
					return a.value.toLowerCase() == this.cliente.clsegmento.toLowerCase();
				}),
			];
		},

		mudaCidade(arr) {
			this.cliente.clcidade = arr[0];
		},

		mudaTipoDeServico(arr) {
			this.clienteIntegracao.citipo = arr[0];
		},

		mudaTipoDeConexao(arr) {
			this.clienteSMTP.cstipoconexao = arr[0];
		},

		carregaCidade() {
			this.preCidade = [
				this.optSelectCidade.find((a) => {
					return a.value == this.cliente.clcidade;
				}),
			];
		},

		posicionaMapa() {
			if (this.cliente.cllatitude != null) {
				this.posMapaI.lat = this.cliente.cllatitude;
				this.posMapaI.long = this.cliente.cllongitude;
			}
		},

		salvarCliente() {
			let uri = this.baseUri;
			if (this.$route.params.id) uri += "update";
			else uri += "salvar";
			this.objSalvar.clienteSMTP.csporta = this.objSalvar.clienteSMTP.csporta > 0 ? parseInt(this.objSalvar.clienteSMTP.csporta) : null;
			this.objSalvar.clienteSMTP.cslimiteemailporhora = this.objSalvar.clienteSMTP.cslimiteemailporhora > 0 ? parseInt(this.objSalvar.clienteSMTP.cslimiteemailporhora) : null
			this.objSalvar.clienteSMTP.cslimiteemailpormes = this.objSalvar.clienteSMTP.cslimiteemailpormes > 0 ? parseInt(this.objSalvar.clienteSMTP.cslimiteemailpormes) : null;
			const date = new Date();

			if (this.objSalvar.cliente.cliniciovigencia.length == 0) {
				let mes = date.getUTCMonth() + 1;
				if (mes < 10) {
					mes = '0' + mes;
				}

				const today = date.getDate() + '/' + mes + '/' + date.getFullYear();
				this.objSalvar.cliente.cliniciovigencia = today;
			}

			this.carregandoCliente = true;
			new HttpRequest().Post(uri, this.objSalvar).then(({ data }) => {
				this.carregandoCliente = false;
				if (data.success) {
					// new EmpresasService().Refresh();
					this.$router.push({ name: "listarClientes" });
				} else if (data.cnpjDupli) {
					let tipo = this.clienteJuridico ? "CNPJ" : "CPF";
					conectionError(`${tipo} já cadastrado!`);
					this.carregandoCliente = false;
				} else {
					this.carregandoCliente = false;
					conectionError();
				}
			});
		},

		/**
		 * Resolve pelo número de chaves da integração caso
		 * a empresa possua alguma, se sim, iguala as chaves que
		 * existem e já deixa prepara os campos
		 */
		carregaIntegracao(integracoes) {
			if (Object.keys(integracoes).length) {
				this.ticket = true;
				Object.keys(integracoes).forEach((i) => {
					if (integracoes[i]) this.clienteIntegracao[i] = integracoes[i];
				});
				if (this.clienteIntegracao.citipo) {
					this.preServicoIntegracao = [
						this.optSelectServicoIntegracao.find((o) => {
							return o.value == this.clienteIntegracao.citipo;
						}),
					];
				}
			}
		},

		carregaEmails(emails) {
			if (!emails.length) return;
			this.qtBntEmail = [];
			emails.forEach((e) => {
				let mail = {
					value: e.ememail,
					codigo: e.emcodigo,
				};
				this.qtBntEmail.push(mail);
			});
		},

		carregaTelefone(telefones) {
			if (!telefones.length) return;
			this.qtBntTel = [];
			telefones.forEach((t) => {
				let phone = {
					value: t.tlnumero,
					codigo: t.tlcodigo,
				};
				this.qtBntTel.push(phone);
			});
		},

		habilita() {
			if (!this.habilitaDriver) {
				this.driverOpcoes = this.driverOpcoes.map((v) => (v = false));
			}
		},

		limpaTudo() {
			Object.keys(this.cliente).forEach((k) => (this.cliente[k] = ""));
			Object.keys(this.clienteIntegracao).forEach(
				(k) => (this.clienteIntegracao[k] = "")
			);

			//Adicionado para controle do raio no mapa.
			this.cliente.clraio = "350";
			this.valueSlide = "350";

			this.cliente.cllongitude = -52.6269;
			this.cliente.cllatitude = -27.1;
		},

		/**
		 * Caso seja uma edição, essa função é chamada
		 * para carregar as informações do cliente
		 */
		carregaCliente(codigo) {
			this.carregandoCliente = true;
			let uri = this.baseUri + "editar";
			new HttpRequest().Post(uri, { codigo: codigo }).then(({ data }) => {
				/**Carrega as opções da página */
				this.allModulos = data.modulos;
				this.optSelectCidade = data.cidades;
				this.appsEagle = data.apps;
				/**Carrega as informações do cliente */
				if (data.clienteSMTP.length > 0) {
					this.clienteSMTP = data.clienteSMTP[0];
					this.clienteSMTPAux = data.clienteSMTP[0];
					if (data.clienteSMTP[0].cshost) {
						this.adicionarSMTP = true;
						this.tipoConexaoSelecionada = [{
							value: data.clienteSMTP[0].cstipoconexao,
							description: data.clienteSMTP[0].cstipoconexao
						}];
					}
				}
				Object.keys(data.cliente).forEach((k) => {
					let clientsField = k != "email" && k != "telefones";
					if (data.cliente[k] && clientsField)
						this.cliente[k] = data.cliente[k];
				});
				if (this.cliente.clapikey) this.apiAtiva = true;

				/**Alterna entre pessoa física/juridica */
				if (this.cliente.cltipo == "F") {
					this.clienteJuridico = false;
					this.buttonsFiltroTipoCliente[0].isSelected = true;
					this.buttonsFiltroTipoCliente[1].isSelected = false;
				} else {
					this.clienteJuridico = true;
					this.buttonsFiltroTipoCliente[0].isSelected = false;
					this.buttonsFiltroTipoCliente[1].isSelected = true;
				}
				/**Selecina as opções que correspondem com o cliente */
				this.modulosSelecionados = data.modSisCliente;
				this.driverOpcoes = [
					data.cliente.classinaturadriver,
					data.cliente.clcoletadriver,
					data.cliente.climprimirticketdriver,
					data.cliente.clbloqueioalteracaostatusdriver,
					data.cliente.clrealizatransbordodriver,
				];
				// this.driverOpcoes[0] || this.driverOpcoes[1]
				if (this.driverOpcoes.find((v) => true)) {
					this.habilitaDriver = !this.habilitaDriver;
				}

				if (this.cliente.clraio) {
					this.valueSlide = this.cliente.clraio;
					// this.$refs.sliderRaio.value = this.cliente.clraio
				}
				this.carregaEmails(data.cliente.email);
				this.carregaTelefone(data.cliente.telefones);
				this.posicionaMapa();
				this.carregaSegmento();
				this.carregaCidade();
				this.carregaIntegracao(data.integracoes);
				this.carregandoCliente = false;
			});
		},

		...mapActions(["updatePermissions"]),
	},

	/**
	 * Puxando algumas das informações necessárias
	 * pra completar os requerimentos, busca pelos módulos
	 * (para criar a lista no menu de módulos) e também
	 * busca pelas cidades(CI)(para ser utilizado no dropdown de cidade)
	 */
	mounted() {
		this.updatePermissions();
		if (this.$route.params.id) {
			this.carregaCliente(this.$route.params.id);
		} else {
			let uri = this.baseUri + "modulos";
			this.limpaTudo();
			new HttpRequest().Get(uri).then((data) => {
				this.allModulos = data.data.modulos;
				this.optSelectCidade = data.data.cidades;
			});
		}
		this.$refs.mapaSimples.validateSize();
	},

	computed: {
		docValido() {
			var cnpj = this.cliente.cldocumento;
			return validaCpfCnpj(cnpj);
		},

		objSalvar() {
			var cliente = this.cliente;
			var clienteSMTP = this.clienteSMTP;

			if (cliente.clcodigo == "") delete cliente.clcodigo;
			if (cliente.clfantasia == "") cliente.clfantasia = cliente.clnome;
			cliente.classinaturadriver = this.driverOpcoes[0];
			cliente.clcoletadriver = this.driverOpcoes[1];
			cliente.climprimirticketdriver = this.driverOpcoes[2];
			cliente.clbloqueioalteracaostatusdriver = this.driverOpcoes[3];
			cliente.clrealizatransbordodriver = this.driverOpcoes[4];
			var obj = {
				cliente: { ...cliente, cltipo: this.clienteJuridico ? "J" : "F" },
				integracao: this.clienteIntegracao,
				modulos: this.modulosSelecionados,
				apps: this.driverOpcoes,
				logo: this.imageUrl,
				contratoCliente: this.contratoClienteUrl,
				emails: this.qtBntEmail,
				telefones: this.qtBntTel,
				clienteSMTP,
			};
			return obj;
		},
	},

	watch: {
		adicionarSMTP: function (statusAdicionarSMTP) {
			if (statusAdicionarSMTP) {
				if (this.clienteSMTPAux.csporta > 0) {
					this.clienteSMTP = this.clienteSMTPAux;
				}
			} else {
				if (this.clienteSMTPAux.csporta < 0) {
					this.clienteSMTPAux = this.clienteSMTP;
				}
				this.clienteSMTP = {
					cshost: '',
					csporta: null,
					csusuario: '',
					cssenha: '',
					csssl: false,
					cstipoconexao: '',
					csremetente: '',
					csremetenteticket: '',
					cslimiteemailporhora: null,
					cslimiteemailpormes: null,
				};
			}
		}
	}
});
</script>

<style lang="scss" scoped>
.prospeccao {
	text-align: left !important;
	margin-top: 2.2%;
	font-size: 14px;
}

.inputLogin {
	background: transparent;
	border: 1px solid #0f4352;
	border-radius: 0px;
	margin-top: 10px;
	color: #fff;
}

.iconeOlhinho {
	cursor: pointer;
	position: relative;
	justify-content: center;
	left: 43%;
	margin-right: 6px;
	bottom: 30px;
}

.labelMapa {
	text-align: left !important;
	vertical-align: bottom;
	font-size: 13px;
	padding: 0 !important;
}

.labelMapa::after {
	content: ":";
}

.tituloConfig {
	text-align: left !important;
	padding: 0 !important;
	font-size: 20px;
}

.buttonConfig {
	text-align: left !important;
	padding-left: 0 !important;
}

.centraliza {
	text-align: center !important;
}

table,
th,
td {
	border: 1px solid #dddddd;
	font-size: 12px;
}

th {
	background-color: #f5f5f5;
}

.alturaBotoes {
	height: 37px !important;
}

.divApi {
	display: flex;
	padding-left: 5px;
	padding-bottom: 5px;
}

.labelKey {
	padding-left: 5px;
	padding-top: 6px;
	text-align: left !important;
	font-size: 14px;
	font-weight: bolder;
}

.botao {
	border: none;
	text-align: center;
	font-size: 13px;
	width: 10%;
}

.APICopiar {
	background-color: #fe970a;
	padding: 10px;
	text-align: center;
	color: white;
}

.APIAtt {
	padding: 10px;
	width: 175px !important;
	background-color: #1caf9a;
}

.inputApi {
	font-size: 11.4px !important;
}

.icone::before {
	margin: 0 !important;
	padding: 0 !important;
}

.botaoUpload {
	min-width: 25px;
	background-color: #428bca;
	border: none;
}

.divUpload {
	display: flex;
	padding: 0 !important;
	margin: 0 !important;
}

.subtitle {
	font-size: 13px;
	text-align: left;
	margin-bottom: 2px;
}
</style>