<template>
	<panelEagle id='RegistrarAbastecimento' :loading='carregando'>
		<div class="col-sm-12 nopadding row">
			<div class="col-sm-6">
				<tituloPage :icon='mdiCogSync' titulo='Manutenção' />
			</div>
			<div class="col-sm-6 col-md-6 col-lg-6">
				<basicButtonsCadastrosSC :disabled='$v.$invalid' tipoCancela='button' linkCancel='listarManutencao'
					@salvarCadastro='salvarCadastro' />
			</div>
		</div>

		<div class="col-sm-12 col-md-12">
			<hr>
		</div>

		<div class="col-sm-12 row nopadding">
			<div class="col-xl-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<selectAll :extraClass='{
		"border border-danger":
			$v.valueEmpresa.$anyError
	}' @close='$v.valueEmpresa.$touch()' nameForRadio='Empresas' :isMultiple='false' :optionsArray='optEmpresas'
					:labels='labelsEmp' :hasSelectAll='false' @changeSelect='changeEmpresa' :selected='selectedEmpre' />
			</div>
			<div class="col-xl-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<inputSimple :inputClass='{
		"border border-danger":
			$v.valueDescricao.$anyError
	}' @blur='$v.valueDescricao.$touch()' name='desc' label='Descrição*' :value='valueDescricao'
					@changeInput='changeDescricao' />
			</div>
			<div class="col-xl-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<selectAll @close='$v.valueVeiculo.$touch()' :optionsArray='optVeiculos' :extraClass='{
		"border border-danger":
			$v.valueVeiculo.$anyError
	}' ref='refSelectVeiculo' nameForRadio='Veiculo' :disabled='!optVeiculos.length' :isMultiple='false'
					:labels='labelsVei' :loading='buscandoVeiculos' :hasSelectAll='false' @changeSelect='changeVeiculo'
					:selected='selectedVeicu' />
			</div>
			<div class="col-xl-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<selectAll :extraClass='{
		"border border-danger":
			$v.valueManutencao.$anyError
	}' @close='$v.valueManutencao.$touch()' nameForRadio='TipoDEmanutenção' :isMultiple='false'
					:disabled='!optManutencao.length' :optionsArray='optManutencao' :labels='labelsTDM'
					:hasSelectAll='false' @changeSelect='changeManutencao' :selected='selectedManut' />
			</div>
			<div class="col-xl-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<selectAll ref='refApuracao' :disabled='disabledApura' :extraClass='{
		"border border-danger":
			$v.valueApuracao.$anyError
	}' @close='$v.valueApuracao.$touch()' nameForRadio='Tipo de apuração' :isMultiple='false' :optionsArray='optApuracao'
					:labels='labelsTDP' :hasSelectAll='false' @changeSelect='changeApuracao'
					:selected='selectedApura' />
			</div>
			<div class="col-lg-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<inputSimple :value='valueIntervalo' :disabled="valueManutencao[0] != 1" name='int' label='Intervalo*'
					:hasMask="true" :mask="['NNNNNNNNN']" @changeInput='changeIntervalo' />
			</div>

			<div class="col-lg-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<inputSimple name='nos' label='Atual' id="atualVeiculo" :disabled="valueApuracao[0] == 3"
					:value='valueAtual' @changeInput='changeAtual' />
			</div>

			<b-popover target="atualVeiculo" :show.sync="popover" placement="top" ref="popover">
				<div class="col-sm-12 nopadding">
					Deseja atualizar o {{ baseCalculoMedia == 1 ? 'hodômetro' : baseCalculoMedia == 2 ? ('horímetro') :
		(baseCalculoMedia == 3 ? ('Dias') : ('')) }}
					do veículo com base nessa informação?
					<div class="col-sm-12 row mt-2 mb-2">
						<div class="col-sm-6 nopadding">
							<simpleButton type="red" event="click" text="Não" @click="popover = false" />
						</div>
						<div class="col-sm-6 nopadding" align="right">
							<simpleButton type="blue" event="click" text="Sim" @click="atualizarAtualVeiculo()" />
						</div>
					</div>
				</div>
			</b-popover>

			<div class="col-lg-3 col-md-6 marg-top-1px marg-bot-1px nopadding">
				<inputSimple name='nos' label='Programado' :value='valueProgramado' :disabled="valueManutencao[0] != 1"
					@changeInput='changeProgramado' />
			</div>

			<div class="col-xl-6 col-md-6 mt-3 nopadding">
				<selectAll ref='refServicos' :disabled='!optVeiculos.length' nameForRadio='Serviços' :isMultiple='false'
					:optionsArray='optServicos' :labels='labelsServico' :hasSelectAll='false' :loading='loadingServicos'
					@changeSelect='changeServicos' />
			</div>

			<div class="col-xl-3 col-md-6 mt-3 nopadding">
				<selectAll ref='refLocal' :disabled='!optVeiculos.length' nameForRadio='Local' :isMultiple='false'
					:optionsArray='optLocal' :labels='labelsLocal' :hasSelectAll='false' :loading="loadingServicos"
					@changeSelect='changeLocal' :selected='selectedLocal' />
			</div>
			<div class="col-lg-3 col-md-6 mt-3 nopadding">
				<inputSimple name='nos' label='Nº da ordem de serviço' :value='valueNos' type="number"
					@changeInput='changeNos' />
			</div>
			<div class='col-sm-6 row'>
				<div class='col-sm-12 nopadding'>
					<div class='col-sm-12 d-flex cabecalhoListaItens'>
						<div class='col-sm-9' align='left'>Itens</div>
						<div class='col-sm-3 pr-0' align='right'>Remover</div>
					</div>
					<div class='col-sm-12 divListaItens nopadding'>
						<div v-for='(itens, i) in servicosSelecionados' :key="i + '1'"
							class='col-sm-12 tdColaboradoresBanco d-flex justify-content-start'>
							<div class="col-sm-9" align='left'>{{ itens.nome }}</div>
							<div class="col-sm-3 retirarItem p-0" align='right' @click="deletarItem(i)">
								<baseIcon class="iconRetirar" :icon='mdiCloseThick' size='20' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-6 mt-3 nopadding pl-3">
				<div class="col-sm-12 pl-3 subtitle">Observações</div>
				<textarea v-model="valueObservacoes" name="textManutencao" class="textArea" />
			</div>
		</div>
		<required />
		<div class="col-sm-12 col-md-12">
			<hr>
		</div>
	</panelEagle>
</template>
<script>
import { mdiCogSync, mdiCloseThick } from '@mdi/js'
import Vue from 'vue'
import Vuex from '@/store/index'
import { FiltrosService } from '@/Services/filtros/filtros.Service'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { EmpresasService } from '@/Services/auth/Empresas.service'
import { HttpRequest } from '@/Services/auth/HttpRequest.Service.ts'
import { conectionError } from '@/Services/Helpers/swellHeper.js'
import { DateTime } from 'luxon'

export default Vue.extend({
	name: 'editarCadastroDeManutencao',
	components: {
		tituloPage: require('@/components/Atom/Header/Titulo').default,
		selectAll: require('@/components/Atom/Select/SelectAll').default,
		panelEagle: require('@/components/Atom/Panel/PanelEagle').default,
		inputSimple: require('@/components/Atom/Inputs/InputSimple').default,
		required: require('@/components/Atom/Footer/RequiredFields').default,
		basicButtonsCadastrosSC: require('@/components/Atom/Buttons/BasicButtonsCadastrosSC').default,
		baseIcon: require('@/components/Atom/Icon/BaseIcon').default,
		simpleButton: require('@/components/Atom/Buttons/SimpleButton').default,
	},
	validations: {
		valueEmpresa: { required },
		valueVeiculo: { required },
		valueApuracao: { required },
		valueDescricao: { required },
		valueManutencao: { required },
		servicosSelecionados: { required },
		valueIntervalo: {
			required: requiredIf(function () {
				if (this.valueManutencao[0] !== 1) {
					return false
				}
				return true
			}),
		}
	},
	data() {
		return {
			mdiCogSync: mdiCogSync,
			mdiCloseThick: mdiCloseThick,
			buscandoVeiculos: false,
			disabledApura: false,
			carregando: false,
			id: null,
			copiar: null,
			refSelectVeiculo: '',
			refInputTextArea: '',
			optEmpresas: new EmpresasService().Get(),
			labelsEmp: [{
				indexDFH: 'EM',
				description: 'Empresa*'
			}],
			optVeiculos: [],
			labelsVei: [{
				indexDFH: 'VE',
				description: 'Veiculo*'
			}],
			optManutencao: [],
			labelsTDM: [{
				indexDFH: 'TDM',
				description: 'Tipo de manutenção*'
			}],
			optApuracao: [],
			labelsTDP: [{
				indexDFH: 'TDP',
				description: 'Tipo de apuração*'
			}],
			labelsServico: [{
				indexDFH: 'S',
				description: 'Serviços*'
			}],
			labelsLocal: [{
				indexDFH: 'L',
				description: 'Local'
			}],
			valor: '',
			selectedApura: [],
			selectedManut: [],
			selectedEmpre: [],
			selectedVeicu: [],
			/////// values //////  /
			valueEmpresa: [],
			valueVeiculo: [],
			valueNos: '',
			valueApuracao: '',
			valueDescricao: '',
			valueIntervalo: '',
			valueManutencao: '',
			valueObservacoes: '',
			servicosSelecionados: [],
			optServicos: [],
			selectedServicos: [],
			loadingServicos: false,
			osSelecionado: '',
			optLocal: [],
			selectedLocal: [],
			localSelecionado: [],
			valueAtual: '',
			valueProgramado: '',
			popover: false,
			can: false,
			baseCalculoMedia: 1,
			editarCampo: true,
		}
	},
	methods: {
		async salvarCadastro() {
			this.carregando = true
			var obj = {
				'valueEmpresa': this.valueEmpresa,
				'valueVeiculo': this.valueVeiculo,
				'valueManutencao': this.valueManutencao,
				'valueApuracao': this.valueApuracao,
				'valueDescricao': this.valueDescricao,
				'valueNos': this.valueNos,
				'valueIntervalo': this.valueIntervalo,
				'valueObservacoes': this.valueObservacoes,
				'id': this.id,
				'copiar': this.copiar,
				'atual': this.valueAtual,
				'programado': this.valueProgramado,
				'os': this.osSelecionado,
				'servicos': this.servicosSelecionados,
				'local': this.localSelecionado,
			}
			let uri = '/manutencoes/cadastros/manutencao/editar/salvar'
			new HttpRequest().Post(uri, { 'obj': obj })
				.then((data) => {
					if (data.code === 201) {
						this.$router.push({ name: 'listarManutencao' })
						this.carregando = false
					} else {
						conectionError()
						this.carregando = false
					}
				})
		},

		limpaCampos() {
			this.optVeiculos = []
			this.$refs.refSelectVeiculo.clearAll()
			this.$refs.refLocal.clearAll()
			this.$refs.refServicos.clearAll()
			this.servicosSelecionados = []
			this.valueAtual = ''
			this.valueIntervalo = ''
			this.valueProgramado = ''
			this.valueNos = ''
			this.valueObservacoes = ''
			this.valueDescricao = ''
		},

		async changeEmpresa(value) {
			this.valueEmpresa = value
			if (!this.carregando)
				this.servicosSelecionados = [];
			this.$refs.refSelectVeiculo.clearAll()
			this.$refs.refLocal.clearAll()
			let uri = '/manutencoes/cadastros/manutencao/editar/veiculos'
			if (value.length) {
				this.buscandoVeiculos = true
				new FiltrosService().dados_filtros(value, ['V', 'P'])
					.then((data) => {
						this.optVeiculos = data.V
						this.buscandoVeiculos = false
					})
				this.buscaServicos(value)
			} else {
				this.limpaCampos()
			}
		},

		changeVeiculo(value) {
			this.valueVeiculo = value
			if (value.length > 0)
				this.buscaKmAtual(value)
		},

		changeManutencao(value) {
			this.valueManutencao = value
			if (Number(value[0]) !== 1) {
				this.valueIntervalo = ''
				this.valueAtual = ''
			}
			if (value === 3) {
				this.selectedApura = [{ 'value': '3', 'description': 'Dias' }]
				this.valueApuracao = '3'
				this.disabledApura = true
			} else {
				this.disabledApura = false
			}
			if (this.valueVeiculo.length > 0) {
				this.buscaKmAtual(this.valueVeiculo)
			}
		},

		changeApuracao(value) {
			this.valueApuracao = value;
			this.baseCalculoMedia = value;

			if(this.valueApuracao[0] == 3){
				this.popover = false
                this.valueProgramado = this.calcularDataProgramada(this.valueIntervalo || 0);
			} else {
				this.valueProgramado = Number(this.valueIntervalo) + Number(this.valueAtual)
			}

			if (value === 1) {
				this.valor = 'Quilometragem(Km)*'
			}
			else if (value === 2) {
				this.valor = 'Horimetro(H)*'
			}
			else if (value === 3) {
				this.valor = 'Dias*'
			}
			else {
				this.valor = ''
			}
			if (this.valueVeiculo.length > 0) {
				this.buscaKmAtual(this.valueVeiculo)
			}
		},

		changeDescricao(value) {
			this.valueDescricao = value
		},

		changeNos(value) {
			this.valueNos = value
		},

		/**
		Atualiza o valor do intervalo e calcula o valor programado com base no tipo de apuração.
		@param {number} value - O novo valor do intervalo.
		@description Esta função atualiza o valor do intervalo com base no parâmetro fornecido.
		Em seguida, verifica se o tipo de apuração é igual a 3 (tipo específico).
		Se for, calcula a data programada adicionando o intervalo de dias à data atual.
		Caso contrário, se 'editarCampo' for verdadeiro, calcula o valor programado somando o intervalo ao valor atual.
		@author Desconhecido e Alterado por Yuri 🇧🇷
		*/
		changeIntervalo(value) {
			this.valueIntervalo = value;

			if(this.valueApuracao[0] == 3){
				this.popover = false
                this.valueProgramado = this.calcularDataProgramada(this.valueIntervalo || 0);
			} else {
				this.valueProgramado = Number(this.valueIntervalo) + Number(this.valueAtual)
			}
		},

		changeAtual(atual) {
			this.valueAtual = atual;
			this.popover = true;

			if(this.valueApuracao[0] == 3){
				this.popover = false
                this.valueProgramado = this.calcularDataProgramada(this.valueIntervalo || 0);
			} else {
				this.valueProgramado = Number(this.valueIntervalo) + Number(this.valueAtual)
			}
		},

		/**
		Atualiza o valor atual de um veículo.
		@description Esta função atualiza o hodometro ou horimetro atual de um veículo.
		Após atualizar chama a função this.buscaKmAtual() para atualizar o hodometro ou horimetro
		do veículo em tela para o usuário.
		@author Yuri 🇧🇷
		*/
		atualizarAtualVeiculo() {
			this.carregando = true;
			const obj = {
				"veiculo": parseInt(this.valueVeiculo[0]),
				"tipoApuracao": parseInt(this.baseCalculoMedia[0]),
				"atual": parseFloat(this.valueAtual)
			}
			
			let uri = '/manutencoes/cadastros/manutencao/editar/atualizar/atual';

			new HttpRequest().Post(uri, obj)
				.then((dados) => {
					this.buscaKmAtual(this.valueVeiculo, true)
				}).finally(() => {
					this.carregando = false;
					this.popover = false;
				})
		},

		changeProgramado(programado) {
			this.valueProgramado = programado
		},

		changeOs(os) {
			this.osSelecionado = os
		},

		changeLocal(local) {
			this.localSelecionado = local
		},

		changeServicos(x1, codigo, item, x3) {
			if (codigo !== '' && item !== '') {
				var index = this.servicosSelecionados.findIndex(el => {
					return el.codigo === codigo
				})
				var obj = {
					'codigo': codigo,
					'nome': item
				}
				if (index !== -1)
					this.deletarItem(index)
				this.servicosSelecionados.push(obj)
			}
		},

		/**
		 * @description Método para deletar item 
		 *  da array servicosSelecionados
		 * @param  {number} index - posição do elemento na array
		 * @author Vitor Hugo 🐨
		*/
		deletarItem(index) {
			this.servicosSelecionados.splice(index, 1)
		},

		/**
		 * @description Método para buscar os serviços e
		 *  os pontos da manutenção
		 * @param  {array} empresa - código da empresa
		 * @author Vitor Hugo 🐨
		*/
		buscaServicos(empresa) {
			this.loadingServicos = true
			let uri = '/manutencoes/cadastros/manutencao/editar/servico'
			new HttpRequest().Post(uri, { 'empresa': empresa })
				.then((dados) => {
					this.optServicos = dados.data.servico
					this.optLocal = dados.data.pontos
					this.loadingServicos = false
				})
		},

		/**
		 * @description Método para buscar o hodômetro ou
		 *  horímetro atual do veículo
		 * @param  {array} veiculo - código do veículo
		 * @author Vitor Hugo 🐨
		*/
		buscaKmAtual(veiculo, manterAtual = false) {
			if (this.valueApuracao[0] != 3) {
				if (this.valueApuracao.length > 0 && veiculo != null) {
					let uri = '/manutencoes/cadastros/manutencao/editar/atual'
					new HttpRequest().Post(
						uri,
						{ 'veiculo': veiculo, 'apuracao': this.valueApuracao })
						.then((dados) => {
							var existem_dados = dados.data.atual.length !== 0
							var outra_condicao = Number(this.valueManutencao[0]) !== 2;

							if (manterAtual) {
								outra_condicao = true;
							}

							if (existem_dados && outra_condicao) {
								this.valueAtual = dados.data.atual[0].km
								var va = Number(this.valueAtual)
								var vi = Number(this.valueIntervalo)
								if (this.editarCampo) this.valueProgramado = va + vi
								this.$nextTick(() => {
									this.editarCampo = true
								})
							} else {
								this.valueAtual = ''
							}
						})
				}
			} else {
				this.valueAtual = '';
			}
		},

		/**
		 * Função para manipular a soma data para o campo programado.
		 * @description retorna a soma da data atual + o parametro da funcao usando Luxon.
		 * @param valor int 
		 * @returns retorna a data formatada dd/mm/yyyy 
		 * @author VeCo® 🔱
		 */
		calcularDataProgramada(intervaloDias) {
			const dataProgramada = DateTime.now().plus({ days: intervaloDias });
			const dataFormatada = dataProgramada.toFormat('dd/MM/yyyy');
			return dataFormatada;
		},
	},
	async mounted() {
		this.selectedApura.push({ 'value': '1', 'description': 'Quilometragem' })
		this.selectedManut.push({ 'value': 1, 'description': 'Preventiva' })
		if (this.$route.params.id) this.id = this.$route.params.id;
		if (this.$route.params.cp) this.copiar = this.$route.params.cp;
		var dadosSelect = await new HttpRequest()
			.Post('/manutencoes/cadastros/manutencao/editar/select', { 'obj': this.id })
		this.optManutencao = dadosSelect.data.manutencao
		this.optApuracao = dadosSelect.data.apuracao
		if (this.id === undefined) {
			this.selectedApura = [{ 'value': 1, 'description': 'Quilometragem' }]
			this.selectedManut = [{ 'value': 1, 'description': 'Preventiva' }]
		} else {
			if (this.id !== null) {
				this.carregando = true
				var url = '/manutencoes/cadastros/manutencao/editar/copiar'
				new HttpRequest().Post(url, { obj: this.id })
					.then((res) => {
						// sim isso precisa ser feito pq quando troca o valor do input ele emite o evento
						// mesmo que nao seja o usuario que fez
						this.editarCampo = false
						this.selectedApura = [
							{
								'value': res.data.manutencao[0]['mcapuracao'] + '',
								'description': res.data.manutencao[0]['mtadescricao']
							}
						]
						this.selectedManut = [
							{
								'value': res.data.manutencao[0]['mctipomanutencao'] + '',
								'description': res.data.manutencao[0]['mtidescricao']
							}
						]
						this.selectedEmpre = [
							{
								'value': res.data.manutencao[0]['clcodigo'],
								'description': res.data.manutencao[0]['clfantasia']
							}
						]
						var des2 = res.data.manutencao[0]['veprefixo']
						var description = res.data.manutencao[0]['veplaca'] + ' | ' + des2
						this.selectedVeicu = [
							{
								'value': res.data.manutencao[0]['vecodigo'],
								'description': description
							}
						]
						var valueObservacoes = res.data.manutencao[0]['mcobservacao'] ?? ''
						this.valueNos = res.data.manutencao[0]['mcordemservico'] + ''
						this.valueDescricao = res.data.manutencao[0]['mcdescricao']
						this.valueObservacoes = valueObservacoes
						this.servicosSelecionados = res.data.servicos
						this.valueApuracao = Number(res.data.manutencao[0]['mcapuracao'])
						this.valueIntervalo = this.valueApuracao === 1 ? res.data.manutencao[0]['mcintervalo'] / 1000 : res.data.manutencao[0]['mcintervalo']
						if (this.valueApuracao === 1) {
							this.valueProgramado = res.data.manutencao[0].mcprogramado / 1000
						}

						this.valueProgramado = res.data.manutencao[0].mcprogramado


						if (res.data.manutencao[0]['pocodigo'] != null) {
							this.selectedLocal = [
								{
									'value': res.data.manutencao[0]['pocodigo'],
									'description': res.data.manutencao[0]['podescricao']
								}
							]
						}
						if (this.copiar) {
							this.valueDescricao = this.valueDescricao + ' - CÓPIA'
						}
					}).finally(() => {
						this.carregando = false
					})
			}
		}
	},
})
</script>
<style lang="scss" scoped>
.cor1 {
	color: rgb(255, 255, 255);
	padding-bottom: 0px;
	background-color: #F0AD4E;
	margin: 1px;
}

.padTop {
	padding-top: 5px;
	padding-bottom: 0px;
	height: 30px;
}

.alinha-text-rig {
	text-align: right;
	margin-left: 0px;
}

.alinha-text-lef {
	text-align: left;
	margin-right: 0px;
}

.cor2 {
	color: rgb(255, 255, 255);
	padding-bottom: 0px;
	margin: 1px;
	margin-top: 5px;
	margin-bottom: 5px;
	background: #E04B4A;
}

.marg-top-1px {
	margin-top: 2px;
}

.marg-bot-1px {
	margin-bottom: 3px;
}

.cabecalhoListaItens {
	background: #cccccc;
	height: 25px;

	div {
		padding-top: 4px;
	}
}

.divListaItens {
	min-height: 340px;
	max-height: 340px;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	border: 1px solid #dcdbdb !important;
	overflow: scroll;
	text-overflow: clip;
	overflow-x: auto;
	vertical-align: middle;

	.tdColaboradoresBanco:hover {
		// cursor: pointer !important;
		background: #f3efef;
	}

	.tdColaboradoresBanco {
		padding: 5px;
		height: 10%;
		white-space: nowrap;
		border: none !important;
		padding-left: 10px;
	}
}

.retirarItem {
	text-align: right;
	vertical-align: middle;

	.iconRetirar:hover {
		cursor: pointer;
		font-weight: bold;
		color: #585858;
	}
}

.textArea {
	width: 95%;
	min-height: 200px;
	border: 1px solid #d1d6dc;
}
</style>
