<template>
    <panelEagle id="monitoramentoLinhasView" :loading="loadingPanel">
        <div class="col-sm-12 nopadding divDontPrint">
            <div class="col-sm-12 nopadding row">
                <div class="col-sm-6">
                    <tituloPage :icon="mdiTelevisionGuide" titulo="Monitoramento linhas">
                    </tituloPage>
                </div>

                <div class="col-sm-6">
                    <buttonExpand>
                        <simpleButton id="aplicarFiltros" width="120px" :icon="mdiPlus" type="orange"
                            text="Aplicar filtros" title="Aplicar filtros">
                        </simpleButton>

                        <simpleButton id="popoverInfo" width="120px" :icon="mdiAlertCircleOutline" type="blue"
                            text="Legenda" title="Legenda">
                        </simpleButton>
                    </buttonExpand>
                </div>
            </div>

            <!-- Legnda -->
            <div>
                <b-popover target="popoverInfo" triggers="hover click" placement="auto" ref="popover">
                    <div class="col-sm-12 nopadding row">
                        <div class="col-sm-12 nopadding custom-break-line">
                            <subtitle :items="itemsSubtitleRota" title="Rota">
                            </subtitle>
                            <subtitle :items="itemsSubtitlePontos" title="Pontos">
                            </subtitle>
                        </div>
                    </div>
                </b-popover>
            </div>

            <!-- Filtros -->
            <div>
                <b-popover id="popoverFiltros" target="aplicarFiltros" triggers="hover click"
                    :show.sync="popoverFiltrosShow" placement="top" ref="popover" @hidden="onHidden">
                    <div class="col-sm-12 row nopadding">
                        <div class="col-sm-12 nopadding">
                            <!-- Input Data -->
                            <div class="mb-3">
                                <inputSingleDay @changeInput="changeData" name="dataCancelamentos" :isObrigatorio="true"
                                    :value="dataSelecionada">
                                </inputSingleDay>
                            </div>

                            <!-- Input Empresas -->
                            <div class="mb-3">
                                <selectAll @changeSelect="changeEmpresa" :isMultiple="true"
                                    nameForRadio="empresasCancelamento" :hasSelectAll="true"
                                    :labels="[{ indexDFH: 'EM', description: 'Empresas*' }]"
                                    :optionsArray="optionsEmpresas" :selected="popoverData.empresas" firstSelected="EM">
                                </selectAll>
                            </div>

                            <!-- Inputs Veículos | Grupos -->
                            <div class="mb-3">
                                <div class="col-sm-12 nopadding">
                                    <div class="d-flex justify-content-start" style="margin-top: -5px;">
                                        <!-- Radio de Veículos -->
                                        <div class="d-flex justify-content-start ml-5">
                                            <input style="cursor: pointer;" class="form-check-input" type="radio"
                                                name="veiculoGrupoTag" id="radioVeiculo" v-model="selectedRadio"
                                                :value="1" @change="selecionaTipo(1)">
                                            <label class="select-none" for="radioVeiculo"
                                                style="cursor: pointer">Veículos</label>
                                        </div>

                                        <!-- Radio de Grupos -->
                                        <div class="d-flex justify-content-start ml-5">
                                            <input style="cursor: pointer;" class="form-check-input" type="radio"
                                                name="veiculoGrupoTag" id="radioGrupo" v-model="selectedRadio"
                                                :value="2" @change="selecionaTipo(2)">
                                            <label class="select-none" for="radioGrupo"
                                                style="cursor: pointer">Grupos</label>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 row nopadding" style="margin-top: -2px;">
                                        <!-- Select Veículos -->
                                        <div v-if="tipoSelecionado == 1" class="col-sm-12 nopadding">
                                            <selectAll :selected="popoverData.veiculos" ref="srefSelectAllVeiculos"
                                                @changeSelect="changeSelectVeiculos" nameForRadio="veiculoOuGrupoModal"
                                                :isMultiple="true" :hasSelectAll="true" :optionsArray="veiculosDoBanco"
                                                :loading="loadingVeiculos" :disabled="loadingVeiculos" />
                                        </div>

                                        <!-- Select Grupo -->
                                        <div v-if="tipoSelecionado == 2" class="col-sm-12 nopadding">
                                            <selectAll :selected="popoverData.grupos" ref="srefSelectAllGruposVeiculos"
                                                @changeSelect="changeSelectGrupoVeiculos"
                                                nameForRadio="veiculoOuGrupoModal" :isMultiple="true"
                                                :hasSelectAll="true" :optionsArray="grupoVeiculoDoBanco"
                                                :loading="loadingGrupos" :disabled="loadingGrupos" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Input Status -->
                            <div class="mb-3">
                                <buttonsFilters @buttonsFiltersChange="changeStatus" :arrayButtons="arrayButtonsStatus"
                                    label="Status" :onlyOneSelected="false">
                                </buttonsFilters>
                            </div>

                            <!-- Input Turnos -->
                            <div class="mb-3">
                                <buttonsFilters @buttonsFiltersChange="changeTurno" :arrayButtons="arrayButtonsTurnos"
                                    label="Turno" :onlyOneSelected="false">
                                </buttonsFilters>
                            </div>

                            <!-- Botões -->
                            <div class="col-sm-12 d-flex justify-content-center align-items-center">
                                <!-- Filtrar -->
                                <div>
                                    <SimpleButton :disabled="$v.$invalid" text="Filtrar" type="blue" event="gerar"
                                        @gerar="getInformacoesLinhas" style="float:right;" />
                                </div>

                                <!-- Cancelar -->
                                <div>
                                    <SimpleButton text="Fechar" :icon="mdiCloseThick" type="red" event="gerar"
                                        @gerar="limparFiltros" style="float:right;" />
                                </div>
                            </div>
                        </div>
                    </div>
                </b-popover>
            </div>

            <div class="col-sm-12">
                <hr>
            </div>

            <div class="divListagemLinhas col-sm-12">
                <statusInformation v-if="arrayDados.length == 0" typeBar="div" :statusBar="statusBar">
                </statusInformation>

                <monitoramentoLP @linhaCriada="linhasPrincipaisCriadas" v-for="(rotaP, index) in arrayDados"
                    :key="index" :arrayDados="rotaP">
                </monitoramentoLP>
            </div>
        </div>
    </panelEagle>
</template>

<script>
import { mdiTelevisionGuide, mdiAlert, mdiAlertCircleOutline, mdiPlus, mdiCloseThick } from '@mdi/js'
import Vue from 'vue'
import { EmpresasService } from '../../../Services/auth/Empresas.service'
import { HttpRequest } from '../../../Services/auth/HttpRequest.Service'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
    name: 'MonitoramentoLinhas',

    components: {
        'inputSingleDay': require('@/components/Atom/Datas/InputSingleDay').default,
        'panelEagle': require('@/components/Atom/Panel/PanelEagle').default,
        'tituloPage': require('@/components/Atom/Header/Titulo').default,
        'buttonExpand': require('@/components/Atom/Buttons/ButtonExpand').default,
        'selectAll': require('@/components/Atom/Select/SelectAll').default,
        'subtitle': require('@/components/Atom/Subtitle/Subtitle').default,
        'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
        'SimpleButton': require('@/components/Atom/Buttons/SimpleButton').default,
        'monitoramentoLP': require(
            '@/components/Atom/SpecificComponents/MonitoramentoLinhas/MonitoramentoLinhaPrincipal').default,
        'buttonsFilters': require('@/components/Atom/Buttons/ButtonsFilters').default,
    },

    validations: {
        selectedEmpresas: { required },
    },

    data() {
        return {
            mdiAlert: mdiAlert,
            mdiTelevisionGuide: mdiTelevisionGuide,
            mdiAlertCircleOutline: mdiAlertCircleOutline,
            mdiPlus: mdiPlus,
            mdiCloseThick: mdiCloseThick,
            optionsEmpresas: new EmpresasService().Get(),
            selectedEmpresas: [],
            itemsSubtitleRota: [
                { 'description': 'Operação de acordo com o programado', 'spanColor': 'light-green' },
                { 'description': 'Operação com atraso tolerado', 'spanColor': 'orange' },
                { 'description': 'Operação em atraso/adiantada', 'spanColor': 'red' },
            ],
            itemsSubtitlePontos: [
                { 'description': 'Parou no ponto', 'spanColor': 'light-green' },
                { 'description': 'Pendente', 'spanColor': 'blue' },
                { 'description': 'Não parou no ponto', 'spanColor': 'red' },
            ],
            statusBar: 'info',
            arrayDados: [],
            arrayIndicadores: [],
            lastRequest: '',
            countLinhasPrincipais: 0,
            arrayLinhasPrincipais: [],
            rotasCriadas: [],
            pontosLinha: [],
            atualiza: undefined,
            loadingPanel: false,
            selectedEmpresa: [],
            popoverFiltrosShow: false,
            popoverDadosFiltro: {
                empresas: [],
            },
            arrayButtonsStatus: [
                {
                    'value': 'I', 'title': 'Iniciadas', 'width': '48%',
                    'text': 'Iniciadas', 'isSelected': true
                },
                {
                    'value': 'P', 'title': 'Pendentes', 'width': '48%',
                    'text': 'Pendentes', 'isSelected': false
                },
                {
                    'value': 'F', 'title': 'Finalizadas', 'width': '48%',
                    'text': 'Finalizadas', 'isSelected': false
                },
                {
                    'value': 'C', 'title': 'Canceladas', 'width': '48%',
                    'text': 'Canceladas', 'isSelected': false
                },
            ],
            arrayButtonsTurnos: [
                {
                    'value': 'P', 'title': '1º Turno', 'width': '33%',
                    'text': '1º Turno', 'isSelected': true, 'extraClass': 'm-0'
                },
                {
                    'value': 'S', 'title': '2º Turno', 'width': '33%',
                    'text': '2º Turno', 'isSelected': false, 'extraClass': 'm-0'
                },
                {
                    'value': 'T', 'title': '3º Turno', 'width': '33%',
                    'text': '3º Turno', 'isSelected': false, 'extraClass': 'm-0'
                },
            ],
            filtroSelecionadoStatus: '',
            filtroSelecionadoTurno: '',
            selectedRadio: 1,
            tipoSelecionado: 1,
            selectedVeiculos: [],
            selectedGrupoVeiculos: [],
            veiculosDoBanco: [],
            loadingVeiculos: false,
            dataSelecionada: '',
            popoverData: {
                empresas: [],
                veiculos: [],
                grupos: []
            },
        }
    },

    beforeDestroy() {
        clearInterval(this.atualiza)
    },

    methods: {
        ...mapGetters(['getMaster']),

        changeEmpresa(empresas) {
            this.selectedEmpresas = empresas;
            this.arrayDados = [];

            this.veiculosDoBanco = [];
            this.grupoVeiculoDoBanco = [];

            this.valueVeiculos = [];
            this.valueGrupoVeiculos = [];

            this.popoverData.grupos = [];
            this.popoverData.veiculos = [];

            this.buscaVeiculosGrupos();
        },

        changeData(data) {
            this.dataSelecionada = data;
            this.popoverData.data = data;
            this.dataSelecionada = data;
        },

        /**
        * @description Adiciona/Remove o veículo selecionado no input veículos.
        * @param {string} veiculo - Veículo selecionado no input.
        * @author Yuri 🇧🇷
        */
        changeSelectVeiculos(veiculo) {
            this.valueVeiculos = veiculo;
            this.selectedGrupoVeiculos = [];
            this.valueGrupoVeiculos = [];
        },

        /**
        * @description Adiciona/Remove o grupo selecionado no input grupos.
        * @param {string} Grupo - Grupo selecionado no input.
        * @author Yuri 🇧🇷
        */
        changeSelectGrupoVeiculos(grupo) {
            this.valueGrupoVeiculos = grupo;
            this.selectedVeiculos = [];
            this.valueVeiculos = [];
        },

        changeStatus(event) {
            this.filtroSelecionadoStatus = event;
        },

        changeTurno(event) {
            this.filtroSelecionadoTurno = event;
        },

        async getInformacoesLinhas() {
            try {
                if (this.selectedEmpresas.length > 0) {
                    this.loadingPanel = true;
                    const body = {
                        'data': this.dataSelecionada,
                        'clientes': this.selectedEmpresas,
                        'veiculos': this.valueVeiculos || [],
                        'grupos': this.valueGrupoVeiculos || [],
                        'status': this.filtroSelecionadoStatus || [],
                        'turno': this.filtroSelecionadoTurno || [],
                        'tipoSelecionado': this.tipoSelecionado
                    }

                    var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/listar/rotas', body)
                    this.arrayDados = [];

                    if (data.data.rotas.rotas.length > 0) {
                        this.arrayDados = data.data.rotas.rotas;
                        this.pontosLinha = this.arrayDados[0].rotas[0].pontos
                        this.arrayIndicadores = data.data.rotas.indicadores;
                        this.lastRequest = data.data.horario;
                    } else {
                        this.statusBar = 'error';
                    }
                    this.controleIntervalo('criar')
                } else {
                    this.statusBar = 'info';
                    this.arrayDados = [];
                    this.arrayIndicadores = [];
                    this.lastRequest = '';
                }
                this.loadingPanel = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');

                this.loadingPanel = false;
            }
        },

        async buscaVeiculosGrupos() {
            try {
                this.loadingVeiculos = true;
                this.loadingGrupos = true;

                var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/listar/veiculos/grupos', { empresas: this.selectedEmpresas })

                this.veiculosDoBanco = data.data.veiculos;
                this.grupoVeiculoDoBanco = data.data.grupos;

                this.loadingGrupos = false;
                this.loadingVeiculos = false;
            } catch (error) {
                this.toastShow('Ops!', 'Ocorreu um problema não esperado!', 'danger');

                this.loadingVeiculos = false;
                this.loadingVeiculos = false;
            }
        },

        linhasPrincipaisCriadas(conjuntoLinhas) {
            this.rotasCriadas.push(conjuntoLinhas);
        },

        async atualizaInfo() {
            if (this.lastRequest != '' && this.selectedEmpresas.length > 0) {
                const body = {
                    'data': this.dataSelecionada,
                    'clientes': this.selectedEmpresas,
                    'veiculos': this.valueVeiculos || [],
                    'grupos': this.valueGrupoVeiculos || [],
                    'status': this.filtroSelecionadoStatus || [],
                    'turno': this.filtroSelecionadoTurno || [],
                    'tipoSelecionado': this.tipoSelecionado
                }
                const rotasAbertas = this.arrayDados;

                var data = await new HttpRequest().Post('/coletivos/monitoramento/linhas/atualizar/info', body);

                this.arrayDados = [];
                this.arrayIndicadores = data.data.rotas.indicadores;
                var novas = data.data.rotas.novas;
                var excluidas = data.data.rotas.excluidas;
                var atualizar = data.data.rotas.atualizar;
                this.lastRequest = data.data.horario;

                await this.novasRotas(novas);
                await this.excluirRotas(excluidas);
                await this.atualizarRotas(atualizar, rotasAbertas);
            } else {
                this.statusBar = 'info';
                this.arrayDados = [];
                this.arrayIndicadores = [];
                this.lastRequest = '';
            }
        },

        novasRotas(novas) {
            var achou = false;
            for (var index in novas) {
                for (var i in this.arrayDados) {
                    if (novas[index].codigo == this.arrayDados[i].codigo) {
                        achou = true;
                        var merged = this.arrayDados[i].rotas.concat(novas[index].rotas);
                        this.arrayDados[i].rotas = merged;
                        this.arrayDados[i].linhasIniciadas = this.arrayDados[i].linhasIniciadas + novas[index].linhasIniciadas;
                    }
                }
            }

            if (!achou) {
                var mergedTwo = this.arrayDados.concat(novas);
                this.arrayDados = mergedTwo;
            }
        },

        excluirRotas(excluidas) {
            for (var a in excluidas) {
                for (var b in this.arrayDados) {
                    for (var c in this.arrayDados[b].rotas) {
                        if (excluidas[a] == this.arrayDados[b].rotas[c].codigo) {
                            this.arrayDados[b].rotas.splice(c, 1);
                            this.arrayDados[b].linhasIniciadas--;
                            if (this.arrayDados[b].linhasIniciadas == 0) {
                                this.arrayDados.splice(b, 1);
                            }
                            break;
                        }
                    }
                }

                for (var d in this.rotasCriadas) {
                    if (excluidas[a] == this.rotasCriadas[d]) {
                        this.rotasCriadas.splice(d, 1);
                    }
                }
            }

        },

        atualizarRotas(atualizar, rotasAbertas) {
            for (var a in atualizar) {
                for (var b in this.arrayDados) {
                    for (var c in this.arrayDados[b].rotas) {
                        if (atualizar[a].rota == this.arrayDados[b].rotas[c].codigo) {
                            this.arrayDados[b].rotas[c].inicioIniciado = atualizar[a].iniciado;
                            this.arrayDados[b].rotas[c].statusOnibus = atualizar[a].statusOnibus;
                            this.arrayDados[b].rotas[c].posicaoBusao = atualizar[a].posicaoOnibus;
                            this.arrayDados[b].rotas[c].passados = atualizar[a].pontosPassados;
                            this.arrayDados[b].rotas[c].porCent = atualizar[a].definePorcentagem;
                            this.arrayDados[b].rotas[c].tempoDeExecucao = atualizar[a].tempoDeExecucao;
                            this.arrayDados[b].rotas[c].kmPercorrido = atualizar[a].kmPercorrido;
                            this.arrayDados[b].rotas[c].rocaClicada = true;

                            for (var e in atualizar[a].pontosAtualizados) {
                                for (var d in this.arrayDados[b].rotas[c].pontos) {
                                    if (atualizar[a].pontosAtualizados[e].ponto == this.arrayDados[b].rotas[c].pontos[d].ponto) {
                                        this.arrayDados[b].rotas[c].pontos[d].status = atualizar[a].pontosAtualizados[e].status
                                        this.arrayDados[b].rotas[c].pontos[d].tempoParado = atualizar[a].pontosAtualizados[e].tempoParado
                                        this.arrayDados[b].rotas[c].pontos[d].kmPercorrido = atualizar[a].pontosAtualizados[e].kmPercorrido
                                        this.arrayDados[b].rotas[c].pontos[d].horaPrevista = atualizar[a].pontosAtualizados[e].horaPrevista
                                        this.arrayDados[b].rotas[c].pontos[d].statusPonto = atualizar[a].pontosAtualizados[e].statusPonto
                                        this.arrayDados[b].rotas[c].pontos[d].mensagem = atualizar[a].pontosAtualizados[e].mensagem
                                        this.arrayDados[b].rotas[c].pontos[d].status = atualizar[a].pontosAtualizados[e].status
                                        this.arrayDados[b].rotas[c].pontos[d].horaPassou = atualizar[a].pontosAtualizados[e].horaPassou
                                    }
                                }
                            }
                        }
                    }
                }
            }

            this.arrayDados.map((dados, indexDados) => {
                dados.rotas.map((rota, indexRotas) => {
                    const rotaAtual = rotasAbertas[indexDados].rotas[indexRotas];
                    if (rota.codigo == rotaAtual.codigo) {
                        this.arrayDados[indexDados].rotas[indexRotas].rotaClicada = rotaAtual.rotaClicada;
                    }
                })
            })
        },

        /**
         * @description Método para mostrar o toast na tela
         * @param {string} msg   - mensagem que vai aparecer no corpo do toast
         * @param {string} type  - qual o tipo do toast
         * @param {string} title - título para inserir no cabeçalho
         * @author Vitor Hugo 🐨
         */
        toastShow(title, msg, type) {
            this.$bvToast.toast(msg, {
                autoHideDelay: 2500,
                variant: type,
                title: title,
            });
        },

        /** 
         * Método para atualizar relatório a cada 60s
         * @param String func = string com oq deve ser feito
         * @return Variável atualiza como undefined
         * @author Vitor Hugo
         */
        controleIntervalo(func) {
            if (func == 'criar') {
                if (this.atualiza == undefined) {
                    this.atualiza = setInterval(() => {
                        this.atualizaInfo()
                    }, 60000);
                }
            } else {
                //destruir intervalo
                if (this.atualiza !== undefined) {
                    clearInterval(this.atualiza)
                    this.atualiza = undefined
                }
            }
        },

        //fazer documentação
        filtrar() {
            this.popoverFiltrosShow = false;
        },

        /**
        * @description Evento de fechar o modal, quando este é executado,
        * as informações dos filtros são armazenadas em *this.popoverDadosFiltro*,
        * pois ao fechar e abrir os filtros, deve mostrar as mesmas
        * informações selecionadas. Não consegui salvar o estado do popover.
        * @author Rafa 🐈
        */
        onHidden() {
            if (Array.isArray(this.selectedEmpresas)) {
                this.popoverData.empresas = this.optionsEmpresas.filter((e) => this.selectedEmpresas.includes(e.value))
            }

            if (this.tipoSelecionado == 1) {
                if (Array.isArray(this.valueVeiculos)) {
                    this.popoverData.veiculos = this.veiculosDoBanco.filter((e) => this.valueVeiculos.includes(e.value))
                }
            } else {
                if (Array.isArray(this.valueGrupoVeiculos)) {
                    this.popoverData.grupos = this.grupoVeiculoDoBanco.filter((e) => this.valueGrupoVeiculos.includes(e.value))
                }
            }
        },

        /**
         * Função para selecionar qual tipo de filtro foi 
         selecionado e mostra em tela para o usuário o inpput correspondente,
        * variável que salva qual tipo foi selecionado é 
        this.tipoSelecionado.
        * @param {tipo} number tipo para filtro selecionado, 
        1 = Veículos
        2 = Grupos
        * @author Yuri 🇧🇷
        */
        selecionaTipo(tipo) {
            if (tipo == 1) {
                this.popoverData.grupos = [];
            } else {
                this.popoverData.veiculos = [];
            }

            this.tipoSelecionado = tipo;
        },

        limparFiltros() {
            this.popoverFiltrosShow = false;
        }
    },

    mounted() {
        if (!this.getMaster()) {
            this.selectedEmpresa = this.optionsEmpresas
        }
    }
})
</script>

<style lang="css">
.divListagemLinhas {
    padding-bottom: 10px;
}

.custom-break-line {
    word-break: break-all;
    overflow-wrap: break-word;
}
</style>