Componente que vira a popup do ponto de acionamento de portas. 
Props: 
	acionamento: dados de um acionamento de porta
	acionamento.porta = nome da porta que gerou o acionamento 
		(báu, betoneira, porta motorista, etc)
	acionamento.data = data do acionamento, no formato hh:mm dd/mm
	acionamento.endereço = onde aconteceu
	acionamento.mtnome =  nome do motorista. 
	<template>
		<div id='info-acionamento-portas-mapa'>
			<div class="col-12 title-popup">
				<span class="d-flex justify-content-center negrito">{{ acionamento.porta }}</span>
			</div>
	
			<div v-if="acionamento.data_saida">
				<div class="col-12">
					<span>Início:</span> {{ acionamento.data_entrada }}
				</div>
				<div class="col-12">
					<span>Fim:</span> {{ acionamento.data_saida }}
				</div>
				<div class="col-12">
					<span class="tempo-acionamento">Tempo do acionamento: {{ acionamento.tempoAcionamento }}</span>
				</div>
			</div>
	
			<div v-else>
				<div class="col-12">
					<span>Em acionamento:</span> {{ acionamento.data_entrada }}
				</div>
				<div class="col-12">
					<span class="tempo-acionamento">Tempo do acionamento: {{ acionamento.tempoAcionamento }}</span>
				</div>
			</div>
	
			<div class="col-12">
				<span>Endereço:</span> {{ acionamento.endereco }}
			</div>
			<div class="col-12" v-if="acionamento.mtnome">
				<span>Motorista:</span> {{ acionamento.mtnome }}
			</div>
		</div>
	</template>
	

<script>
import Vue from 'vue'
export default Vue.extend({
	name: 'infoAcionamentoPortasMapa',
	props:{
		acionamento:{
			type: Object,
			required: true,
		}
	},
	computed:{
		portaAcionamento(){
			var porta  = '';
			switch(this.acionamento.bimotivotransmissao){
				case 13:
					porta = 1
					break
				case 15:
					porta = 2
					break
				case 17:
					porta = 3
					break
				case 19:
					porta = 4
					break
				default:
					porta = ''
			}
			var nome = ''
			if(this.acionamento.porta)
				nome = `- ${this.acionamento.porta}`
			return `${porta} ${nome}`
		}
	}
})
</script>

<style lang="scss" scoped>
#info-acionamento-portas-mapa{
	width: 400px;
	min-height: fit-content;
	div{
		inline-size: 300px;
		overflow-wrap: break-word;
	}
	.negrito{
		font-weight: bolder;
	}
	.title-popup{
		font-size: 16px;
		color: red;
	}
	.tempo-acionamento{
		color: red;
	}
}

</style>