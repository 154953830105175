Componente utilizado no Finder, condença todas as informações de 1 veículo, Possui um sub
componente que traz todos os alertas não resolvidos ligados ao veículo, dando a opção de
tratar os mesmos. Eventos seguir-veiculo: Emitido quando o usuário clica no botão seguir,
envia o objeto veículo e um boolean rastro-comparar: Envia o rastro de 2 veículos
rastro-veiculo : Envia o rastro de 1 veículo
<template>
    <div id="viewVeiculo" class="row tamanhoFonteInfo">

        <div :class="{ esconder: !loading }" class="loadingInfo  pt-3 d-flex justify-content-center">
            <b-spinner small></b-spinner>
            <span class="ml-1">Carregando</span>
        </div>
        <div class="divInfo ">
            <div v-show="!loading" class="viewVeiculo" :class="{ divViewVeiculoMinimizada: showInfoVeiculo == false }">
                <div id="viewVeiculo_draggable" class="displayFlex justify-content-between divCabecalho"
                    :class="{ divViewVeiculoId: showInfoVeiculo == false }">
                    <div title="Placa" :class="{displayFlex: showInfoVeiculo == true}">
                        <base-icon :icon="mdiShieldCar" :size="18" />
                        <div :class="{ botaoEsconderInfo: showInfoVeiculo == false }">
                            <span class="ml-1">{{ veiculo.placa }}</span>
                        </div>
                    </div>
                    <div class="divBotaoShow" :class="{ divBotaoFecharMinimizar: showInfoVeiculo == false }">
                        <div class="divBotaoMinimiza">
                            <b-button squared size="sm" style="background-color: white;" type="black" class="bt-1"
                                :title="titleVerInfoVeiculo" :class="{ botaoMinimizar: showInfoVeiculo == false }"
                                @click="exibeInfoVeiculo">
                                <base-icon style="color: black;" :size="18" :icon="iconBotaoMinimizaInfoVeiculo" />
                            </b-button>
                        </div>
                        <div>
                            <b-button squared size="sm" variant="danger" class="bt-1"
                                :class="{ botaoFechar: showInfoVeiculo == false }" @click="fechaInfo">
                                <base-icon :size="18" :icon="mdiCloseThick" />
                            </b-button>
                        </div>
                    </div>


                </div>
                <div class="infoVeiculo mx-2 " v-show="showInfoVeiculo">
                    <div class="tituloso">
                        <div class="tiltable tamanhoFonteInfo">Prefixo</div>
                        <div class="tiltable tamanhoFonteInfo">Temperatura</div>
                        <div v-if="veiculo.temHorimetro" class="tiltable tamanhoFonteInfo">Horímetro</div>
                        <div v-else class="tiltable tamanhoFonteInfo">Hodômetro</div>

                        <div v-if="veiculo.temHorimetro">
                            <div class="tiltable tamanhoFonteInfo">H. Trabalhadas</div>
                            <div class="tiltable tamanhoFonteInfo">H. Trabalhadas CAN</div>
                        </div>
                        <div v-else>
                            <div class="tiltable tamanhoFonteInfo">Percorridos</div>
                            <div class="tiltable tamanhoFonteInfo">Percorridos CAN</div>
                        </div>
                        <div class="tiltablebot tamanhoFonteInfo">Empresa</div>
                    </div>
                    <div>
                        <div class="cellTable awntable  tamanhoFonteInfo text-truncate" :title="veiculo.prefixo"
                            v-text="veiculo.prefixo">
                        </div>
                        <div class="cellTable tamanhoFonteInfo awntable" :title="defineTemperatura()"
                            v-text="defineTemperatura()"></div>

                        <div v-if="veiculo.temHorimetro" class="cellTable tamanhoFonteInfo awntable"
                            :title="formataHorimetro(veiculo.horimetro)" v-text="formataHorimetro(veiculo.horimetro)">
                        </div>
                        <div v-else class="cellTable tamanhoFonteInfo awntable"
                            :title="formataHodometro(veiculo.hodometro)" v-text="formataHodometro(veiculo.hodometro)">
                        </div>

                        <div v-if="veiculo.temHorimetro">
                            <div class="cellTable tamanhoFonteInfo awntable&quot;"
                                :title="formataHorimetro(veiculo.percorrido)"
                                v-text="formataHorimetro(veiculo.percorrido)"></div>
                            <div class="cellTable tamanhoFonteInfo awntable&quot;"
                                :title="formataHorimetro(veiculo.percorridoCAN)"
                                v-text="formataHorimetro(veiculo.percorridoCAN)"></div>
                        </div>
                        <div v-else>
                            <div class="cellTable tamanhoFonteInfo awntable&quot;"
                                :title="formataPercorrido(veiculo.percorrido)"
                                v-text="formataPercorrido(veiculo.percorrido)"></div>
                            <div class="cellTable tamanhoFonteInfo awntable&quot;"
                                :title="formataPercorrido(veiculo.percorridoCAN)"
                                v-text="formataPercorrido(veiculo.percorridoCAN)"></div>
                        </div>
                        <div class="awntablebot tamanhoFonteInfo wordWrap" :title="veiculo.clfantasia"
                            v-text="veiculo.clfantasia"></div>
                    </div>
                </div>
                <div class="displayFlex  justify-content-center mt-1" v-show="showInfoVeiculo">
                    <b-checkbox v-if="verbloqueio" :disabled="!podebloquear" switch v-model="switchBloqueio"
                        @change="abreModalBloqueio" class="tamanhoFonteInfo">
                        {{ switchBloqueio ? "Desbloquear" : "Bloquear" }}
                    </b-checkbox>
                </div>
                <div class="displayFlex  justify-content-center" v-show="showInfoVeiculo">
                    <b-button squared :pressed="controleaba == 0" class="botaoPrincipal tamanhoFonteInfo"
                        @click="controleaba = 0" variant="outline-secondary">
                        <base-icon :icon="mdiCarInfo" style="margin-top: -3px" :size="14" />Info. Veículo
                    </b-button>
                    <div class="divisoria" />
                    <b-button squared :pressed="controleaba == 1" class="botaoPrincipal tamanhoFonteInfo"
                        @click="controleaba = 1" variant="outline-secondary">
                        <base-icon :icon="mdiBell" style="margin-top: -5px" :size="14" /><label
                            class="nopadding tamanhoFonteInfo" style="margin-top: -10px;">Alertas</label>
                    </b-button>
                </div>
                <div v-show="showInfoVeiculo">
                    <hr />
                </div>
                <div v-if="controleaba == 0">
                    <b-overlay :show="overlay">
                        <div class="displayFlex " id="popover-reactive-1" event="click" v-show="showInfoVeiculo">
                            <label class="pt-1 pr-1 tamanhoFonteInfo" for="periodoPicker">Período:</label>
                            <el-date-picker id="periodoPicker" style="height: 25px" v-model="modelPeriodo"
                                :picker-options="pickerOptions" class="datePicker tamanhoFonteInfo" type="daterange"
                                size="mini" range-separator="-" value-format="dd/MM/yyyy" format="dd/MM/yyyy"
                                start-placeholder="" end-placeholder="" @change="mudaPeriodo"
                                @limpa-rastro="mudaPeriodo" @limpa-parada="mudaPeriodo">
                            </el-date-picker>
                            <b-popover target="popover-reactive-1" placement="left" variant="danger" triggers="manual"
                                ref="popoverPeriodo">
                                <div>
                                    {{ msgErroPeriodo }}
                                </div>
                            </b-popover>
                        </div>
                        <div class="displayFlex  justify-content-between mt-1"
                            :title="loadingPercorridos ? 'Consultando dados' : ''" v-show="showInfoVeiculo">
                            <b-checkbox id="checkbox-horario" v-model="checkDetalhado" class="pt-1"
                                :disabled="loadingPercorridos">
                                <label class="nopadding tamanhoFonteInfo" style="margin: 0px;">Horário:</label>
                            </b-checkbox>

                            <div class="  justify-content-between">
                                <input class="inputTime" :class="{ 'border border-danger': dataErrada }"
                                    v-model="horaInicio" :disabled="!checkDetalhado" type="time" />
                                <span class="mr-2 ml-2">-</span>
                                <input class="inputTime" :class="{ 'border border-danger': dataErrada }"
                                    v-model="horaFim" :disabled="!checkDetalhado" type="time" />
                            </div>
                        </div>
                        <div class="displayFlex  justify-content-between mt-1 mb-1" v-show="showInfoVeiculo">
                            <b-checkbox id="checkbox-rastro" :disabled="dataErrada" v-model="mostraRastro"
                                class="tamanhoCheck pt-1">
                                <label class="nopadding tamanhoFonteInfo" style="margin: 0px;">Rastro</label>
                            </b-checkbox>
                            <div class="displayFlex  justify-content-between" v-show="showInfoVeiculo">
                                <!--BOTÃO KML-->
                                <b-button squared title="Exportar rastro do veiculo"
                                    :disabled="!mostraRastro || dataErrada" size="sm"
                                    class="botaoRastro tamanhoFonteInfo" @click="exportarRastro">
                                    KML
                                </b-button>
                                <!--BOTÃO SEGUIR-->
                                <b-button squared :variant="seguindo ? 'danger' : 'secondary'" :title="tituloSeguindo()"
                                    size="sm" @click="seguirVeiculo()" class="botaoRastro tamanhoFonteInfo">
                                    Seguir
                                </b-button>
                            </div>
                        </div>
                        <div class="displayFlex tamanhoFonteInfo justify-content-between" v-show="showInfoVeiculo">
                            <!--Checkbox de comparação -->
                            <b-checkbox id="comparar-rastro" :disabled="!mostraRastro || dataErrada"
                                v-model="compararVeiculo" style="font-size: 7px !important;">
                                <label class="nopadding tamanhoFonteInfo" style="margin: 0px;">Comparar</label>
                            </b-checkbox>
                            <!--seletor de veiculo p/ comparação-->
                            <select v-model="compararCom" :disabled="!compararVeiculo || dataErrada"
                                class="seletorForm tamanhoFonteInfo">
                                <option selected value="">Selecione um veiculo</option>
                                <option v-for="opt in optVeiculos" :key="opt.value" :value="opt.value">
                                    {{ opt.description }}
                                </option>
                            </select>
                        </div>
                        <div v-if="possuiEaglePerformance" class="displayFlex  justify-content-between"
                            v-show="showInfoVeiculo">
                            <b-checkbox :disabled="dataErrada" v-model="mostraEventos" class="mt-2">
                                <label class="nopadding tamanhoFonteInfo" style="margin: 0px;">Eventos</label>
                            </b-checkbox>
                            <select v-model="eventoSelecionado" :disabled="!mostraEventos || dataErrada"
                                class="seletorForm tamanhoFonteInfo">
                                <option selected value="">Selecione um evento</option>
                                <option v-for="ev in optEventosVeiculos" :value="ev.value"
                                    :key="`${ev.value}_eventos_veiculo`">
                                    {{ ev.description }}
                                </option>
                            </select>
                        </div>
                        <div class="displayFlex justify-content-between" v-show="showInfoVeiculo">
                            <!-- Mostrar Paradas-->
                            <b-checkbox :disabled="dataErrada" v-model="mostraParadas" class="mt-2 check-slider"><label
                                    class="nopadding tamanhoFonteInfo" style="margin: 0px;">Paradas</label></b-checkbox>
                            <basic-slider :minValue="0" :maxValue="60" :value="initialValueParadas"
                                @change="mudaSliderParadas" class="mt-2 sliderVeiculo" />
                            <span class="mt-2 displayzin">
                                {{ nParadas + "min" }}
                            </span>
                        </div>
                        <div class="displayFlex justify-content-between" v-show="showInfoVeiculo">
                            <!-- Mostrar Velocidade -->
                            <b-checkbox :disabled="dataErrada" v-model="mostraVelocidade"
                                class="mt-2 check-slider"><label class="nopadding tamanhoFonteInfo"
                                    style="margin: 0px;">Velocidade</label></b-checkbox>
                            <basic-slider @change="mudaSliderVelocidade" :minValue="30" :maxValue="100"
                                :value="initialValueVelocidade" class="mt-2 sliderVeiculo" />
                            <span class="mt-2 displayzin">{{ velocidade + "Km/h" }}</span>
                        </div>
                        <div class="displayFlex justify-content-between" v-show="showInfoVeiculo">
                            <b-checkbox :disabled="dataErrada" v-model="mostraAcPortas" class="mt-2">
                                <label class="nopadding tamanhoFonteInfo" style="margin: 0px;">Acionamentos</label>
                            </b-checkbox>
                            <div style="display: flex; gap: 5px;">
                                <b-button size="sm"
                                    :class="['rounded-circle', 'botaoPorta', 'tamanhoFonteInfo', getPortaAtiva(13) ? 'btn-success' : 'btn-secondary']"
                                    @click="portaAcMostrar = 13" :title="getNomePorta(13)">1</b-button>
                                <b-button size="sm"
                                    :class="['rounded-circle', 'botaoPorta', 'tamanhoFonteInfo', getPortaAtiva(15) ? 'btn-success' : 'btn-secondary']"
                                    @click="portaAcMostrar = 15" :title="getNomePorta(15)">2</b-button>
                                <b-button size="sm"
                                    :class="['rounded-circle', 'botaoPorta', 'tamanhoFonteInfo', getPortaAtiva(17) ? 'btn-success' : 'btn-secondary']"
                                    @click="portaAcMostrar = 17" :title="getNomePorta(17)">3</b-button>
                                <b-button size="sm"
                                    :class="['rounded-circle', 'botaoPorta', 'tamanhoFonteInfo', getPortaAtiva(19) ? 'btn-success' : 'btn-secondary']"
                                    @click="portaAcMostrar = 19" :title="getNomePorta(19)">4</b-button>
                            </div>
                            <b-checkbox :disabled="dataErrada" v-model="acionamentosParados"
                                class="d-flex align-items-center ml-2" @change="exibirAcionamentosParados">
                                <span class="nopadding tamanhoFonteInfo spanParado">Parado</span>
                            </b-checkbox>
                        </div>
                        <div class="displayFlex col-sm-12 row p-0 m-0" v-show="showInfoVeiculo">
                            <div class="col-sm-6 row p-0 m-0">
                                <b-button :disabled="dataErrada" squared block @click="listarPosicoes"
                                    class="mt-1 botaoPosicoes tamanhoFonteInfo">
                                    <b class="tamanhoFonteInfo">Listar Posições</b>
                                </b-button>
                            </div>
                            <div class="col-sm-6 row p-0 m-0">
                                <b-button :disabled="dataErrada" squared block @click="visualizaViagens"
                                    class="mt-1 botaoPosicoes tamanhoFonteInfo">
                                    <b class="tamanhoFonteInfo">Visualizar Viagens</b>
                                </b-button>
                            </div>
                        </div>
                    </b-overlay>
                </div>
                <div v-else>
                    <alertas-painel-veiculo :temPerformance="possuiEaglePerformance" :tiposAlertas="optSelectTipoAlerta"
                        :codigoVeiculo="veiculo.veiculoId" @local-alerta="(v) => $emit('local-alerta', v)" />
                </div>
            </div>
        </div>
        <modal-eagle id="modal-bloqueio" title="Essa ação requer confirmação do usuário" :noCloseOnEsc="true"
            :noCloseOnBackdrop="true" :hardToClose="true" :hideHeaderClose="true" size="md"
            @confirmButton="bloquearVeiculo">
            <template #modalBody>
                <label class="label-senha-bloqueio" for="senha-bloqueio">Informe sua senha:</label>
                <input id="senha-bloqueio" type="password" class="form-control" v-model="senhaUsuario" />
                <span class="my-1">
                    {{ erroBloqueio }}
                </span>
            </template>
            <template #modalFooter>
                <b-button :disabled="bloqueando || $v.$invalid" variant="success" @click="bloquearVeiculo">
                    <b-spinner small class="my-1" v-if="bloqueando" />
                    <base-icon v-else size="18" :icon="mdiCheckBold" />
                    Confirmar
                </b-button>
                <b-button variant="danger" @click="cancelaAlteracaoBloqueio">
                    <base-icon size="18" :icon="mdiCloseThick" />
                    Cancelar
                </b-button>
            </template>
        </modal-eagle>
        <b-popover target="checkbox-rastro" placement="left" variant="danger" triggers="manual" ref="popupRastro">
            <div>Não há rastros para esse dia</div>
        </b-popover>
        <b-popover target="comparar-rastro" placement="left" variant="danger" triggers="manual" ref="popupCompara">
            <div>Não há rastros para esse dia</div>
        </b-popover>
    </div>
</template>

<script>
import Vue from "vue";
import ModalEagle from "@/components/Atom/Modal/ModalEagle";
import BasicSlider from "@/components/Atom/Buttons/BasicSlider.vue";
import AlertasPainelVeiculo from "@/components/Atom/Painel/PainelVeiculo/AlertasPainelVeiculo";
import BaseIcon from "@/components/Atom/Icon/BaseIcon.vue";
import { pickerOptions } from "@/components/Atom/Painel/datePickerHelper.js";
import { DateTime } from "luxon";
import { mdiShieldCar, mdiBell, mdiCarInfo, mdiCheckBold, mdiCloseThick, mdiWindowMaximize, mdiMinus } from "@mdi/js";
import { HttpRequest } from "@/Services/auth/HttpRequest.Service";
import { conectionError } from "@/Services/Helpers/swellHeper";
import { required, minLength } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import { Veiculo } from "@/views/Mapa/models";

export default Vue.extend({
    name: "infoVeiculo",
    components: {
        AlertasPainelVeiculo,
        BasicSlider,
        BaseIcon,
        ModalEagle,
    },

    props: {
        permissoes: {
            type: [Object, Array],
            required: true,
        },
        telemetryInterval: {
            type: Object,
            required: true,
        },
        optVeiculos: {
            type: Array,
            default: () => {
                return [];
            },
        },
        seguindo: {
            type: Boolean,
            default: false,
        },
        voltarViagem: {
            type: Boolean,
            default: false
        },
        abriuViagem: {
            type: Boolean,
            default: false
        }
    },

    validations: {
        senhaUsuario: {
            required,
            minLength: minLength(6),
        },
    },

    data() {
        return {
            pickerOptions: pickerOptions,
            mdiCloseThick: mdiCloseThick,
            mdiCheckBold: mdiCheckBold,
            mdiBell: mdiBell,
            mdiShieldCar: mdiShieldCar,
            mdiCarInfo: mdiCarInfo,
            titleVerInfoVeiculo: 'Minimizar informações do veículo',
            showInfoVeiculo: true,
            mdiWindowMaximize: mdiWindowMaximize,
            mdiMinus: mdiMinus,
            iconBotaoMinimizaInfoVeiculo: mdiMinus,
            optSelectTipoAlerta: [],
            bloqueando: false,
            switchBloqueio: false,
            loading: false,
            mostraAcPortas: false,
            mostraVelocidade: false,
            acionamentosParados: false,
            mostraParadas: false,
            mostraEventos: false,
            mostraRastro: false,
            checkDetalhado: false,
            compararVeiculo: false,
            overlay: false,
            temBloqueio: false,
            dataErrada: false,
            loadingPercorridos: false,
            compararCom: "",
            portaAcMostrar: "",
            modelVeiculoCompara: [],
            eventoSelecionado: "",
            controleaba: 0,
            modelPeriodo: [
                this.telemetryInterval.value.start.toFormat("dd/LL/yyyy"),
                this.telemetryInterval.value.end.toFormat("dd/LL/yyyy"),
            ],
            intervalo: "31",
            senhaUsuario: "",
            veiculo: {
                clfantasia: "",
                hodometro: null,
                horimetro: null,
                temHorimetro: false,
                percorrido: "-",
                percorridoCAN: "-",
                ticket: "",
                mocodigo: "",
                momodelo: "",
            },
            erroBloqueio: "",
            searchVeiculo: "",
            baseUri: "/finder/veiculo/",
            horaInicio: this.telemetryInterval.value.start.toFormat("HH:mm"),
            horaFim: this.telemetryInterval.value.end.toFormat("HH:mm"),
            velocidade: 40,
            initialValueVelocidade: 40,
            nParadas: 5,
            initialValueParadas: 5,
            arrayRastro: "",
            arrayVelocidade: [],
            arrayParadas: [],
            arrayAcPortas: [],
            temperatura: "",
            portas: [false, false, false, false],
            msgErroPeriodo: "",
            resetarParadasRastros: false,
        };
    },

    /**
     * @typedef {Object} iconVeiculo
     * @property {string} icon - html do ícone
     * @property {number[]} iconSize - tamanho do ícone
     */
    methods: {
        ...mapGetters(["getModulos"]),

        /**
         * @description se o periodo selecionado pelo usuário
         * for maior que o período válido de 7 dias, a função
         * força um periodo válido começando da primeira data.
         * @author Gui 🍺
         */
        mudaPeriodo() {
            this.$emit('limpa-rastro');
            this.$emit('limpa-parada');
            if (this.verificaPeriodo()) {
                return;
            }
            var com = DateTime.fromFormat(this.modelPeriodo[0], "dd/LL/yyyy");
            var fim = com.plus({ days: 6 });
            this.modelPeriodo = [com.toFormat("dd/LL/yyyy"), fim.toFormat("dd/LL/yyyy")];
        },
        tituloSeguindo() {
            if (!this.seguindo) return "Seguir rastro do veiculo";
            return "Parar de seguir veiculo";
        },

        /**
         * @description controla o valor da variavel showInfoVeiculo
         * pra setar o titulo e o ícone do estado atual
         * @author Otávio 🦆 
         */
        exibeInfoVeiculo() {
            this.showInfoVeiculo = !this.showInfoVeiculo;

            if (this.showInfoVeiculo) {
                this.titleVerInfoVeiculo = 'Minimizar informações do veículo';
                this.iconBotaoMinimizaInfoVeiculo = mdiMinus;
            } else {
                this.titleVerInfoVeiculo = 'Maximizar informações do veículo';
                this.iconBotaoMinimizaInfoVeiculo = mdiWindowMaximize;
            }
            this.emiteMinimizar();
        },
        /**
         * @description verifica se existe informação
         * de temperatura e caso sim, devolve ela formatada
         * para a tabela.
         * @return {string} com a temperatura ou "-"
         */
        defineTemperatura() {
            if (typeof this.temperatura === "number") return `${this.temperatura}°C`;
            return "-";
        },

        abreModalBloqueio() {
            this.senhaUsuario = "";
            this.$bvModal.show("modal-bloqueio");
        },

        cancelaAlteracaoBloqueio() {
            this.fechaModalBloqueio();
            this.switchBloqueio = !this.switchBloqueio;
        },

        fechaModalBloqueio() {
            this.$bvModal.hide("modal-bloqueio");
        },
        /**
         * @description limpa os dados de acionadores do mapa, caso o 
         * checkbox de parados esteja marcado faz a requisição para buscar
         * os dados de acionadores parados, caso seja desmarcado, busca todos os 
         * dados de acionadores do período.
         * @author Otávio 🦆
         */
        exibirAcionamentosParados(){
            this.$emit("limpa-ac-portas"); 
            if(this.mostraAcPortas){
                this.getAcPortas();
            }
        },
        /**
         * @example valor 1 - bloquear, 3 - desbloquear
         */
        bloquearVeiculo() {
            var obj = {
                valor: this.switchBloqueio ? "1" : "3",
                password: this.senhaUsuario,
                veiculo: this.veiculo.veiculoId,
                modulo: this.veiculo.mocodigo,
                modelo: this.veiculo.momodelo,
            };
            this.bloqueando = true;
            var uri = `${this.baseUri}bloqueio/veiculo`;
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    if (data.data?.erro) {
                        this.erroBloqueio = data.data.erro;
                        setTimeout(() => (this.erroBloqueio = ""), 3000);
                    } else this.fechaModalBloqueio();
                })
                .finally(() => (this.bloqueando = false));
        },

        /**
         * @listens click botão de exportar rastro
         * @description Faz a exportação do rastro do veículo para
         * KML, a função de exportação
         * precisa da plava do veículo e da array de coordenadas do rastro
         * as coordenadas devem ser string(!)
         */
        exportarRastro() {
            let uri = `${this.baseUri}exportar/rastro`;
            let obj = {
                placa: this.veiculo.placa,
                coordenadas: this.arrayRastro,
            };
            var root = process.env.VUE_APP_ROOT;
            new HttpRequest().Post(uri, obj).then((data) => {
                if (data.data?.dados) window.open(root + "/" + data.data.dados);
            });
        },

        listarPosicoes() {
            const obj = {
                placa: this.veiculo.placa,
                ...this.datas,
            };
            this.$emit("listar-posicoes", obj);
        },

        visualizaViagens() {
            const obj = {
                placa: this.veiculo.placa,
                prefixo: this.veiculo.prefixo,
                ...this.datas,
            };
            this.emiteMinimizar();
            this.$emit("visualiza-viagens", obj);
        },

        /**Emite o evento que avisa o mapa que ele deve seguir a
         * localizaçao desse veiculo em tempo real.
         */
        seguirVeiculo(iniciar = false) {
            if (iniciar) this.$emit("seguir-veiculo", this.veiculo.veiculoId, false);
            else this.$emit("seguir-veiculo", this.veiculo.veiculoId, !this.seguindo);
        },

        /**
         * Muda o valor do tempo consinderado uma
         * parada, e já altera o check-box pra falso.
         */
        mudaSliderParadas(tempo) {
            this.nParadas = tempo;
            this.emiteParadas();
        },

        /**
         * Muda o valor da velocidade, e já altera a
         * check-box para falso.
         */
        mudaSliderVelocidade(vel) {
            this.velocidade = vel;
            this.emiteExcessoVelocidade();
        },

        /**
         * cria o objeto e faz a request que traz os acionamentos das
         * portas do rastreador instalado no veículo.
         */
        getAcPortas() {
            this.overlay = true;
            let obj = {
                placa: this.veiculo.placa,
                acionamentosParados: this.acionamentosParados,
                ...this.datas,
            };
            let uri = this.baseUri + "ac/portas";
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    this.arrayAcPortas = data.data.array ?? [];
                    this.emiteAcPortas();
                })
                .finally(() => (this.overlay = false));
        },

        /**
         * Verifica se o motivo da porta está ativo no array de portas.
         * 
         * @param {number} bimotivo - O valor do bimotivo que representa o motivo da porta.
         * @returns {boolean} - Retorna true` se o bimotivo estiver presente no array, caso contrário false.
         * @author Otávio 🦆
         */
        getPortaAtiva(bimotivo) {
            return this.arrayAcPortas.some(item => item.bimotivotransmissao === bimotivo);
        },
        
        /**
         * Obtém o nome da porta correspondente ao bimotivo.
         * 
         * @param {number} bimotivo - O valor do bimotivo que representa o motivo da porta.
         * @returns {string} - O nome da porta associada ao bimotivo, ou uma string vazia se não for encontrada.
         * @author Otávio 🦆
         */
        getNomePorta(bimotivo) {
            const porta = this.arrayAcPortas.find(item => item.bimotivotransmissao === bimotivo);
            return porta ? porta.porta : '';
        },

        emiteAcPortas() {
            if (this.mostraAcPortas) {
                var acFiltro = this.arrayAcPortas.filter((p) => {
                    if (this.portaAcMostrar)
                        return p.bimotivotransmissao === this.portaAcMostrar;
                    return true;
                });
                this.$emit("ac-portas", acFiltro);
            }
        },

        /**
         * @description faz a requisição que traz todos os eventos de paradas
         * do veículo dentro do periodo selecionado pelo usuário.
         * @see watcher - mostraParadas
         */
        getParadas() {
            this.overlay = true;
            let obj = { placa: this.veiculo.placa, ...this.datas };
            let uri = this.baseUri + "paradas";
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    if (data.status) {
                        this.arrayParadas = data.data.paradas;
                        this.emiteParadas();
                    }
                })
                .finally(() => (this.overlay = false));
        },

        /**
         * @fires paradas-veiculo.
         */
        emiteParadas() {
            if (!this.mostraParadas) return;
            var paradas = this.arrayParadas.filter((p) => {
                return p.segundos >= this.nParadas * 60;
            });
            this.$emit("paradas-veiculo", paradas);
        },

        /**
         * @description emite o valor da showInfoVeiculoMapa usada para 
         * exiber ou não as info do veículo
         * @author Otávio 🦆
         */
        emiteMinimizar(){
            this.$emit("show-info-veiculo", this.showInfoVeiculo);
        },

        /**
         * @description pega os eventos de velocidade do veiculo.
         */
        GetExcessoVelocidade() {
            this.overlay = true;
            let obj = {
                placa: this.veiculo.placa,
                ...this.datas,
            };
            let uri = this.baseUri + "velocidade";
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    this.arrayVelocidade = data.data.array;
                    this.emiteExcessoVelocidade();
                })
                .finally(() => (this.overlay = false));
        },

        emiteExcessoVelocidade() {
            if (this.mostraVelocidade) {
                var array = this.arrayVelocidade.filter((v) => {
                    return v.velocidade >= this.velocidade;
                });
                this.$emit("excesso-velocidade", array);
            }
        },

        /**
         * @description procura pelos eventos do tipo
         * selecionado, p/ o veículo selecionado, na data
         * selecionada, se encontrar algo, emite o evento p/
         * o mapa.
         * @author Gui 🧟
         */
        getEventos() {
            this.overlay = true;
            let obj = {
                placa: this.veiculo.placa,
                evento: this.eventoSelecionado,
                ...this.datas,
            };
            let uri = this.baseUri + "eventos";
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    var eventos = data.data;
                    this.$emit("eventos-veiculo", eventos, this.eventoSelecionado);
                })
                .finally(() => (this.overlay = false));
        },

        /**
         * @param {object} 	      veiculo
         * @param {string?} 		  veiculo.prefixo
         * @param {number?} 		  veiculo.hodometro
         * @param {(number|string)?} veiculo.percorrido
         * @param {(number|string)?} veiculo.percorridoCAN
         * @param {?string?} 		  veiculo.clfantasia
         * @param {string?}		  veiculo.placa
         * @param {number?}		  veiculo.veiculoId
         * @description Inicia a modal/popUp/treco de veículo,
         * limpando as variáveis para não começar com os valores
         * de outro veiculo, passada as novas informações para a
         * modal/popUp/treco e depois procura pelas outras
         * informações no back-end.
         * O Veiculo precisa ter todos os campos acima para a/o
         * modal/popup/treco funcionar corretamente.
         * @author Gui 🏴
         */
        iniciaInfoVeiculo(veiculo) {
            this.controleaba = 0;
            this.resetaAsCoisas(true);
            this.veiculo = veiculo;
            this.procuraInformacaoVeiculo();
            let hoje = DateTime.now().toFormat("dd/LL/yyyy");
            this.modelPeriodo = [hoje, hoje];
            this.getAcPortas();
            this.$emit("show-info-veiculo", true)
        },

        /**
         * @param {object} veiculo
         * @description passa a resetarParadasRastros para false para não 
         * chamar a função resetaAsCoisas. Com os dados do objeto veiculo
         * seta a data, hora e placa para fazer as requisições e buscar os 
         * dados do rastro e paradas.
         * @author Otávio 🦆
         */
        iniciaInfoVeiculoRegiao(veiculo){
            this.resetarParadasRastros = false;
            this.checkDetalhado = true;
            this.horaInicio = veiculo.horaIni;
            this.horaFim = veiculo.horaFim;
            let dia = veiculo.data;
            this.modelPeriodo = [dia, dia];
            this.showInfoVeiculo = true;
            this.controleaba = 0;
            this.veiculo.veiculoId = veiculo.veiculo;
            this.veiculo.placa = veiculo.placa;
            this.veiculo.prefixo = veiculo.prefixo;
            this.mostraRastro = true;
            this.mostraParadas = true;
            this.procuraInformacaoVeiculo();
            this.getRastroVeiculo();
            this.getParadas();            
            this.$emit("show-info-veiculo", true);
        },

        /**
         * @description Busca inicial, que traz apenas as informações mais
         * básicas sobre o veículo. Espera 500ms para fazer com que o elemento
         * seja draggable, tentei usar o nextTick, mas não parece ter funcionado
         * direito, com a espera de meio segundo, a div _header já esta criada.
         */
        procuraInformacaoVeiculo() {
            this.loading = true;
            let uri = this.baseUri + "info";
            let obj = { vecodigo: this.veiculo.veiculoId };
            new HttpRequest()
                .Post(uri, obj)
                .then((data) => {
                    if (data.data?.dados) {
                        this.veiculo.clfantasia = data.data.dados.clfantasia;
                        this.veiculo.hodometro = data.data.dados.hodometro;
                        this.veiculo.horimetro = data.data.dados.horimetro;
                        this.veiculo.percorrido = data.data.dados.percorrido;
                        this.veiculo.percorridoCAN = data.data.dados.percorridoCAN;
                        this.veiculo.temHorimetro = data.temHorimetro;
                        this.veiculo.ticket = data.data.dados.ticket;
                        this.veiculo.mocodigo = data.data.dados.mocodigo;
                        this.veiculo.momodelo = data.data.dados.momodelo;
                        this.temBloqueio = data.data.bloqueio;
                        this.temperatura = data.data.temperatura;
                        this.portas = data.data.portas;
                        // this.$forceUpdate();
                        setTimeout(() => {
                            var info = document.getElementById("viewVeiculo");
                            this.dragElement(info);
                        }, 500);
                    } else {
                        conectionError(`Não foi possível carregar os dados do veículo`);
                    }
                })
                .finally(() => (this.loading = false));
        },

        /**
         * @fires rastro-comparar.
         * @fires rastro-veiculo.
         * @description Pega o rastro do veículo no back-end, e dependendo do
         * valor do check-box de comparação, já também traz o rastro
         * de outro veículo para, bom, comparação.
         * @author Gui 🍺
         */
        getRastroVeiculo() {
                let obj = {};
                let uri = `${this.baseUri}rastro`;
                this.overlay = true;
                if (this.compararVeiculo) {
                    this.$emit("remove-comparacao");
                    obj = {
                        vecodigos: [this.veiculo.veiculoId, this.compararCom],
                        data: this.modelPeriodo.join("-"),
                        // placaS:this.compararCom
                    };
                    uri += "/comparar";
                    new HttpRequest()
                        .Post(uri, obj)
                        .then((data) => {
                            if (data.data?.veiculos) {
                                this.emitRastroComparar(data.data);
                            } else {
                                this.disparaPopover("rastro-compara");
                            }
                        })
                        .finally(() => (this.overlay = false));
                } else {
                    obj = {
                        placa: this.veiculo.placa,
                        ...this.datas,
                    };
                    new HttpRequest()
                        .Post(uri, obj)
                        .then((data) => {
                            this.arrayRastro = JSON.stringify(
                                data.data.array.map((el) => {
                                    return el.posicao.split(",");
                                })
                            );
                            if (!data.data.array.length)
                                this.disparaPopover("rastro-veiculo");
                            this.$emit("rastro-veiculo", data.data.array, this.veiculo);
                        })
                        .finally(() => (this.overlay = false));
                }
        },

        emitRastroComparar({ veiculos, rastros }) {
            const latlng = veiculos.bilatlog?.split(",");
            var temp = {
                lat: parseFloat(latlng[0]),
                lng: parseFloat(latlng[1]),
                placa: veiculos.placa,
                prefixo: veiculos.prefixo,
                descricao: veiculos.placa,
                modulo: veiculos.modulo,
                veiculoId: veiculos.veiculoid,
                tipo: veiculos.vetipo,
                ignicao: veiculos.biignicao,
                direcao: veiculos.bidirecao,
            };
            this.$emit("rastro-comparar", rastros, new Veiculo(temp));
        },

        /**
         * @description dispara uma das popovers do componente,
         * e já deixa a função para em 6 segundos fechar a popover novamente.
         * @param {'rastro-veiculo'|'rastro-compra'} qual
         * @author Gui 🧟
         */
        disparaPopover(qual) {
            if (qual === "rastro-veiculo") {
                this.$refs.popupRastro.doOpen();
                setTimeout(() => {
                    this.$refs.popupRastro.doClose();
                }, 3000);
            } else if (qual === "rastro-compara") {
                this.$refs.popupCompara.doOpen();
                setTimeout(() => {
                    this.$refs.popupCompara.doClose();
                }, 3000);
            }
        },

        /**
         * @description Método para verificar se período
         * 	selecionado é valido para buscar o rastro
         * @return {boolean} - Retorna true caso a requição possa
         * 	ser realizada e false caso não possa
         * @author Vitor Hugo 🐨
         */
        verificaPeriodo() {
            if (!this.modelPeriodo || this.modelPeriodo.length < 2) return true;
            this.intervalo = "7";
            var com = DateTime.fromFormat(this.modelPeriodo[0], "dd/LL/yyyy");
            var fim = DateTime.fromFormat(this.modelPeriodo[1], "dd/LL/yyyy");
            var dif = fim.diff(com, "days").toObject();
            if (dif.days > this.intervalo - 1) {
                this.msgErroPeriodo = "Período máximo de 7 dias";
                this.$refs.popoverPeriodo.doOpen();
                setTimeout(() => {
                    if (this.$refs.popoverPeriodo) this.$refs.popoverPeriodo.doClose();
                }, 3000);
                return false;
            }
            return true;
        },

        /**
         * @param {boolean} tudo - se é pra resetar tudo mesmo ou só de leve
         * @description Como só existe um elemento de modal/popUp/treco,
         * pode acontecer delx já possuir os valores do último
         * veículo que foi exibido, então essa função só garante
         * que as informações exibidas estão corretas
         */
        resetaAsCoisas(tudo = false) {
            if(this.resetarParadasRastros){
                this.mostraParadas = false;
                this.mostraRastro = false;
            }
            this.modelVeiculoCompara = 0;
            this.compararVeiculo = false;
            this.mostraEventos = false;
            this.mostraVelocidade = false;

            this.mostraAcPortas = false;
            this.acionamentosParados = false;
            this.eventoSelecionado = "";
            this.compararCom = "";
            this.portaAcMostrar = "";
            if (tudo) {
                this.checkDetalhado = null;
                // this.velocidade = 40;
                // this.initialValueVelocidade = 40;
                // this.nParadas = 5;
                // this.initialValueParadas = 5
                this.horaInicio = "00:00";
                this.horaFim = "23:59";
            }
            this.$emit("limpa-eventos");
        },

        /**
         * @param {number?} hodometro
         * @description formata o hodometro como string e pá
         * @returns {string} hodometro arredendado a 2 casas + Km
         */
        formataHodometro(hodometro) {
            if (typeof hodometro !== "number") {
                return "";
            }
            var hkm = hodometro / 1000;
            return `${hkm.toFixed(0)}Km`;
        },
        formataHorimetro(horimetro){
            if (typeof horimetro !== "number") {
                return "";
            }
            return horimetro + 'H'
        },

        formataPercorrido(valor) {
            if (typeof valor !== "number") {
                return "";
            }
            return `${valor}Km`;
        },

        /**
         * @param {HTMLElement} elmnt - elemento que deve "flutuar"
         * @description Adiciona a funcionalidade de ser arrastavel para um
         * elemento html, procura por um elemento com o mesmo nome e com o
         * adicionar de _header para ser onde vc arrasta o lance todo.
         * @link https://www.w3schools.com/howto/howto_js_draggable.asp
         */
        dragElement(elmnt) {
            var pos1 = 0,
                pos2 = 0,
                pos3 = 0,
                pos4 = 0;
            var elementID = `${elmnt.id}_draggable`;
            if (document.getElementById(elementID)) {
                // if present, the header is where you move the DIV from:
                document.getElementById(elementID).onmousedown = dragMouseDown;
            } else {
                // otherwise, move the DIV from anywhere inside the DIV:
                elmnt.onmousedown = dragMouseDown;
            }

            function dragMouseDown(e) {
                e = e || window.event;
                e.preventDefault();
                // get the mouse cursor position at startup:
                pos3 = e.clientX;
                pos4 = e.clientY;
                document.onmouseup = closeDragElement;
                // call a function whenever the cursor moves:
                document.onmousemove = elementDrag;
            }

            function elementDrag(e) {
                e = e || window.event;
                e.preventDefault();
                // calculate the new cursor position:
                pos1 = pos3 - e.clientX;
                pos2 = pos4 - e.clientY;
                pos3 = e.clientX;
                pos4 = e.clientY;
                // set the element's new position:
                elmnt.style.top = elmnt.offsetTop - pos2 + "px";
                elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
            }

            function closeDragElement() {
                // stop moving when mouse button is released:
                document.onmouseup = null;
                document.onmousemove = null;
            }
        },

        consultaKmPercorrido(newValue) {
            let uri = `${this.baseUri}km/percorrido`;
            let obj = {
                ...newValue,
                placa: this.veiculo.placa,
                detalhado: this.checkDetalhado ?? false
            };
            this.loadingPercorridos = true
            new HttpRequest().Post(uri, obj)
                .then(({ data, status }) => {
                    if (status) {
                        this.veiculo.percorrido = data.total;
                        this.veiculo.percorridoCAN = data.totalcan;
                        this.veiculo.temHorimetro = data.temHorimetro;
                        this.$forceUpdate();
                    }
                })
                .catch((e) => { '' })
                .finally(() => {
                    this.loadingPercorridos = false
                })
        },

        changePeriod(forceStartTime, forceEndTime) {
            const today = DateTime.now().toFormat("dd/LL/yyyy");

            const startDateString = this.modelPeriodo ? this.modelPeriodo[0] : today;
            const endDateString = this.modelPeriodo ? this.modelPeriodo[1] :today;

            const start = DateTime.fromFormat(`${startDateString} ${forceStartTime ?? this.horaInicio}`, "dd/LL/yyyy HH:mm");
            const end = DateTime.fromFormat(`${endDateString} ${forceEndTime ?? this.horaFim}`, "dd/LL/yyyy HH:mm");

            this.$emit('change-interval', start, end);
        },
    
        fechaInfo() {
            this.resetarParadasRastros = true;
            this.$emit('fecha-info');
        }
    },

    watch: {
        /**
         * @description controla o valor da variavel abriuViagem
         * para controlar se minimiza ou máxima infoVeiculo
         * @author Otávio 🦆 
         */
        abriuViagem(){
            if(this.abriuViagem == true){
                this.showInfoVeiculo = false;
                this.titleVerInfoVeiculo = 'Maximizar informações do veículo';
                this.iconBotaoMinimizaInfoVeiculo = mdiWindowMaximize;
            }else{
                this.titleVerInfoVeiculo = 'Minimizar informações do veículo';
                this.iconBotaoMinimizaInfoVeiculo = mdiMinus;
                this.showInfoVeiculo = true;
            }
            this.emiteMinimizar();
        },
        portaAcMostrar() {
            if (this.mostraAcPortas) this.emiteAcPortas();
        },
        voltarViagem() {
            if(this.voltarViagem){
                this.visualizaViagens();
            }
        },
        horaFim() {
            this.resetaAsCoisas();
            this.changePeriod();
        },

        horaInicio() {
            this.resetaAsCoisas();
            this.changePeriod();
        },

        modelPeriodo() {
            this.resetaAsCoisas();
            this.changePeriod();
        },

        mostraEventos(newValue) {
            if (!newValue) {
                this.eventoSelecionado = "";
            }
        },

        /**
         * @description Mesma ideia por traz desses watchers dos checks.
         * Caso o novo valor for falso, emite um evento avisando que as
         * informações desse campo devem ser removidas do mapa.
         * Se for positivo, faz uma consulta ao back-end pra pegar
         * os eventos necessários
         * @author Gui 🍺
         */
        mostraRastro(newValue) {
            this.compararCom = "";
            this.compararVeiculo = false;
            if (newValue) {
                this.getRastroVeiculo();
            } else {
                this.$emit("limpa-rastro-veiculo");
            }
        },

        compararCom(newValue) {
            if (newValue) {
                this.getRastroVeiculo();
            } else {
                this.$emit("remove-comparacao");
            }
        },

        /**
         * @fires excesso-velocidade
         * @fires limpa-excesso-velocidade
         */
        mostraVelocidade(newValue) {
            if (newValue) this.GetExcessoVelocidade();
            else this.$emit("limpa-excesso-velocidade");
        },

        mostraParadas(newVal) {
            if (newVal) this.getParadas();
            else this.$emit("limpa-paradas-veiculo");
        },

        mostraAcPortas(newValue) {
            if (newValue) this.getAcPortas();
            else this.$emit("limpa-ac-portas");
        },

        eventoSelecionado(newValue) {
            if (newValue) {
                this.getEventos();
            } else {
                this.$emit("limpa-eventos");
            }
        },

        compararVeiculo(newValue) {
            if (!newValue) {
                this.compararCom = "";
            }
        },

        datas(newValue) {
            let iniValido = newValue.dataIni.length === 19;
            let fimValido = newValue.dataFim.length === 19;
            if (iniValido && fimValido) {
                let format = "dd/LL/yyyy HH:mm:ss";
                let di = DateTime.fromFormat(newValue.dataIni, format);
                let df = DateTime.fromFormat(newValue.dataFim, format);
                this.dataErrada = di >= df;
                if (!this.dataErrada) {
                    this.consultaKmPercorrido(newValue);
                    this.getAcPortas();
                } else {
                    this.msgErroPeriodo = "Data inicial deve ser menor que a data final";
                    this.$refs.popoverPeriodo.doOpen();
                    setTimeout(() => {
                        if (this.$refs.popoverPeriodo)
                            this.$refs.popoverPeriodo.doClose();
                    }, 3000);
                }
            }
        },

        checkDetalhado(newValue) {
            if (newValue != null) {
                this.resetaAsCoisas();
                this.consultaKmPercorrido(this.datas);

                if (newValue) {
                    this.changePeriod();
                } else {
                    this.changePeriod('00:00', '23:59');
                }
                
            }
        },
    },

    computed: {
        datas() {
            if (!this.modelPeriodo || this.modelPeriodo.length < 2) {
                return {
                    dataIni:
                        DateTime.now().toFormat("dd/LL/yyyy") +
                        " " +
                        this.horaInicio +
                        ":00",
                    dataFim:
                        DateTime.now().toFormat("dd/LL/yyyy") +
                        " " +
                        this.horaFim +
                        ":59",
                };
            }
            if (!this.checkDetalhado) {
                return {
                    dataIni: this.modelPeriodo[0] + " 00:00:00",
                    dataFim: this.modelPeriodo[1] + " 23:59:59",
                };
            }
            return {
                dataIni: this.modelPeriodo[0] + " " + this.horaInicio + ":00",
                dataFim: this.modelPeriodo[1] + " " + this.horaFim + ":59",
            };
        },

        verbloqueio() {
            if (typeof this.permissoes.mapbloqueio === "undefined") return false;
            return this.permissoes.mapbloqueio.ppvisualizar && this.temBloqueio;
        },

        podebloquear() {
            if (typeof this.permissoes.mapbloqueio === "undefined") return false;
            return this.permissoes.mapbloqueio.ppeditar;
        },

        possuiEaglePerformance() {
            var temPerformance = Object.keys(this.getModulos()).includes("performance");
            return temPerformance;
        },

        possuiFinderPlus() {
            var temFinderPlus = Object.keys(this.getModulos()).includes("finder/plus");
            return temFinderPlus;
        },

        /**
         * O value dos objetos esta ligado ao bimotivodetransmicao,
         * segue ao lado o código do alerta no tipo_alerta
         */
        optEventosVeiculos() {
            return [
                {
                    value: 405,
                    description: "Colisão",
                },
                {
                    value: 82,
                    description: "Freada brusca", // tacodigo = 9
                },
                {
                    value: 80,
                    description: "Aceleração brusca", // tacodigo = 15
                },
                {
                    value: 88,
                    description: "Freada brusca em curva", // tacodigo = 16
                },
                {
                    value: 87,
                    description: "Curva em alta velocidade", // tacodigo = 17
                },
                {
                    value: 89,
                    description: "Aceleração brusca em curva", // tacodigo = 18
                },
            ];
        },
    },

    mounted() {
        let uri = this.baseUri + "tipos/alertas";
        new HttpRequest().Get(uri).then((data) => {
            this.optSelectTipoAlerta = data.data?.tipos;
        });
    },

    beforeDestroy() {
        this.seguirVeiculo(true);
    },
});
</script>

<style lang="scss" scoped>
#viewVeiculo {
    position: absolute;
    z-index: 295;
    top: 0.1%;
    left: 0.3%;
    [id$="_draggable"] {
        cursor: move;
    }
    .tamanhoFonteInfo {
        font-size: 11px !important;
        font-weight: bold;
    }

    .vertical-center {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }


    .custom-control-label{
        font-size: 4px;
    }

    .displayFlex {
        display: flex;
    }

    .displayFlexInfoMinimizada {
        display: flex;
        flex-direction: column;
    }

    .divBotaoShow {
        display: flex;
        justify-content: end;
        margin-right: 0px;
        height: 50px;
    }

    .marginBotoesMinimizados {
        margin-left: 0px;
    }
    .divBotaoFecharMinimizar {
        height: 180px;
        width: 50px;
    }
    .botaoMinimizar {
        width: 100%;
        margin-bottom: 10px;
        margin-left: -5px;        
    }
    .botaoFechar{
        margin-top: -6px;
        width: 48px; 
        margin-left: -9px;
    }
    .label-senha-bloqueio {
        font-weight: bold;
    }

    .check-slider {
        width: 90px;
        text-align: left;
    }

    .sliderVeiculo {
        width: 120px;
    }

    .divViewVeiculoMinimizada {
        margin-top: 0px;
        width: 50px !important;
        // height: 50px;
        display: flex;
        flex-direction: column;
    }

    .divBotaoMinimiza{
        margin-top: 0px;
        margin-right: 5px;
    }
    .divViewVeiculoId {
        margin-top: 0px;
        width: 50px !important;
        height: -50px;
        display: flex;
        flex-direction: column;
    }
    .displayzin {
        font-size: 11px;
        text-align: left;
        width: 45px;
    }

    .loadingInfo {
        background-color: white;
        height: 45px;
        width: 290px;   
        border-radius: 3px;
    }

    .botaoEsconderInfo {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        margin-left: -10px;
        align-items: center;
        margin-bottom: 10px;
        transform: rotate(90deg);
        white-space: nowrap;
    }

    .divBotaoFecharMinimizar {
        display: flex !important;
        margin-top: 250px;
        flex-direction: column !important;
        vertical-align: middle;
    }

    .botaoInfoAlerta {
        display: flex;
    }

    .viewVeiculo {
        margin-top: 4px;
        padding: 5px;
        background-color: white;
        min-height: 400px;
        width: 290px;
        border-radius: 3px;
    }

    .divInputTempo {
        padding-top: 1px;
        width: 185px;
    }

    .divPeriodos {
        display: flex;
    }

    .inputTime {
        height: 25px;
        width: 80px;
        text-align: center;
    }

    .botaoRastro {
        width: 60px;
        height: 20px;
    }

    .botaoPrincipal {
        width: 130px;
        height: 25px;
    }

    .seletorForm {
        text-align: center;
        width: 180px;
    }

    .botoesRastro {
        width: 180px;
    }

    .botaoPorta {
        width: 46px;
    }

    .cellTable {
        height: 20px;
        border-bottom: 1px solid black;
        width: 120px;
    }

    .tiltable {
        width: 122px;
        border-bottom: 1px solid black;
        height: 20px;
        padding-right: 4px;
        border-right: 1px solid black;
    }

    .tituloso {
        background-color: #edf2f6;
    }

    .tiltablebot {
        width: 122px;
        padding-right: 4px;
        border-right: 1px solid black;
    }

    .awntable {
        padding-left: 3px;
    }

    .awntablebot {
        padding-left: 3px;
    }
    .divCabecalho{
        height: 30px;
    }
    .infoVeiculo {
        background-color: #edf2f6;
        border: solid 1px black;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
    }

    .divisoria {
        margin-left: 5px;
        border-left: 2px solid gray;
        padding: 4px;
    }

    .datePicker {
        max-width: 250px;
    }

    .botaoPosicoes {
        background-color: #007bff;
        color: white;
    }

    .wordWrap {
        white-space: nowrap;
        width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    hr {
        margin: 3px;
    }

    .esconder {
        display: none !important;
    }

    .botaoPorta {
        width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 1;
        padding: 0;
        margin-top: 4px;
    }

    .tamanhoFonteInfo {
        font-size: 10px;
    }

    .spanParado{
        margin-left: 2px;
        margin-top: -2px;
        line-height: 1;
    }
}
</style>
