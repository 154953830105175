import { AuthService } from '@/Services/auth/Auth.Service'
import { RouteConfig } from 'vue-router'
import Cadastros from '@/views/Children.vue'
import Relatorio from '@/views/Children.vue'
import NaoMenu from '@/views/Children.vue';
import Layout from '@/components/Atom/Layout/Layout.vue'


import listagemGrupoColaboradores from '@/views/MapaPlus/Cadastros/GrupoColaboradores/ListagemGrupoColaboradores.vue'
import cadastroGrupoColaboradores from '@/views/MapaPlus/Cadastros/GrupoColaboradores/CadastroGrupoColaboradores.vue'

import AcionamentoSensores from '../Relatorios/AcionamentoSensores.vue'
import ListagemRegioes from '@/views/MapaPlus/Cadastros/Regioes/ListagemRegioes.vue'
import CadastroRegioes from '@/views/MapaPlus/Cadastros/Regioes/CadastroRegioes.vue'
import TempoFuncionamento from '@/views/MapaPlus/Relatorios/TempoFuncionamento.vue'
import TempoIgnicaoLigada from '@/views/MapaPlus/Relatorios/TempoIgnicaoLigada.vue'
import OcorrenciaEmPonto from '@/views/MapaPlus/Relatorios/OcorrenciaEmPonto.vue'
import OperacaoRotas from '@/views/MapaPlus/Relatorios/OperacaoRotas.vue'
import SemComunicacao from '@/views/MapaPlus/Relatorios/SemComunicacao.vue'
import DesperdicioCombustivel from '@/views/MapaPlus/Relatorios/DesperdicioCombustivel.vue'
import ResumoFrota from '@/views/MapaPlus/Relatorios/ResumoFrota.vue'
import ResumoDiario from '@/views/MapaPlus/Relatorios/ResumoDiario.vue'
import Alertas from '@/views/MapaPlus/Alertas/Alertas.vue'
import listagemGrupoVeiculo from '@/views/MapaPlus/Cadastros/GrupoVeiculos/ListagemGrupoVeiculos.vue'
import CadastroGrupoVeiculo from '@/views/MapaPlus/Cadastros/GrupoVeiculos/CadastroGrupoVeiculos.vue'
import ListagemPontos from '@/views/MapaPlus/Cadastros/Pontos/ListagemPontos.vue'
import CadastroPontos from '@/views/MapaPlus/Cadastros/Pontos/CadastroPontos.vue'
import importarPontos from '@/views/MapaPlus/Cadastros/Pontos/ImportarPontos.vue'
import AlertasRelatorio from '@/views/Performance/Relatorios/Alertas.vue'
// import Alerta from '@/views/MapaPlus/Relatorios/Alerta.vue'
import ExcessoDeVelocidade from '@/views/MapaPlus/Relatorios/ExcessoDeVelocidade.vue'
import HistoricoDePosicoes from '@/views/MapaPlus/Relatorios/HistoricoDePosicoes.vue'
import ResumoHorimetro from '@/views/MapaPlus/Relatorios/ResumoHorimetro.vue'
import KmsPercorrido from '@/views/MapaPlus/Relatorios/KmsPercorrido.vue'
import TempoParado from '@/views/MapaPlus/Relatorios/TempoParado.vue'
import Regiao      from '@/views/MapaPlus/Relatorios/Regiao.vue'
import Notificacao from '@/views/MapaPlus/Notificacao.vue'
import DashboardFinderPlus from '@/views/MapaPlus/Dashboard/DashboardFinderPlus.vue'
import EventosVeiculo from '@/views/MapaPlus/Relatorios/EventosVeiculo.vue'

export const ifAuthenticated = (_to: any, _from: any, next: any) => {
	if (new AuthService().isAuthenticated()) {
		next()
		return
	}
	next('/login')
}

export const mapaPlusRouter: RouteConfig = {
	path: '/finder/plus',
	name: 'mapaPlus',
	beforeEnter: ifAuthenticated,
	meta: {
		requiresAuth: true,
		Module: 'finder/plus',
		breadcrumbName:'Finder Plus'
	},
	component: Layout,
	children: [
		{
			path: 'cadastros', 
			meta: 
			{ 
				Module: 'finder/plus', 
				breadcrumbName: 'Cadastros' 
			}, 
			components: 
			{ 
				content: Cadastros 
			}, 
			children: [
				{ 
					path: 'grupos/motoristas',
					name: 'listarGrupoMotorista',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Grupo de colaboradores'
					}, 
					component: listagemGrupoColaboradores
				},
				{
					path: 'grupos/motoristas/novo/:id?',
					name: 'cadastrarGrupoMotorista',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Grupo de colaboradores'
					},
					component: cadastroGrupoColaboradores
				},
				{
					path: 'grupos/veiculo',
					name:'listarGrupoVeiculos',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Grupo de Veículos'
					},
					component: listagemGrupoVeiculo
				},
				{
					path: 'grupos/veiculos/editar/:id',
					name:'editarGrupoVeiculos',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Grupo de Veículos'
					},
					component: CadastroGrupoVeiculo
				},
				{
					path: 'importar/pontos',
					name: 'importarPontos',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Importar Pontos'
					},
					component: importarPontos
				},
				{
					path: 'pontos',
					name: 'listarPonto',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Pontos'
					},
					component: ListagemPontos
				},
				{
					path: 'pontos/cadastrar/:id?',
					name: 'cadastrarPonto',
					meta:
					{ 
						Module: 'finder/plus',
						breadcrumbName: 'Pontos'
					},
					component: CadastroPontos
				},
				{
					path: 'regioes',
					name: 'listagemRegioes',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Regiões'
					},
					component: ListagemRegioes
				},
				{
					path: 'regioes/novo/:id?',
					name: 'cadastroRegioes',
					meta:
					{
						Module: 'finder/plus',
						breadcrumbName: 'Regiões'
					},
					component: CadastroRegioes
				},
			]
		},
		{
			path: '', 
			meta: {
				Module: 'finder/plus',
				breadcrumbName: 'Relatorios'
			},
			components: {
				content: NaoMenu
			},
			children: [
				// Página da Dashboard Finder Plus (Não achei uma maneira melhor)
				{
					path: '',
					name: 'DashboardFinderPlus',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Dashboard Finder Plus'
					},
					component: DashboardFinderPlus
				},
				// Alertas
				{
					path: 'alertas/:cli?/:placa?',
					name: 'nao/menu/alertas',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Alertas'
					}, component: Alertas
				},
				{
					path: 'notificacoes',
					name: 'notificacoes',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Notificações'
					},
					component: Notificacao
				},
			]
		},
		{
			path: 'relatorios',
			meta:
			{
				Module: 'finder/plus',
				breadcrumbName: 'Relatórios'
			},
			components:
			{
				content: Relatorio
			},
			children: [
				{ 
					path: 'acionamento/sensores',
					name:'AcionamentoDeSensores',
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Acionamento de portas'
					},
					component: AcionamentoSensores 
				},

				// Alerta 
				{
					path: 'alertas',
					name: 'RelatorioAlertas',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Alertas'
					},
					component: AlertasRelatorio
				},
				{ 
					path: 'desperdicio/combustivel',
					name:'DesperdicioDeCombustivel',
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Desperdício de combustível'
					},
					component: DesperdicioCombustivel 
				},
				{
					path: 'excesso/velocidade',
					name: 'exessoVelocidade',
					meta: {
						Module: 'finder/plus',
						breadcrumbName:'Excesso de velocidade'
					},
					component: ExcessoDeVelocidade
				},
				{
					path: 'historico/posicoes',
					name: 'historicoDePosicoes',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Histórico de posições'
					},
					component: HistoricoDePosicoes
				},
				{
					path: 'kms/percorrido',
					name: 'KmsPercorrido',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Quilômetros percorridos'
					},
					component: KmsPercorrido
				},
				{ 
					path: 'ocorrencia/pontos', 
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Ocorrência em ponto'
					},
					component: OcorrenciaEmPonto 
				},
				{
					path: 'operacao/rotas',
					name: 'OperacaoRotas',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Operação de Rotas'
					},
					component: OperacaoRotas
				},
				{
					path: 'regiao',
					name: 'relatorioRegiao',
					meta: {
						Module: 'finder/plus',
						breadcrumbName:'Região'
					},
					component: Regiao
				},
				{
					path: 'resumo/horimetro',
					name: 'resumoHorimetro',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Resumo horímetro'
					},
					component: ResumoHorimetro
				},
				{
					path: 'resumo/diario',
					name: 'relatarResumoDiario',
					meta: {
						Module: 'finder/plus',
						breadcrumbName: 'Resumo diário'
					},
					component: ResumoDiario
				},
				{ 
					path: 'resumo/frota', 
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Resumo de frota' 
					}, 
					component: ResumoFrota
				},
				{ 
					path: 'comunicacao', 
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Sem comunicação'
					},
					component: SemComunicacao 
				},
				{ 
					path: 'tempo/funcionamento', 
					meta: 
					{
						Module: 'finder/plus', 
						breadcrumbName: 'Tempo de funcionamento'
					},
					component: TempoFuncionamento
				},
				{ 
					path: 'tempo/ignicao/ligada', 
					meta: 
					{ 
						Module: 'finder/plus', 
						breadcrumbName: 'Tempo de ignição ligada'
					},
					component: TempoIgnicaoLigada
				},
				{
					path: 'tempo/parado',
					name: 'tempoParado',
					meta: {
						Module: 'finder/plus',
						breadcrumbName:'Tempo parado'
					},
					component: TempoParado
				},
				{
					path: 'eventos/veiculo',
					name: 'eventosVeiculo',
					meta: {
						Module: 'finder/plus',
						breadcrumbName:'Eventos do Veículo'
					},
					component: EventosVeiculo
				},
			]
		},
	]
}