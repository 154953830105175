export var listagem = {
    
    botoesFiltroTipo: [
        {value: 'F', width: '32.5%', isSelected: false, text:'Física',   icon  :''},
        {value: 'J', width: '32.5%', isSelected: false, text:'Jurídica', 'icon':''},
        {value: 'T', width: '32.5%', isSelected: true,  text:'Todos',    'icon':''},
    ],

    botoesFiltroStatus:[
        {value: 'A', width: '32.5%', isSelected: true, text:'Ativo',    icon  :''},
        {value: 'I', width: '32.5%', isSelected: false, text:'Inativos', 'icon':''},
        {value: 'T', width: '32.5%', isSelected: false,  text:'Todos',    'icon':''},
    ],

    titulosListagem:[
        {name: 'Nome',     number: 'clfantasia'},
        {name: 'Cidade',   number: 'cinome'},
        {name: 'Endereço', number: 'endereco'},
        {name: 'Telefone', number: 'telefone'},
        {name: 'E-mail',   number: 'email'},
        {name: 'Documento', number: 'cldocumento'}
    ],
}

export var cadastro = function () {
    return {
        buttonsFiltroTipoCliente: [
            {value: 'F', width: '48%', isSelected: false, text:'Fisica'},
            {value: 'J', width: '48%', isSelected: true,  text:'Jurídica'},
        ],
    
        optSelectSegmentoEmpresa:[
            {value: 'T', description: 'Transporte'},
            {value: 'C', description: 'Transporte Coletivo'},
        ],
    
        optSelectServicoIntegracao:[
            {value: 'F', description: 'FTP'},
            {value: 'W', description: 'Webservice'}
        ],
    
        cliente:{
            clcodigo        : '',
            clnome          : '',
            clfantasia      : '',
            cldocumento2    : '',
            cllogradouro    : '',
            clnumero        : '',
            clcomplemento   : '',
            clbairro        : '',
            clfone          : '',
            clemail         : '',
            clcidade        : '',
            cllongitude     : -52.6269,
            cltipo          : '',
            cldocumento     : '',
            cllatitude      : -27.1000,
            clstatus        : '',
            clsite          : '',
            clurlbaseinterfoc : '',
            clcodigointerfocfilial : '',
            clcodigointerfocmatriz : '',
            clsegmento      : '',
            cllogo          : '',
            clraio          : '',
            clapikey        : '',
            clprospeccao    : false,
            cliniciovigencia: '',
            clmodotratamentorota : '',
            clrotaagruparpedidos:'',
            classinaturadriver:'',
            clcoletadriver:'',
        },
    
        clienteIntegracao:{
            cicodigo  : '',
            ciusuario : '',
            cisenha   : '',
            cichave   : '',
            citipo    : '',
            cihost    : '',
            ciporta   : '',
            cipasta   : '',
            ciempresaintegracao : '',
        },
    }
}