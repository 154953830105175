<template>
  <tableRelatorio>
    <slot slot="thead">
      <th style="width: 28%;">Linha</th>
      <th style="width: 6%;">Veiculo</th>
      <th style="width: 5%;">Prefixo</th>
      <th style="width: 10%;">Colaborador</th>
      <th style="width: 5%;">Turno</th>
      <th style="width: 12%;">Escala</th>
      <th style="width: 10%;">Pesagem total</th>
      <th style="width: 7%;">Tempo(PxR)</th>
      <th style="width: 7%;">Intervalo</th>
      <th style="width: 5%;">Percorrido</th>
      <th style="width: 1%;">Progresso</th>
      <th style="width: 1%;">Status</th>
    </slot>

    <slot slot="tbody">
      <statusInformation colspanForTd='12' v-if="tamanhoRelatorio == 0" typeBar="tr" :statusBar="statusbar">
      </statusInformation>

      <template v-for="(data, d) in dadosRelatorio">
        <br :key="d + '_br'">

        <!-- Badge da data -->
        <tr :key="d + 'data'" class="trDataTabela ">
          <td colspan="2" class="dataTabela cinzaClaro" style="width: 5%; background-color:   #777777">
            {{ d }}
          </td>
        </tr>

        <template v-for="(linha, l) in data">
          <!-- Dados principais -->
          <tr :key="l + 'dadosTabela' + d" v-b-toggle="(linha.codigo + '' + l)" @click="linha.clicado = !linha.clicado"
            :class="linha.classeTr == 'trEmAtrasoTolerado' ?
              'dadosTabela sem-borda trEmAtrasoTolerado' :
              linha.classeTr == 'trAtrasada' ?
                'dadosTabela sem-borda trAtrasada' :
                linha.classeTr == 'trDeAcordoComOProgramado' ?
                  'dadosTabela sem-borda trDeAcordoComOProgramado' :
                  'dadosTabela cinzaClaro sem-borda'">
            <td class="fonteTabelaExterna">
              <div class="chevronTop">
                <b-icon :id="actived" class="h5 mb-0 fonteTabelaExterna" shift-v="-6" shift-h="0"
                  style="font-weight: bold;" :icon="linha.clicado ?
                    'chevron-down' : 'chevron-right'" />
              </div>
              <div class="fonteTabelaExterna">
                {{ linha.linha }}
              </div>
            </td>
            <td class="fonteTabelaExterna ">{{ linha.placa }}</td>
            <td class="fonteTabelaExterna ">{{ linha.prefixo }}</td>
            <td class="fonteTabelaExterna ">{{ linha.motorista }}</td>
            <td class="fonteTabelaExterna ">{{ linha.turno }}</td>
            <td class="fonteTabelaExterna ">{{ linha.escala }}</td>
            <td class="fonteTabelaExterna ">{{ linha.pesagem.length <= 0 ? ('0') : (linha.pesagem) }}</td>
            <td class="fonteTabelaExterna ">{{ linha.tempoProg }} / {{ linha.tempoReal }}</td>
            <td class="fonteTabelaExterna ">{{ linha.intervalo }}</td>
            <td class="fonteTabelaExterna ">{{ linha.distanciaProg }} / {{ linha.distanciaReal }} </td>
            <td class="fonteTabelaExterna ">{{ linha.progresso + '%' }}</td>
            <td class="fonteTabelaExterna ">{{ linha.rcstatus }}</td>
          </tr>

          <!-- Chevron, dados ocultos  -->
          <tr :key="l + 'chevron' + d">
            <td colspan="12" class="sem-borda">
              <b-collapse :id="(linha.codigo + '' + l)">
          <tr :key="l + 'chevronHeader' + d" class="dadosChevronHeader cinzaEscuro">
            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 400px;">Ordem</th>
            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 5%">Situação</th>
            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Ponto</th>
            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Horário</th>
            <th class="sem-borda fonteTabelaInterna cinzaEscuro" style="width: 15%">Tempo Parado</th>
            <th class="fonteTabelaInterna" style="width: 40%">Km</th>
          </tr>

          <tr v-for="(dadosLinha, dl) in linha.itensRota" :key="dl + 'dadosLinha'"
            class="dadosChevron fonteTabelaInterna" style="background: #eaecf3;">
            <td class="descPontoDL cinzaClaro fonteTabelaInterna">{{ dadosLinha.ordenacao }}</td>
            <td class="descPontoDL cinzaClaro fonteTabelaInterna"
              :class="dadosLinha.status == 'E' ? 'cor-verde' : 'cor-vermelha'">
              <baseIcon size='18' :icon="dadosLinha.status == 'E' ? mdiMapMarkerCheck : mdiMapMarkerRemoveVariant" />
            </td>
            <td class="cinzaClaro fonteTabelaInterna">{{ dadosLinha.descricaoPonto }}</td>
            <td class="descPontoDL cinzaClaro fonteTabelaInterna">{{ dadosLinha.horario }}</td>
            <td class="cinzaClaro descPontoDL fonteTabelaInterna">{{ dadosLinha.tempoParado }}</td>
            <td class="cinzaClaro fonteTabelaInterna">{{ dadosLinha.kms ? dadosLinha.kms + ' Km' : '' }}
            </td>
          </tr>

          </b-collapse>
          </td>
          </tr>
        </template>
      </template>
    </slot>
  </tableRelatorio>
</template>
<script>
import Vue from 'vue'
import {
  mdiMapMarkerCheck,
  mdiMapMarkerRemoveVariant
} from '@mdi/js'

export default Vue.extend({
  name: 'TabelaDetalhesLinhasComPontos',

  components: {
    baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
    'tableRelatorio': require('@/components/Atom/Table/TableRelatorio').default,
    'statusInformation': require('@/components/Atom/StatusInformation/StatusInformation').default,
  },

  data() {
    return {
      mdiMapMarkerCheck: mdiMapMarkerCheck,
      mdiMapMarkerRemoveVariant: mdiMapMarkerRemoveVariant
    }
  },

  props: {
    tamanhoRelatorio: {
      type: Number,
      required: true,
      default: 0,
    },
    statusbar: {
      type: String,
      required: true,
      default: 'info'
    },
    dadosRelatorio: {
      required: true,
    },
    actived: {
      type: Boolean,
      required: true,
      default: false,
    },
  }
})
</script>

<style lang="css" scoped>
.trDataTabela {
  background: #fff !important;
}

.dataTabela {
  min-width: 30%;
  text-align: center;
  vertical-align: middle;
  background: #777777;
  height: 35px;
  margin-top: 20px !important;
  color: #fff;
  font-weight: 500;
  font-size: 1 !important;
}

.cinzaClaro {
  background: #dbdde2;
}

.trEmAtrasoTolerado {
  background-color: #f9c576 !important;
}

.dadosTabela {
  td {
    border: 1px solid #d5d5d5;
  }
}

.sem-borda {
  padding: 0px;
  border: none !important;
}

.trAtrasada {
  background-color: #f0a7a6 !important;
}

.trDeAcordoComOProgramado {
  background-color: #b5cd8e !important;
}

.fonteTabelaExterna {
  font-size: 12px !important;
}

.chevronTop {
  vertical-align: top;
  display: block;
}

.fonteTabelaInterna {
  font-size: 11px !important;
}

.cinzaEscuro {
  background: #b1b2b5;
}

.descPontoDL {
  text-align: center;
}

.dadosChevron {
  background: #fff !important;
  border-bottom: 1px solid #d5d5d5;
  border-left: 1px solid #d5d5d5;
  border-right: 1px solid #d5d5d5;
  padding-left: 0px;
}
</style>
