/**
Componente compoe a tabela de monitoramento de linhas, contém as informações do segundo SlideUpDown
*/
<template>
    <div class="col-sm-12 nopadding divMasterTimeline">
        <div class="pb-1" style="background-color: #eeeeee;">
            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiTransitConnectionVariant" />
                    <span style="font-size: 12px; font-weight: bold;">Detalhes</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Status:</span>
                    <div style="user-select: none;">
                        <b-col
                            v-b-popover.hover.top="arrayDados.status == 'I' ? ('Iniciada') : (arrayDados.status == 'F' ? ('Finalizada') : (arrayDados.status == 'P' ? ('Pendente') : (arrayDados.status == 'C' ? ('Cancelada') : (''))))"
                            class="font-bold border border-warning-subtle bolinhaAzul">
                            <p style="margin-top: -4px;">
                                {{ arrayDados.status }}
                            </p>
                        </b-col>
                    </div>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Percorrido:</span>
                    <span style="font-weight: bold; font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.percorrido + ' km'">
                        {{ arrayDados.percorrido }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span style="font-weight: bold; font-size: 12px;" v-b-popover.hover.top="arrayDados.turno">
                        {{ arrayDados.turno }}
                    </span>
                </div>
            </div>

            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiClockCheckOutline" />
                    <span class="font-bold" style="font-size: 12px;">Tempo em execução</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Tempo produtivo:</span>
                    <span class="font-bold" v-b-popover.hover.top="arrayDados.tempoExecucao.tempoProdutivo">
                        {{ arrayDados.tempoExecucao.tempoProdutivo }}
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Tempo improdutivo:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.tempoExecucao.tempoImprodutivo">
                        {{ arrayDados.tempoExecucao.tempoImprodutivo }}
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Tempo de execução:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.tempoExecucao.tempoExecucao">
                        {{ arrayDados.tempoExecucao.tempoExecucao }}
                    </span>
                </div>
            </div>

            <div class="row align-items-center p-1" style="border-bottom: 1px solid black;">
                <div class="col d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiCarTractionControl" />
                    <span class="font-bold" style="font-size: 12px;">Km produtivo / improdutivo</span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Km produtivo:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.kmProdutivo + ' km'">
                        {{ arrayDados.kmProdutivoImprodutivo.kmProdutivo }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Km improdutivo:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.kmImprodutivo + ' km'">
                        {{ arrayDados.kmProdutivoImprodutivo.kmImprodutivo }} km
                    </span>
                </div>

                <div class="col d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Média de consumo:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.kmProdutivoImprodutivo.mediaConsumo + ' km/L'">
                        {{ arrayDados.kmProdutivoImprodutivo.mediaConsumo }} km/L
                    </span>
                </div>
            </div>

            <div class="d-flex align-items-center p-1 col-sm-12" style="border-bottom: 1px solid black;">
                <div class="col-sm-3 d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiBusMarker" />
                    <span class="font-bold" style="font-size: 12px;">Transbordo</span>
                </div>

                <div class="col-sm-3 d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Descarga (ponto):</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.totalDescargas + ' pontos'">
                        {{ arrayDados.totalDescargas }} pontos
                    </span>
                </div>

                <div class="col-sm-5 d-flex align-items-center">
                    <span class="mr-2" style="font-size: 12px;">Peso:</span>
                    <span class="font-bold" style="font-size: 12px;"
                        v-b-popover.hover.top="arrayDados.rctpesagem + 'kg'">
                        {{ arrayDados.rctpesagem }} kg
                    </span>
                </div>
            </div>
            <!-- 
            <div class="d-flex align-items-center p-1 col-sm-12" style="border-bottom: 1px solid black;">
                <div class="col-sm-3 d-flex align-items-center">
                    <baseIcon size="18  " class="mr-2" style="color: #000" :icon="mdiAccountGroup" />
                    <span class="font-bold" style="font-size: 12px;">Ajudantes</span>
                </div>

                <div class="col-sm-9 d-flex align-items-center">
                    <span style="font-size: 12px;" v-b-popover.hover.top="'Yuri Santos'">
                        Yuri Santos
                    </span>
                </div>
            </div> -->

            <div v-if="arrayDados.totalPontos >= 1" class="col-sm-12 timeLineTime mt-3">
                <div class="d-flex justify-content-between align-items-center" style="border-bottom: 1px solid black;">
                    <!-- Posição que o veículo esta em relação aos pontos  -->
                    <div class="col-sm-12 thisIsTheBusao">
                        <div :class="'col-sm-12 timeLineBusao statusPontoProgress-' + rotas.statusOnibus"
                            :style="'width:' + rotas.posicaoBusao + '%'">
                            <!-- mdiBusSide -->
                            <baseIcon size='45' class="iconBusao animeBusao" :icon="mdiBusSide" />
                        </div>
                    </div>
                    <!-- Ícones dos pontos -->
                    <div class="col-sm-12 thisIsTimeLine" style="">
                        <ponto @pontoCriado="pontoCriado" :tamanho="(100 / rotas.totalPontos)"
                            :key="rotas.codigo + '|' + ponto.ponto" v-for="(  ponto  ) in rotas.pontos  "
                            :arrayDados="ponto">
                        </ponto>
                    </div>
                </div>
            </div>

            <div v-if="Object.keys(arrayDados.regioes).length > 0"
                style="background-color: white; border: 2px solid #a6cfff; margin: 5px">
                <p class="font-bold mt-1 ml-2" style="font-size: 12px; margin-bottom: -1px;">Setores:</p>

                <div class="d-flex justify-content-center align-items-center position-relative pb-2"
                    style="width: 100%; margin: auto;">
                    <div style="height: 1px; background-color: black; width: 100%;" />

                    <div v-for="(key, index) in Object.keys(arrayDados.regioes)" v-bind:key="index">
                        <div class="d-flex justify-content-center align-items-center position-relative">
                            <div v-if="arrayDados.regioes[key].informacoesRegiao != null">
                                <div @click="toggleCollapse(index)"
                                    class="d-flex justify-content-center align-items-center position-relative" :style="{
                                        'background-color': arrayDados.regioes[key].informacoesRegiao.corRegiao
                                    }" style="border-radius: 5px; padding: 5px; cursor: pointer; width: max-content;">
                                    <p class="font-bold m-0 text-truncate"
                                        style="text-align: center; font-size: 12px; color: black">
                                        {{ arrayDados.regioes[key].informacoesRegiao.nomeRegiao }}
                                    </p>
                                </div>

                                <b-collapse v-model="activeCollapseIndex[index]" :id="'collapse-' + index"
                                    class="position-absolute mt-1"
                                    style="z-index: 1050; width: max-content; max-width: 500px; background-color: white; border: 2px solid rgb(156 163 175);">

                                    <div v-if="arrayDados.regioes[key].informacoesRegiao.tempoNoSetor">
                                        <div class="p-2">
                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiClockCheckOutline" />
                                                    <p style="font-size: 13px">Tempo no setor: </p>
                                                    <p class="font-bold" style="font-size: 13px">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.tempoNoSetor }}</p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiCarTractionControl" />
                                                    <p style="font-size: 13px">Km percorrido: </p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{arrayDados.regioes[key].informacoesRegiao.kmPercorrido }} km</p>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiArrowCollapseRight" />
                                                    <p style="font-size: 13px">Entrada</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.dataHoraEntrada }}</p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <p style="font-size: 13px">Hodômetro:</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{arrayDados.regioes[key].informacoesRegiao.hodometroEntrada }}
                                                        Km/h</p>
                                                </div>
                                            </div>

                                            <div class="d-flex justify-content-between align-items-start">
                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <baseIcon size="17" style="color: #000"
                                                        :icon="mdiArrowCollapseLeft" />
                                                    <p style="font-size: 13px">Saída</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.dataHoraSaida }}</p>
                                                </div>

                                                <div class="d-flex justify-content-between align-items-start mr-3"
                                                    style="gap: 5px">
                                                    <p style="font-size: 13px">Hodômetro:</p>
                                                    <p style="font-size: 13px" class="font-bold">
                                                        {{ arrayDados.regioes[key].informacoesRegiao.hodometroSaida }} Km/h
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-for="(transbordo, index) in arrayDados.regioes[key].transbordos"
                                            style="margin-top: -8px;" :key="index">
                                            <div style="border-top: 2px solid rgb(156 163 175);"
                                                class="row justify-content-center align-items-center text-truncate">
                                                <baseIcon size="17" style="color: #000" :icon="mdiBusMarker"
                                                    class="ml-2" />
                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mb-3">Transbordo {{ index + 1 }}
                                                    </p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Ticket: </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">
                                                        {{ transbordo.ticket }}</p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Peso: </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">
                                                        {{ transbordo.peso }} Kg</p>
                                                </div>
                                                <div class="d-flex align-items-center pt-3 mx-2 text-truncate"
                                                    style="max-width: 150px"
                                                    v-b-popover.hover.top="'Descarga (ponto): ' + transbordo.descarga">
                                                    <p style="font-size: 13px" class="mr-2 mb-3">Descarga (ponto): </p>
                                                    <p style="font-size: 13px" class="font-bold mb-3">{{
                                                        transbordo.descarga }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="p-2">
                                            <p class="font-bold">Veículo ainda não passou na região</p>
                                        </div>
                                    </div>
                                </b-collapse>
                            </div>

                            <div v-if="arrayDados.regioes[key].informacoesRegiao != null && index < (Object.keys(arrayDados.regioes).length) - 1"
                                class="d-flex justify-content-center align-items-center position-relative">
                                <div style="height: 1px; background-color: black; width: 30px;" />

                                <div class="estrela-container">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="19" height="19"
                                        class="estrela">
                                        <polygon points="12 2 15 9 22 12 15 15 12 22 9 15 2 12 9 9 12 2" fill="black" />
                                    </svg>
                                </div>
                                <div style="height: 1px; background-color: black; width: 30px;" />
                            </div>
                        </div>
                    </div>

                    <div style="height: 1px; background-color: black; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mdiBusSide, mdiCarBack, mdiTransitConnectionVariant, mdiClockCheckOutline, mdiCarTractionControl, mdiBusMarker, mdiAccountGroup, mdiLogout, mdiLogin, mdiArrowCollapseRight, mdiArrowCollapseLeft } from '@mdi/js'
import Vue from 'vue'

export default Vue.extend({
    name: 'MonitoramentoLinhaSeparada',

    components: {
        baseIcon: require('@/components/Atom/Icon/BaseIcon.vue').default,
        'ponto': require('./MonitoramentoLinhaPonto').default
    },

    data() {
        return {
            mdiBusSide: mdiBusSide,
            mdiCarBack: mdiCarBack,
            actived: false,
            pontosCriados: 0,
            arrayPontos: [],
            rotas: this.arrayDados,
            mdiTransitConnectionVariant: mdiTransitConnectionVariant,
            mdiClockCheckOutline: mdiClockCheckOutline,
            mdiCarTractionControl: mdiCarTractionControl,
            mdiBusMarker: mdiBusMarker,
            mdiAccountGroup: mdiAccountGroup,
            mdiLogout: mdiLogout,
            mdiLogin: mdiLogin,
            popoverSetores: false,
            activeCollapseIndex: {},
            mdiArrowCollapseLeft: mdiArrowCollapseLeft,
            mdiArrowCollapseRight: mdiArrowCollapseRight
        }
    },

    props: {
        arrayDados: {
            type: Object,
            required: true,
            default: function () { return {} }
        },
    },

    methods: {
        pontoCriado(ponto) {
            if (this.pontosCriados == (this.rotas.totalPontos - 1)) {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.$emit('linhaSeparadaCriada', this)
            } else {
                this.arrayPontos[ponto.arrayDados.ponto] = ponto;
                this.pontosCriados++;
            }
        },

        abrirFecharPopoverSetor(setor) {
            alert(setor)
        },

        /**
         * Alterna o estado de um colapso específico e fecha os demais.
         *
         * @param {number} index - O índice do colapso que será aberto ou fechado.
         * @returns {void}
         * @author Yuri 🇧🇷
         */
        toggleCollapse(index) {
            this.$set(this.activeCollapseIndex, index, !this.activeCollapseIndex[index]);

            for (let key in this.activeCollapseIndex) {
                if (key != index) {
                    this.$set(this.activeCollapseIndex, key, false);
                }
            }
        },
    },

    mounted() {
        this.$emit('linhaSeparada', this.rotas.codigo);
    },

    watch: {
        arrayDados() {
            this.rotas = this.arrayDados;
        }
    },
})
</script>

<style lang="css" scoped>
div.font-bold {
    font-weight: bold !important;
}

.bolinhaAzul {
    background-color: #a6cfff;
    padding: 6px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.5));
}

.estrela-container {
    padding: 0;
    margin: 0;
    margin-left: -3px;
    margin-right: -3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.estrela {
    margin: 0;
    padding: 0;
}
</style>
